import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {debounceTime, switchMap} from 'rxjs/operators';

import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

import {Store} from '@ngxs/store';

import {findIndex, isEmpty, sortBy} from 'lodash-es';

import {AuthService, ProjectDef, User} from '@cultursys/core';
import {AppState} from 'app/store/app.state';
import {ProjectFormComponent} from 'app/layout/components/toolbar/project-form/project-form.component';
import {ToastrService} from 'ngx-toastr';
import {AddProject} from 'app/store/app.actions';
import {of} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';

export interface ProjectDetails {
    name: string;
    id: string;
    company: string;
    group: string;
    isLeaf: boolean;
    disabled: boolean;
    children?: ProjectDetails[];
}

@UntilDestroy()
@Component({
    selector: 'cs-project-picker',
    templateUrl: './project-picker.component.html',
    styleUrls: ['./project-picker.component.scss']
})
export class ProjectPickerComponent implements OnInit {

    @Input() title = 'My Projects';

    @Input() icon: string;

    @Input() allowAdd = false;

    @Output() projectSelected = new EventEmitter<ProjectDetails>();

    projectDetails: ProjectDetails[] = [];

    private projects: Array<Partial<ProjectDef>>;
    user: User;

    constructor(private store: Store,
                public dialog: MatDialog,
                private auth: AuthService,
                private toasty: ToastrService) {
    }

    ngOnInit(): void {
        this.auth
            .onUserChange$
            .pipe(untilDestroyed(this))
            .subscribe((user) => {
                this.user = user;
            });

        this.store
            .select(AppState.projects)
            .pipe(
                debounceTime(250),
                untilDestroyed(this)
            )
            .subscribe(projects => {
                setTimeout(() => {
                    this.projects = projects || [];
                    // this.initProjectsList();
                    this.watchForProjectChange();
                });
            });
    }

    addProject() {
        if (this.user.isAdmin && this.allowAdd) {
            const dialogRef = this.dialog.open(ProjectFormComponent, {
                panelClass: 'project-form-dialog',
                data: {
                    action: 'new'
                }
            });

            dialogRef.afterClosed()
                .pipe(switchMap((project: ProjectDef) => {
                    if (project) {
                        return this.store.dispatch(new AddProject(project));
                    } else {
                        return of();
                    }
                }))
                .subscribe(() => {
                     this.toasty.success('Project successfully created', 'Add Project');
                }, err => {
                    console.error('Error creating project ', err);
                    this.toasty.error('Error creating the project', 'Add Project', {timeOut: 5000});
                });
        }
    }


    setSelectedProject(companyProject: ProjectDetails): void {
        if (!companyProject.disabled && companyProject.isLeaf) {
            this.projectSelected.emit(companyProject);
        }
    }

    trackProject(index: number, item: ProjectDetails): string {
        return item.id;
    }

    private initProjectsList() {
        this.projectDetails = [];
        if (!isEmpty(this.projects)) {
            this.projects.forEach(project => {
                const isCurrentProject = project.id === this.auth.activeUser.activeProject.id;
                if (!isEmpty(project.company)) {
                    const group = isEmpty(project.group) ? project.company : project.group;
                    let companyNode = this.projectDetails.find(p => p.group === group);
                    if (!companyNode) {
                        companyNode = {
                            id: project.company,
                            name: project.company,
                            group: group,
                            company: project.company,
                            children: [],
                            isLeaf: false,
                            disabled: false
                        };
                        this.projectDetails.push(companyNode);
                    }
                    companyNode.children.push({
                        id: project.id,
                        name: project.name,
                        group: group,
                        company: project.company,
                        isLeaf: true,
                        disabled: isCurrentProject
                    });
                } else {
                    this.projectDetails.push({
                        id: project.id,
                        name: project.name,
                        company: '',
                        group: 'zzzzz', // so that it is listed  at the end of list
                        isLeaf: true,
                        disabled: isCurrentProject
                    });
                }
            });
            this.projectDetails = sortBy(this.projectDetails, ['group']);
        }
    }

    private watchForProjectChange() {
        if (this.auth.activeUser && this.auth.activeUser.activeProject) {
            this.auth.activeUser.activeProject.onProjectModified$
                .pipe(untilDestroyed(this))
                .subscribe((pref) => {
                    if (pref) {
                        this.projects = this.store.selectSnapshot(AppState.projects);

                        // HACK: since the store's projects is not updated when pref changes...
                        const index = findIndex(this.projects, { 'id':  this.auth.activeUser.activeProject.id});
                        this.projects[index] = this.auth.activeUser.activeProject;
                        this.initProjectsList();
                    }
                });
        }
    }


}
