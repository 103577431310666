import {AngularFirestore, AngularFirestoreDocument} from '@angular/fire/compat/firestore';
import {AngularFireStorage} from '@angular/fire/compat/storage';

import {forEach, get, includes, isUndefined, keys, set} from 'lodash-es';

import {BehaviorSubject} from 'rxjs';

import {Project, ProjectDef} from './project.model';

export enum UserRole {
    Admin = 'admin',
    ProjectAdmin = 'project-admin',
    ClientAdmin = 'client-admin',
    AppUser = 'appuser'
}

export const USER_ROLE_HASH = {
    'admin': 'Administrator',
    'project-admin': 'Project Administrator',
    'client-admin': 'Client Administrator',
    'appuser': 'Client'
};

export const roleHierarchy = {
    'admin': [],
    'project-admin': [UserRole.Admin],
    'client-admin': [UserRole.Admin, UserRole.ProjectAdmin],
    'appuser': [UserRole.Admin, UserRole.ProjectAdmin, UserRole.ClientAdmin]
};

export enum ProjectPermission {
    Read = 0,
    Write = 1
}

export interface ProjectPreference {
    showMapEditor: boolean;
}

export interface ProjectRef {
    permission: ProjectPermission;
    projectId: string;
    activeBoardId?: string;
    preference: ProjectPreference;
}

export interface IUser {
    uid: string;
    name: string;
    lastName: string;
    email: string;
    avatar: string;
    company: string;
    role: UserRole;
    projects: Record<string, ProjectRef>;
    projectNames?: string[],
    designations: Record<string, boolean>;
    disabled?: boolean;
}

export class User {
    uid: string;
    name: string;
    lastName: string;
    email: string;
    avatar: string;
    company: string;
    role: UserRole;
    designations: { [key: string]: boolean } = {};
    projects: { [key: string]: ProjectRef };
    activeProjectId: string;
    activeProject: Project;
    projectNames?: string[];
    disabled?: boolean;

    private userRef: AngularFirestoreDocument<any>;

    readonly onPreferenceChange$: BehaviorSubject<ProjectPreference> = new BehaviorSubject<ProjectPreference>({showMapEditor: false});

    constructor(userDef: any = {},
                private afs: AngularFirestore,
                private afStorage: AngularFireStorage,
                private projectFactory: any) {
        this.uid = userDef.uid || null;
        this.name = userDef.name || '';
        this.lastName = userDef.lastName || '';
        this.email = userDef.email || '';
        this.avatar = userDef.avatar || 'assets/images/avatars/profile.jpg';
        this.company = userDef.company || '';
        this.role = userDef.role || '';
        this.projects = userDef.projects || {};
        this.designations = userDef.designations || {};
        this.disabled = !!userDef.disabled;

        this.projectNames = userDef.projectNames;

        if (userDef.activeProjectId) {
            this.activeProjectId = userDef.activeProjectId;
        } else if (keys(this.projects).length > 0) {
            this.activeProjectId = keys(this.projects)[0];
        }

        this.userRef = this.afs.doc(`users/${this.uid}`);

        this.ensureBackwardCompatibility();
    }

    get fullName() {
        return this.name + ' ' + this.lastName;
    }

    get isAdmin() {
        return this.role === UserRole.Admin;
    }
    get isClientAdmin() {
        return this.allow(UserRole.ClientAdmin);
    }

    get canEditMap() {
        return this.allow(UserRole.ClientAdmin);
    }

    allow(role: UserRole) {
        return this.role === role || includes(roleHierarchy[role], this.role);
    }

    addProject(project: ProjectDef): Promise<any> {
        const projectMetadata = {
            permission: ProjectPermission.Write,
            projectId: project.id,
            activeBoardId: '',
            preference: {
                showMapEditor: true
            }
        };

        this.projects[project.id] = projectMetadata;

        return this.userRef.update({[`projects.${project.id}`]: projectMetadata});
    }

    save(prop?: string) {
        let updateSet;
        if (prop) {
            updateSet = {[prop]: get(this.toObject(), prop, null)};
        } else {
            updateSet = this.toObject();
        }

        if (updateSet) {
            return this.userRef.update(updateSet);
        } else {
            console.warn('No valid property found to save ', prop);
        }
    }

    setActiveProject(project: ProjectDef) {
        const doUpdateDb = this.activeProjectId !== project.id;
        this.activeProjectId = project.id;
        this.activeProject = this.projectFactory(project);
        if (doUpdateDb) {
            this.userRef.update({activeProjectId: project.id});
        }

        this.onPreferenceChange$.next(this.projects[project.id].preference);
    }

    updateActiveBoard(boardId: string, projectId: string) {
        const projectPath = `projects.${projectId}.activeBoardId`;
        const updateSet = {[projectPath]: boardId};
        this.userRef.update(updateSet);
    }

    updateAvatar(avatarUrl: string): Promise<any> {
        const oldAvatarUrl = this.avatar;
        this.avatar = avatarUrl;
        return this.userRef
            .update({avatar: avatarUrl})
            .then(() => {
                if (oldAvatarUrl && oldAvatarUrl.startsWith('https://')) {
                    const oldAvatarRef = this.afStorage.storage.refFromURL(oldAvatarUrl);
                    return oldAvatarRef.delete();
                }
            })
            .catch(err => {
                console.error('Error updating avatar ', err);
            });
    }

    toObject() {
        return {
            uid: this.uid,
            name: this.name,
            lastName: this.lastName,
            email: this.email,
            avatar: this.avatar,
            company: this.company,
            role: this.role,
            projects: this.projects,
            designations: this.designations,
            disabled: false
        };
    }

    deleteProject(projectId: string): Promise<any> {
        delete this.projects[projectId];
        return this.userRef.update({projects: this.projects});
    }

    // migrateProjects() {
    //     this.afs
    //         .collection<any>(`projects`)
    //         .valueChanges()
    //         .pipe(take(1))
    //         .subscribe(projects => {
    //             forEach(projects, project => {
    //                 // delete project.boardDefs;
    //                 // this.afs.doc(`projects/${project.id}`).set(project);
    //                 console.log(project.name);
    //                 // this.afs
    //                 //     .collection(`projects/${project.id}/maps`)
    //                 //     .valueChanges()
    //                 //     .pipe(take(1))
    //                 //     .subscribe(maps => {
    //                 //         if (isEmpty(maps)) {
    //                 //             console.log(project.name);
    //                 //         }
    //                 //     });
    //
    //             });
    //         });
    // }

    getPreference(projectId: string) {
        return this.projects[projectId].preference;
    }

    getActiveProjectPreference() {
        return this.getPreference(this.activeProject.id);
    }

    private ensureBackwardCompatibility() {
        forEach(this.projects, project => {
            if (isUndefined(project.preference)) {
                project.preference = {
                    showMapEditor: true
                };
            }
        });
    }

    updatePreference(projectId: string, path: string, value: any) {
        set(this.projects[projectId].preference, path, value);
        const fieldToSave = `projects.${projectId}.preference`;
        this.save(fieldToSave);
        this.onPreferenceChange$.next(this.projects[projectId].preference);
    }
}
