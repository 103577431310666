import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DataSource} from '@angular/cdk/collections';

import {Observable} from 'rxjs';
import {fuseAnimations} from '@fuse/animations/index';
import {FuseSidebarService} from '@fuse/components/sidebar/sidebar.service';

import {FileManagerService} from '../file-manager.service';
import {Select, Store} from '@ngxs/store';
import {FileManagerState} from '../store/file-manager.state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {DeleteFile, SetCurrentFolder} from '../store/file-manager.actions';
import { MatDialog } from '@angular/material/dialog';
import {ConfirmDialogComponent} from '@cultursys/common';
import {ToastrService} from 'ngx-toastr';
import {HttpClient} from '@angular/common/http';
import {FileRef} from '../file-ref.def';



@UntilDestroy()
@Component({
        selector: 'file-list',
        templateUrl: './file-list.component.html',
        styleUrls: ['./file-list.component.scss'],
        animations: fuseAnimations
    })
export class FileManagerFileListComponent implements OnInit, OnDestroy {

    @Select(FileManagerState.filesInCurrentFolder) files$;

    @ViewChild('downloadElem') downloadElem;

    files: any;
    dataSource: FilesDataSource | null;
    displayedColumns = ['icon', 'name', 'type', 'size', 'detail-button'];
    selected: any;

    constructor(
        private _fileManagerService: FileManagerService,
        private _fuseSidebarService: FuseSidebarService,
        private http: HttpClient,
        private store: Store,
        private dialog: MatDialog,
        private toasty: ToastrService
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.dataSource = new FilesDataSource(this.store);

        this.store
            .select(FileManagerState.files)
            .pipe(untilDestroyed(this))
            .subscribe(files => {
                this.files = files;
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * On select
     *
     * @param selected
     */
    onSelect(selected: FileRef): void {
        if (selected.isFolder) {
            this.store.dispatch(new SetCurrentFolder([...selected.path, selected.name]));
        }
    }

    /**
     * Toggle the sidebar
     *
     * @param name
     */
    toggleSidebar(name): void {
        this._fuseSidebarService.getSidebar(name).toggleOpen();
    }

    delete(file: FileRef, event: Event) {
        event.preventDefault();
        this.dialog
            .open(ConfirmDialogComponent)
            .afterClosed()
            .subscribe(goAhead => {
                if (goAhead) {
                    this.store
                        .dispatch(new DeleteFile(file))
                        .subscribe(() => {
                            },
                            err => {
                                this.toasty.error('Failed to delete file. Try again',
                                    'Delete File',
                                    {
                                    closeButton: true,
                                    timeOut: 5000
                                });
                            });
                }
            });
    }

    showURLCopyMessage() {
        this.toasty.success( 'Copied to clipboard', 'Download Link', {timeOut: 2000});
    }

    downloadFile(file: FileRef) {
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = (event) => {
            const blob = new Blob([xhr.response], {
                type: 'text/json;charset=utf-8;'
            });

            const downloadButton = this.downloadElem.nativeElement;
            downloadButton.download = file.name;
            downloadButton.href = window.URL.createObjectURL(blob);
            downloadButton.dataset.downloadurl = ['text/json', downloadButton.download, downloadButton.href].join(':');
            downloadButton.click();
        };
        xhr.open('GET', file.url);
        xhr.send();

        // this.http
        //     .get(file.url)
        //     .subscribe(data => {
        //         const blob = new Blob([data], {
        //             type: 'text/json;charset=utf-8;'
        //         });
        //
        //         const downloadButton = this.downloadElem.nativeElement;
        //         downloadButton.download = file.name;
        //         downloadButton.href = window.URL.createObjectURL(blob);
        //         downloadButton.dataset.downloadurl = ['text/json', downloadButton.download, downloadButton.href].join(':');
        //         downloadButton.click();
        //     });
    }
}

export class FilesDataSource extends DataSource<any> {

    constructor(
        private store: Store
    ) {
        super();
    }

    /**
     * Connect function called by the table to retrieve one stream containing the data to render.
     *
     * @returns {Observable<any[]>}
     */
    connect(): Observable<any[]> {
        return this.store.select(FileManagerState.filesInCurrentFolder);
    }

    /**
     * Disconnect
     */
    disconnect(): void {
    }
}
