import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatIconModule, MatIconRegistry} from '@angular/material/icon';
import {OverlayModule} from '@angular/cdk/overlay';
import {BrowserModule, DomSanitizer} from '@angular/platform-browser';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {TranslateModule} from '@ngx-translate/core';

import {FuseModule} from '@fuse/fuse.module';
import {FuseSharedModule} from '@fuse/shared.module';
import {NgxsRouterPluginModule} from '@ngxs/router-plugin';
import {NgxsModule} from '@ngxs/store';

import {HotkeyModule} from 'angular2-hotkeys';


import {ChartModule, HIGHCHARTS_MODULES} from 'angular-highcharts';
import * as Highcharts from 'highcharts';
import * as HighchartsMore from 'highcharts/highcharts-more';
import * as HighChartsBorderRadius from 'highcharts-border-radius';
import * as HighchartsExporting from 'highcharts/modules/exporting.src';
import * as HighchartsOfflineExporting from 'highcharts/modules/offline-exporting.src';
import * as HighchartsExportData from 'highcharts/modules/export-data.src';

import {Angular2PromiseButtonModule} from 'angular2-promise-buttons';

import {fuseConfig} from './fuse-config';

import {AppComponent} from './app.component';

import {environment} from '../environments/environment';
import {AppRoutingModule} from './app-routing.module';
import {LoginModule} from './login/login.module';
import {CoreModule} from '@cultursys/core';
import {RegisterModule} from './register/register.module';
import {ToastrModule} from 'ngx-toastr';
import {SharedModule} from './shared/shared.module';
import {AppState} from './store/app.state';
import {VerifyLoginUrlComponent} from './verify-login-url/verify-login-url.component';
import {LayoutModule} from './layout/layout.module';
import {NoopComponentModule} from './main/components/noop/noop-component.module';
import {FuseProgressBarModule} from '@fuse/components';

import {NZ_ICONS, NzIconModule} from 'ng-zorro-antd/icon';




import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';

import * as AllIcons from '@ant-design/icons-angular/icons';

import {registerLocaleData} from '@angular/common';
import en from '@angular/common/locales/en';
import {QuillModule} from 'ngx-quill';
import {IconDefinition} from '@ant-design/icons-angular';
import {SnippetService} from './main/components/snippets/snippets.service';
import {SnippetList} from './main/components/snippets/snippet-list/snippet-list.model';
import {AngularFireModule} from '@angular/fire/compat';
import {AngularFireStorageModule, BUCKET} from '@angular/fire/compat/storage';
import {AngularFireAuthModule} from '@angular/fire/compat/auth';
import {AngularFirestore, AngularFirestoreModule, SETTINGS} from '@angular/fire/compat/firestore';
import {NzUploadModule} from 'ng-zorro-antd/upload';


registerLocaleData(en);

const antDesignIcons = AllIcons as {
    [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(key => antDesignIcons[key]);

const ngxsModules = [
    NgxsModule.forRoot([AppState], { developmentMode: !environment.production }),
    NgxsRouterPluginModule.forRoot(),

];

const toastrConfig = {
    timeOut: 1000,
    positionClass: 'toast-top-right',
    preventDuplicates: true,
    countDuplicates: true
};

const firebaseModules: any = [
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features,
    AngularFireStorageModule, // imports firebase/storage only needed for storage features
];

if (!environment.production) {
    // ngxsModules.push(NgxsReduxDevtoolsPluginModule.forRoot());

    if (environment.workOffline) {
        firebaseModules.push(AngularFirestoreModule.enablePersistence());
    }

}

// HighCharts Plugins
HighChartsBorderRadius(Highcharts);


@NgModule({
    declarations: [
        AppComponent,
        VerifyLoginUrlComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        OverlayModule,

        MatIconModule,

        ...firebaseModules,

        NzIconModule,

        ChartModule,

        HotkeyModule.forRoot(),


        ...ngxsModules,

        Angular2PromiseButtonModule.forRoot({
            btnLoadingClass: 'is-loading',
            spinnerTpl: `
                <div class="sk-fading-circle promise-btn-icon">
                    <div class="sk-circle1 sk-circle"></div>
                    <div class="sk-circle2 sk-circle"></div>
                    <div class="sk-circle3 sk-circle"></div>
                    <div class="sk-circle4 sk-circle"></div>
                    <div class="sk-circle5 sk-circle"></div>
                    <div class="sk-circle6 sk-circle"></div>
                    <div class="sk-circle7 sk-circle"></div>
                    <div class="sk-circle8 sk-circle"></div>
                    <div class="sk-circle9 sk-circle"></div>
                    <div class="sk-circle10 sk-circle"></div>
                    <div class="sk-circle11 sk-circle"></div>
                    <div class="sk-circle12 sk-circle"></div>
                </div>`
        }),
        QuillModule.forRoot(),
        TranslateModule.forRoot(),
        CoreModule.forRoot(),
        // Fuse Main and Shared modules
        FuseModule.forRoot(fuseConfig),
        FuseSharedModule,
        FuseProgressBarModule,
        LoginModule,
        RegisterModule,
        AppRoutingModule,
        SharedModule,
        ToastrModule.forRoot(toastrConfig),
        LayoutModule,
        NoopComponentModule,
        FormsModule,

    ],
    bootstrap: [
        AppComponent
    ],
    providers: [
        SnippetService,
        SnippetList,
    { provide: BUCKET, useValue: environment.firebase.storageBucket },

        {provide: HIGHCHARTS_MODULES, useFactory: () => [HighchartsMore, HighchartsExporting, HighchartsOfflineExporting, HighchartsExportData]},
        {provide: NZ_I18N, useValue: en_US},
        {provide: NZ_ICONS, useValue: icons},
        {
            provide: SETTINGS,
            useValue: !environment.production && environment.workOffline ? {
                    host: 'localhost:8080',
                    ssl: false,
                    experimentalAutoDetectLongPolling: true
                } :
                {
                    experimentalAutoDetectLongPolling: true
                }
        },
    ]
})
export class AppModule {
    constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer, afs: AngularFirestore) {
        matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('/assets/material-icons/mdi.svg'));
    }
}
