import {Component} from '@angular/core';

import {TranslateService} from '@ngx-translate/core';

import {get} from 'lodash-es';

import {FuseSplashScreenService} from '@fuse/services/splash-screen.service';
import {FuseTranslationLoaderService} from '@fuse/services/translation-loader.service';
import {FuseNavigationService} from '@fuse/components/navigation/navigation.service';

import {locale as navigationEnglish} from './navigation/i18n/en';
import {locale as navigationTurkish} from './navigation/i18n/tr';
import {environment} from '../environments/environment';
import {LiveSupportService} from './core/live-support.service';
import {Store} from '@ngxs/store';
import {Hotkey, HotkeysService} from 'angular2-hotkeys';
import {AuthService} from '@cultursys/core';
import {ToggleMapEditorVisibility} from './store/app.actions';

@Component({
    selector: 'app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    constructor(private translate: TranslateService,
                private fuseNavigationService: FuseNavigationService,
                private fuseSplashScreen: FuseSplashScreenService,
                private fuseTranslationLoader: FuseTranslationLoaderService,
                private liveSupport: LiveSupportService,
                private store: Store,
                private auth: AuthService,
                private hotkeysService: HotkeysService) {

        console.log('Firebase ', environment.firebase.projectId);

        // Add languages
        this.translate.addLangs(['en', 'tr']);

        // Set the default language
        this.translate.setDefaultLang('en');

        // Set the navigation translations
        this.fuseTranslationLoader.loadTranslations(navigationEnglish, navigationTurkish);

        // Use a language
        this.translate.use('en');

        // this.liveSupport.updateUserInfo();

        this.hotkeysService.add(new Hotkey('alt+e', (event: KeyboardEvent): boolean => {
            const project = get(this.auth.activeUser, 'activeProject', null);
            if (project) {
                this.store
                    .dispatch(new ToggleMapEditorVisibility())
                    .subscribe(() => {
                        setTimeout(() => {
                            window.dispatchEvent(new Event('resize'));
                        });
                    });
            }
            return false; // Prevent bubbling
        }));
    }
}
