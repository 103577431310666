<div id="main">

    <!-- TOOLBAR: Above -->
    <ng-container *ngTemplateOutlet="toolbar"></ng-container>
    <!-- / TOOLBAR: Above -->

    <!-- NAVBAR: Top -->
    <ng-container>
        <ng-container *ngTemplateOutlet="topNavbar"></ng-container>
    </ng-container>
    <!-- / NAVBAR: Top -->

    <div id="container-1" class="container grey-100-bg">

        <div id="container-2" class="container">

                <!-- CONTENT -->
                <content></content>
                <!-- / CONTENT -->

                <!-- FOOTER: Below -->
                <ng-container *ngIf="fuseConfig.layout.footer.position === 'above-static'">
                    <ng-container *ngTemplateOutlet="footer"></ng-container>
                </ng-container>
                <!-- / FOOTER: Below -->


        </div>

    </div>

    <!-- FOOTER: Above -->
    <ng-container *ngIf="fuseConfig.layout.footer.position === 'above-fixed'">
        <ng-container *ngTemplateOutlet="footer"></ng-container>
    </ng-container>
    <!-- FOOTER: Above -->

</div>

<!-- QUICK PANEL -->
<fuse-sidebar name="quickPanel" position="right" class="quick-panel">
    <quick-panel></quick-panel>
</fuse-sidebar>
<!-- / QUICK PANEL -->

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- @ PARTIALS
<!-- ----------------------------------------------------------------------------------------------------- -->

<!-- TOOLBAR -->
<ng-template #toolbar>
    <toolbar *ngIf="!fuseConfig.layout.toolbar.hidden"
             [ngClass]="[fuseConfig.layout.toolbar.position, fuseConfig.layout.toolbar.background]">
    </toolbar>
</ng-template>
<!-- / TOOLBAR -->

<!-- FOOTER -->
<ng-template #footer>
    <footer *ngIf="!fuseConfig.layout.footer.hidden"
            [ngClass]="[fuseConfig.layout.footer.position, fuseConfig.layout.footer.background]"></footer>
</ng-template>
<!-- / FOOTER -->

<!-- TOP NAVBAR -->
<ng-template #topNavbar>
    <navbar variant="horizontal-style-1"
            class="top-navbar" [ngClass]="fuseConfig.layout.navbar.background"
            [hidden]="fuseConfig.layout.navbar.hidden">
    </navbar>
</ng-template>
<!-- / TOP NAVBAR -->
