<div id="login" fxLayout="column" fusePerfectScrollbar *ngIf="!submitted">

    <div id="login-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

        <div id="login-form" *fuseIfOnDom [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

            <div class="logo">
                <img src="assets/images/logos/pyxis_logo.png">
            </div>

            <div class="title">LOGIN TO YOUR ACCOUNT</div>


            <form name="loginForm" [formGroup]="loginForm" (ngSubmit)="onSubmit()" novalidate>
                <mat-form-field>
                    <input matInput placeholder="Email" formControlName="email">
                    <mat-error *ngIf="loginFormErrors.email.required">
                        Email is required
                    </mat-error>
                    <mat-error *ngIf="!loginFormErrors.email.required && loginFormErrors.email.email">
                        Please enter a valid email address
                    </mat-error>
                </mat-form-field>

                <div *ngIf="loginWithPassword">
                    <mat-form-field>
                        <input matInput type="password" placeholder="Password" formControlName="password">
                        <mat-error *ngIf="loginFormErrors.password.required">
                            Password is required
                        </mat-error>
                    </mat-form-field>
                    <span *ngIf="loginFormErrors.password.validationFail" class="red-A700-fg">
                        Email and password did not match
                    </span>

                    <div class="remember-forgot-password" fxLayout="row" fxLayout.xs="column"
                         fxLayoutAlign="space-between center">
                        <a class="forgot-password" [routerLink]="'/forgot-password'">
                            Forgot Password?
                        </a>
                    </div>
                </div>

                <!--<mat-slide-toggle [checked]="loginWithPassword" (change)="onToggleLoginWithPassword($event)">-->
                    <!--I have my password!-->
                <!--</mat-slide-toggle>-->

                <button type="submit"
                        *ngIf="loginWithPassword"
                        mat-raised-button color="accent" class="submit-button" aria-label="LOG IN"
                        [disabled]="loginForm.invalid">
                    LOGIN
                </button>

                <!--<button type="submit"-->
                        <!--*ngIf="!loginWithPassword"-->
                        <!--mat-raised-button color="accent" class="submit-button" aria-label="LOG IN"-->
                        <!--[disabled]="loginForm.get('email').invalid || loginForm.pristine">-->
                    <!--{{emailSent ? 'SEND AGAIN' : 'GET LOGIN LINK'}}-->
                <!--</button>-->

                <!--<div [hidden]="!loginWithPassword && message.length == 0">{{message}}</div>-->
            </form>

        </div>

    </div>

</div>

<!-- FUSE Splash Screen -->
<div id="fuse-splash-screen" *ngIf="submitted">

    <div class="center">

        <div class="logo">
            <img width="198" src="assets/images/logos/pyxis_logo.png">
        </div>

        <!-- Material Design Spinner -->
        <div class="spinner-wrapper">
            <div class="spinner">
                <div class="inner">
                    <div class="gap"></div>
                    <div class="left">
                        <div class="half-circle"></div>
                    </div>
                    <div class="right">
                        <div class="half-circle"></div>
                    </div>
                </div>
            </div>
        </div>
        <!-- / Material Design Spinner -->

    </div>

</div>
<!-- / FUSE Splash Screen -->
