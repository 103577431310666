import { Injectable } from '@angular/core';

import Fuse from 'fuse.js';

const fuseOptions = {
    shouldSort: true,
    threshold: 0.2,
    minMatchCharLength: 2,
    useExtendedSearch: true,
    ignoreLocation: true,
    findAllMatches: true,
    maxPatternLength: 32
};

export interface FacetOption {
    label: string;
    value: string;
    checked: boolean;
    disabled: boolean;
    count: number;
}

export interface Facet {
    title: string;
    docKey: string;
    idxKey: string;
    options: FacetOption[];
    selected: string[];
    showCount: number;
}

@Injectable({
  providedIn: 'root'
})
export class FuseFilterService {

    constructor() { }

    public search(collection: any[], searchString: string, options: any = {}) {
        let results = [];
        if (searchString && searchString.length >= fuseOptions['minMatchCharLength']) {
            const fuse = new Fuse(collection, {...fuseOptions , ...options });
            results = fuse.search(searchString).map((res) => res.item);
            return results;
        } else {
            return collection;
        }
    }
}

