import { Pipe, PipeTransform } from '@angular/core';
import {startCase} from 'lodash-es';

@Pipe({
  name: 'csTitleCase'
})
export class CsTitleCasePipe implements PipeTransform {

  transform(text: string): string {
     return startCase(text);
  }

}
