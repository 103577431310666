import {NgModule} from '@angular/core';

import {FileSizePipe} from './file-size.pipe';
import {FireStoragePipe} from './fire-storage.pipe';
import {CsTitleCasePipe} from './cs-title-case.pipe';
import { TagsPipe } from './tags.pipe';
import { FuseFilterPipe } from './fuse-filter.pipe';
import { HighlightSearchPipe } from './highlight-search.pipe';
import {FilterPipe} from './filter.pipe';



@NgModule({
    declarations: [
        FileSizePipe,
        FireStoragePipe,
        CsTitleCasePipe,
        TagsPipe,
        FuseFilterPipe,
        FilterPipe,
        HighlightSearchPipe
    ],
    imports: [],
    exports: [
        FileSizePipe,
        FireStoragePipe,
        CsTitleCasePipe,
        TagsPipe,
        FilterPipe,
        HighlightSearchPipe,
        FuseFilterPipe
    ],
    providers: [
        FileSizePipe,
        HighlightSearchPipe
    ]
})
export class PipesModule {
}
