export enum DynamicFormControlPermission {
    Read = 'read',
    Write = 'write'
}

export enum DynamicFormControlType {
    Text = 'text',
    Number = 'number',
    Email = 'email',
    Date = 'date',
    TextArea = 'textarea',
    Select = 'select',
    Checkbox = 'checkbox',
    Password = 'password',
    Slider = 'slider',
    Button = 'button'
}

export interface SelectFieldOptions {
    id: string;
    label: string;
}

export interface NumberFieldOptions {
    min: number;
    max: number;
}

export interface SliderFieldOptions {
    min: number;
    max: number;
}

export interface DynamicFormMetadata {
    id: string;
    label: string;
    description?: string;
    value: any;
    required?: boolean;
    type: DynamicFormControlType;
    icon?: string;
    validationMessages?: any;
    options?: SelectFieldOptions[] | NumberFieldOptions | SliderFieldOptions;
    // if no permission defined, default is write
    permission?: DynamicFormControlPermission;
    validators?: any[];
}

