import {Pipe, PipeTransform} from '@angular/core';

import {isString} from 'lodash-es';

import {Observable, of} from 'rxjs';

import {StorageService} from '@cultursys/core';

@Pipe({
    name: 'fireStorageUrl'
})
export class FireStoragePipe implements PipeTransform {

    constructor(private storage: StorageService) {
    }

    transform(refUrl: string): Observable<string> {
        if (isString(refUrl) && refUrl.length > 0) {
            return this.storage.getDownloadUrl(refUrl);
        } else {
            return of(refUrl);
        }
    }

}
