import {Component, Inject, ViewEncapsulation} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import {extend, now} from 'lodash-es';

import {DynamicFormControlPermission, DynamicFormControlType, DynamicFormMetadata} from '@cultursys/dynamic-form';
import {StorageService, User} from '@cultursys/core';
import {DomSanitizer} from '@angular/platform-browser';
import {FileUploadEvent} from '@cultursys/common';
import {take} from 'rxjs/operators';

@Component({
    selector: 'cs-user-profile-dialog',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class UserProfileComponent {
    dialogTitle: string;
    action: string;
    user: User;

    formMetadata: DynamicFormMetadata[];
    newAvatarFileName: string;
    avatarBlob: any;
    imageUploadInProgress = false;

    constructor(public dialogRef: MatDialogRef<UserProfileComponent>,
                private storageService: StorageService,
                private sanitizer: DomSanitizer,
                @Inject(MAT_DIALOG_DATA) private data: any,
                @Inject('UserFactory') private userFactory) {
        this.action = data.action;
        this.dialogTitle = 'Profile';
        this.user = data.contact;

        this.createUserForm();

        this.refreshNewAvatarFileName();
    }

    save(formData: any) {
        extend(this.user, formData);
        this.dialogRef.close({user: this.user});
    }

    onAvatarUploaded(event: FileUploadEvent) {
        this.storageService
            .getDownloadUrl(event.fullPath)
            .pipe(take(1))
            .subscribe(async (path) => {
                this.refreshNewAvatarFileName();
                await this.user.updateAvatar(path);
                this.imageUploadInProgress = false;
            });
    }

    private refreshNewAvatarFileName() {
        this.newAvatarFileName = `${this.user.uid}_${now()}`;
    }

    onAvatarCropped(blob: Blob | string) {
        // const urlCreator = window.URL || window['webkitURL'];
        // const blobUrl = urlCreator.createObjectURL(blob);
        // this.avatarBlob = this.sanitizer.bypassSecurityTrustUrl(blobUrl);
        this.avatarBlob = blob;
        this.imageUploadInProgress = true;
    }

    private createUserForm() {
        this.formMetadata = [
            {
                id: 'name',
                label: 'First Name',
                required: true,
                value: this.user.name || '',
                type: DynamicFormControlType.Text,
                validationMessages: {
                    'required': 'First name is required'
                }
            },
            {
                id: 'lastName',
                label: 'Last Name',
                value: this.user.lastName || '',
                type: DynamicFormControlType.Text
            },
            {
                id: 'email',
                label: 'Email',
                required: true,
                value: this.user.email || '',
                type: DynamicFormControlType.Email,
                permission: DynamicFormControlPermission.Read,
                validationMessages: {
                    'required': 'Email is required',
                    'email': 'Please enter a valid email address'
                }
            },
            {
                id: 'company',
                label: 'Company',
                required: true,
                value: this.user.company || '',
                type: DynamicFormControlType.Text
            }
        ];
    }
}
