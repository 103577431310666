<div fxLayout="column" fxFlex="0 0 350px" class="h-240">
    <mat-toolbar matDialogTitle class="mat-grey-100-bg m-0">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Edit Connection Parameters</span>
            <button mat-mini-fab class="size-24 mat-elevation-z0"
                    color="primary"
                    (click)="dialogRef.close()"
                    aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div class="p-24">
        <form>
            <mat-form-field class="mr-16 w-100">
                <input matInput name="weight"
                       type="number"
                       [min]="1"
                       [max]="2"
                       placeholder="Connection Weight"
                       [(ngModel)]="weight">
            </mat-form-field>
        </form>

        <!--<mat-select placeholder="Select transfer function"-->
        <!--#select="matSelect"-->
        <!--class="w-200"-->
        <!--[(ngModel)]="transferFunction">-->
        <!--<mat-option *ngFor="let option of easingFunctions" [value]="option.value">-->
        <!--{{option.key}}-->
        <!--</mat-option>-->
        <!--</mat-select>-->

        <!--<form class="mt-36">-->
        <!--<mat-form-field class="mr-16 w-100">-->
        <!--<input matInput name="minRange"-->
        <!--type="number"-->
        <!--[(ngModel)]="impact[0]"-->
        <!--placeholder="Min Impact">-->
        <!--</mat-form-field>-->
        <!--<mat-form-field class="w-100">-->
        <!--<input matInput name="maxRange"-->
        <!--type="number"-->
        <!--[(ngModel)]="impact[1]"-->
        <!--placeholder="Max Impact">-->
        <!--</mat-form-field>-->
        <!--</form>-->
    </div>

    <div fxLayout="row" fxLayoutAlign="end center" class="px-24">
        <button mat-raised-button color="accent" (click)="save()">Save</button>
    </div>
</div>
