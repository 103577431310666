<ul class="file-list">
    <li *ngFor="let file of files$ | async" fxLayout="row" fxLayoutAlign="space-between center">
        <section class="file-info"
                 fxFlex
                 fxLayout="row"
                 fxLayoutAlign="start center"
                 [ngClass]="{'is-folder': file.isFolder}"
                 (click)="onSelect(file)">
            <span class="secondary-text mdi mdi-24px mdi-{{file.fileType}}"></span>
            <div class="ml-12">
                <div class="font-size-16 font-weight-500">{{file.name}}</div>
                <div *ngIf="!file.isFolder" class="secondary-text font-size-12"> {{file.size | fileSize}}</div>
            </div>
        </section>
        <section class="file-actions reveal-on-parent-hover" fxFlex="150px" fxLayout="row" fxLayoutAlign="end center">
            <button *ngIf="!file.isFolder" mat-icon-button ngxClipboard [cbContent]="file.url"
                    (cbOnSuccess)="showURLCopyMessage()">
                <mat-icon class="secondary-text">link</mat-icon>
            </button>

            <a hidden #downloadElem></a>
            <button *ngIf="!file.isFolder" mat-icon-button (click)="downloadFile(file)">
                <mat-icon class="secondary-text">file_download</mat-icon>
            </button>

            <button mat-icon-button (click)="delete(file, $event)">
                <mat-icon class="secondary-text">delete</mat-icon>
            </button>

        </section>
    </li>
    <li class="h2 mt-40 ml-16 no-border" *ngIf="(files$ | async).length === 0">No Files In This Folder</li>
</ul>
