import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import {ColorPickerModule} from 'ngx-color-picker';

import { FuseSharedModule } from '@fuse/shared.module';

import { QuickPanelComponent } from './quick-panel.component';
import {DynamicFormModule} from '@cultursys/dynamic-form';
import {ConfirmDialogModule, FileUploadModule} from '@cultursys/common';
import { PaletteGeneratorComponent } from './palette-generator/palette-generator.component';
import {FileManagerModule} from '../../../main/apps/file-manager/file-manager.module';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {FuseProgressBarModule} from '../../../../@fuse/components';

@NgModule({
    declarations: [
        QuickPanelComponent,
        PaletteGeneratorComponent
    ],
    imports     : [
        RouterModule,

        MatDividerModule,
        MatExpansionModule,
        MatListModule,
        MatSlideToggleModule,
        MatButtonModule,
        MatChipsModule,
        MatFormFieldModule,
        MatIconModule,
        MatSelectModule,
        MatDialogModule,

        NgxMatSelectSearchModule,

        FuseSharedModule,
        FuseProgressBarModule,
        DynamicFormModule,

        ConfirmDialogModule,
        FileUploadModule,
        ColorPickerModule,
        FileManagerModule
    ],
    exports: [
        QuickPanelComponent
    ]
})
export class QuickPanelModule
{
}
