export const environment = {
    production: false,
    workOffline: false,
    firebase: {
        apiKey: 'AIzaSyASHED-WbCkZW86oC4VitEep2S8S03JeCM',
        authDomain: 'cultursys-edf9a.firebaseapp.com',
        databaseURL: 'https://cultursys-edf9a.firebaseio.com',
        projectId: 'cultursys-edf9a',
        storageBucket: 'cultursys-edf9a.appspot.com',
        messagingSenderId: '56533995632'
    },
    firebaseApiRoot: 'https://us-central1-cultursys-edf9a.cloudfunctions.net/api',
    tawkId: '5b0d0a3c10b99c7b36d468cf',
    tawkApiKey: '12b4dc639fed9bede6300c835b5449266b8a92e1'
};
