import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';

import {DynamicFormControlPermission, DynamicFormControlType, DynamicFormMetadata, DynamicFormValueChange} from '@cultursys/dynamic-form';

import {Select, Store} from '@ngxs/store';

import {filter as _filter, find, forEach, get, isEmpty, keys, some, values, without} from 'lodash-es';

import {ToastrService} from 'ngx-toastr';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {BehaviorSubject, Observable} from 'rxjs';
import {filter, switchMap, take} from 'rxjs/operators';

import {ConfirmDialogComponent, FileUploadEvent} from '@cultursys/common';
import {AuthService, Project, StorageService, User} from '@cultursys/core';
import {AppState, UIPreferences} from 'app/store/app.state';
import {CloneProject, DeleteProject, ImportProject, SetProjectName, ToggleMapEditorVisibility} from 'app/store/app.actions';
import {FileManagerState, FileManagerUtils, FileRef} from '@cultursys/file-manager';
import {CURRENCIES} from 'app/core/currency.model';
import {FuseProgressBarService} from '../../../../@fuse/components/progress-bar/progress-bar.service';
import {Validators} from '@angular/forms';



@UntilDestroy()
@Component({
        selector: 'quick-panel',
        templateUrl: './quick-panel.component.html',
        styleUrls: ['./quick-panel.component.scss'],
        encapsulation: ViewEncapsulation.None
    })
export class QuickPanelComponent implements OnInit, OnDestroy {

    @ViewChild('downloadElem') downloadElem: ElementRef;

    @Select(AppState.uiPreferences) uiPreferences$: Observable<UIPreferences>;

    filteredCurrencies$ = new BehaviorSubject(values(CURRENCIES));

    date: Date;
    settings: any;
    notes = [];
    events = [];

    user: User;
    project: Project;

    currencyFilter = '';
    selectedCurrency: any;

    formMetadata: DynamicFormMetadata[];

    designations: string[] = [];

    separatorKeysCodes = [ENTER, COMMA];
    logoBlob: SafeUrl;
    cprReportFile: FileRef;
    assuranceReportFile: FileRef;

    constructor(private auth: AuthService,
                private fileManager: FileManagerUtils,
                public dialog: MatDialog,
                private sanitizer: DomSanitizer,
                private storageService: StorageService,
                private toasty: ToastrService,
                private store: Store,
                private fuseProgressBarService: FuseProgressBarService,
                @Inject('ProjectFactory') private projectFactory) {
    }

    ngOnInit() {
        this.auth
            .onUserChange$
            .pipe(
                filter(user => !isEmpty(user)),
                switchMap(user => {
                    this.user = user;
                    return this.store.select(AppState.activeProjectId);
                }),
                filter(projectId => !isEmpty(projectId)),
                switchMap((projectId) => {
                    this.project = this.user.activeProject;
                    console.log('Quick panel ', projectId, this.user.activeProject);
                    if (this.project) {
                        this.designations = keys(this.project.designations) || [];
                        this.createProjectForm();
                    }

                    this.selectedCurrency = find(CURRENCIES, {code: this.project.baseCurrencyCode});

                    return this.store.select(FileManagerState.files);
                }),
                untilDestroyed(this)
            )
            .subscribe(() => {
                this.refreshCPRReportFile();
                this.refreshAssuranceReportFile();
            });
    }

    private refreshAssuranceReportFile() {
        const assuranceFileId = get(this.project, 'assuranceData.reportFile.id', null);
        if (assuranceFileId) {
            const assuranceFileIdFile = this.fileManager.findFileById(assuranceFileId);
            this.assuranceReportFile = assuranceFileIdFile ? assuranceFileIdFile : null;
        } else {
            this.assuranceReportFile = null;
        }
    }

    private refreshCPRReportFile() {
        const cprFileId = get(this.project, 'cprData.reportFile.id', null);
        if (cprFileId) {
            const file = this.fileManager.findFileById(cprFileId);
            this.cprReportFile = file ? file : null;
        } else {
            this.cprReportFile = null;
        }
    }

    ngOnDestroy(): void {
    }

    onProjectPropertyChange(change: DynamicFormValueChange) {
        const value = change.control.id === 'redFlagThreshold' ? change.model[change.control.id] / 100 : change.model[change.control.id];
        this.project[change.control.id] = value;
        this.project.saveProperty(change.control.id, value);
        if (change.control.id === 'name') {
            this.store.dispatch(new SetProjectName(value, this.project.id));
        }
    }

    cloneProject() {
        this.store
            .dispatch(new CloneProject(this.project.toObject()))
            .subscribe(() => {
                 this.toasty.success('Project successfully cloned', 'Clone Project');
            }, err => {
                console.error('Error cloning project ', err);
                this.toasty.error('Error cloning the project', 'Clone Project');
            });
    }

    private createProjectForm() {
        this.formMetadata = [
            {
                id: 'name',
                label: 'Name',
                required: true,
                value: this.project.name,
                type: DynamicFormControlType.Text,
                validationMessages: {
                    'required': 'Name is required'
                },
                permission: this.auth.activeUser.isAdmin ? DynamicFormControlPermission.Write : DynamicFormControlPermission.Read
            },
            {
                id: 'description',
                label: 'Description',
                value: this.project.description,
                type: DynamicFormControlType.Text,
                permission: this.auth.activeUser.isAdmin ? DynamicFormControlPermission.Write : DynamicFormControlPermission.Read
            },
            {
                id: 'company',
                label: 'Company',
                value: this.project.company,
                type: DynamicFormControlType.Text,
                permission: this.auth.activeUser.isAdmin ? DynamicFormControlPermission.Write : DynamicFormControlPermission.Read
            },
            {
                id: 'group',
                label: 'Group under',
                value: this.project.group,
                type: DynamicFormControlType.Text,
                permission: this.auth.activeUser.isAdmin ? DynamicFormControlPermission.Write : DynamicFormControlPermission.Read
            },
            {
                id: 'version',
                label: 'Version',
                value: this.project.version,
                type: DynamicFormControlType.Text,
                validationMessages: {
                    'required': 'Email is required'
                },
                permission: this.auth.activeUser.isAdmin ? DynamicFormControlPermission.Write : DynamicFormControlPermission.Read
            },
            {
                id: 'redFlagThreshold',
                label: 'Red Flag Threshold Score (%)',
                value: this.project.redFlagThreshold * 100,
                type: DynamicFormControlType.Number,
                validators: [Validators.min(0), Validators.max(100)],
                validationMessages: {
                    'required': 'Red flag threshold is required',
                    min: 'Please enter a value between 0 and 100',
                    max: 'Please enter a value between 0 and 100'
                },
                permission: this.auth.activeUser.isAdmin ? DynamicFormControlPermission.Write : DynamicFormControlPermission.Read
            }
        ];
    }

    deleteProject() {
        if (this.auth.activeUser.isAdmin) {
            const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
                disableClose: false
            });

            confirmDialogRef.componentInstance.message = `Are you sure you want to delete ${this.project.name} project?`;

            confirmDialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.store
                        .dispatch(new DeleteProject(this.project))
                        .subscribe((status) => {
                            if (status !== 'error') {
                                 this.toasty.success('Project successfully deleted', 'Delete Project');
                            } else {
                                this.toasty.error('Error deleting the project', 'Delete Project');
                            }
                        }, (err) => {
                            console.error('Error deleting the project ', err);
                            this.toasty.error('Error deleting the project', 'Delete Project');
                        });
                }
            });
        }
    }

    addDesignation(event: MatChipInputEvent) {
        const input = event.input;
        const value = event.value;

        // Add our designation
        const newDesignation = (value || '').trim();
        const designationExists = some(this.designations, designation => designation.toLowerCase() === newDesignation.toLowerCase());
        if (value && !designationExists) {
            this.project.designations[newDesignation] = true;
            this.designations.push(newDesignation);

            // Reset the input value
            if (input) {
                input.value = '';
            }

            setTimeout(() => {
                input.focus();
            });

            this.project.saveProperty('designations', this.project.designations);
        }
    }

    removeDesignation(designation) {
        delete this.project.designations[designation];
        this.designations = without(this.designations, designation);
        this.project.saveProperty('designations', this.project.designations);
    }

    onPaletteChange(palette) {
        this.project.updatePalette(palette);
    }

    isMapEditEnabled() {
        return this.user.projects[this.project.id].preference.showMapEditor;
    }

    toggleMapEditor() {
        this.store
            .dispatch(new ToggleMapEditorVisibility())
            .subscribe(() => {
                setTimeout(() => {
                    window.dispatchEvent(new Event('resize'));
                });
            });
    }

    onLogoUploaded(event: FileUploadEvent) {
        this.storageService
            .getDownloadUrl(event.fullPath)
            .pipe(take(1))
            .subscribe(path => {
                this.project.updateLogo(path);
            });
    }

    onLogoCropped(blob: Blob | any) {
        if (blob instanceof Blob) {
            const urlCreator = window.URL || window['webkitURL'];
            const blobUrl = urlCreator.createObjectURL(blob);
            this.logoBlob = this.sanitizer.bypassSecurityTrustUrl(blobUrl);
        } else {
            this.logoBlob = blob;
        }
    }

    onReportFileSelected(file: FileRef, projectProp: string) {
        if (file) {
            this.project[projectProp].reportFile = {
                id: file.id,
                name: file.name
            };
            this.project.saveProperty(projectProp, this.project[projectProp]);

            if (projectProp === 'cprData') {
                this.refreshCPRReportFile();
            } else {
                this.refreshAssuranceReportFile();
            }
        }
    }


    deleteReport(prop: string) {
        if (prop === 'cprData') {
            this.project.cprData.reportFile = null;
            this.cprReportFile = null;
        } else {
            this.project.assuranceData.reportFile = null;
            this.assuranceReportFile = null;
        }
        this.project.saveProperty(prop, this.project[prop]);
    }

    filterCurrency() {
        if (this.currencyFilter.length > 0) {
            const searchText = this.currencyFilter.toLowerCase();
            const filtered = _filter(CURRENCIES, currency => {
                return currency.name.toLowerCase().includes(searchText)
                    || currency.symbol.toLowerCase().includes(searchText)
                    || currency.code.toLowerCase().includes(searchText);
            });
            this.filteredCurrencies$.next(filtered);
        } else {
            this.filteredCurrencies$.next(values(CURRENCIES));
        }
    }

    onSelectCurrency(event: MatSelectChange) {
        this.project.baseCurrencyCode = event.value.code;
        this.project.saveProperty('baseCurrencyCode', this.project.baseCurrencyCode);
    }

    downloadProject() {
        const project: any = this.project.toObject();
        project.maps = [];
        forEach(this.project.boards, map => {
            project.maps.push(map.toObject());
        });

        const blob = new Blob([JSON.stringify(project, null, '\t')], {
            type: 'text/json;charset=utf-8;'
        });

        const downloadButton = this.downloadElem.nativeElement;
        downloadButton.download = `${this.project.name}-${new Date().toUTCString()}.json`;
        downloadButton.href = window.URL.createObjectURL(blob);
        downloadButton.dataset.downloadurl = ['text/json', downloadButton.download, downloadButton.href].join(':');
        downloadButton.click();
    }

    uploadProject(file: File, input: HTMLInputElement) {
        if (file) {
            const reader = new FileReader();

            // Closure to capture the file information.
            reader.onloadend = (evt: any) => {
                if (evt.target.readyState === FileReader.DONE) {
                    try {
                        const project = JSON.parse(evt.target.result);
                        if (project) {
                            this.createProject(project);
                        } else {
                            throw new Error('No project data');
                        }
                    } catch (err) {
                        console.log('Error parsing project data ', err, evt.target.result);
                        this.toasty.error('Failed to import project. Corrupted data', 'Import Project', {timeOut: 5000, closeButton: true})
                      }
                }
            };

            // Read in the image file as a data URL.
            reader.readAsText(file);

            input.value = '';
        }
    }

    private createProject(project: any) {
        if (!isEmpty(project.maps)) {
            this.fuseProgressBarService.show();

            this.store
                .dispatch(new ImportProject(project, project.maps))
                .subscribe(() => {
                    this.toasty.success('Project imported successfully',  'Import Project', {timeOut: 2000});
                    this.fuseProgressBarService.hide();
                }, err => {
                    this.toasty.success('Error importing the project',  'Import Project', {timeOut: 5000});
                    this.fuseProgressBarService.hide();
                });
        }
    }
}
