import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';

import {of} from 'rxjs';
import {filter, switchMap, take} from 'rxjs/operators';

import {isUndefined} from 'lodash-es';

import {AuthService} from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private auth: AuthService, private router: Router) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.auth.onUserChange$.pipe(
            filter(user => !isUndefined(user)),
            take(1),
            switchMap((user) => {
                if (user) {
                    return of(true);
                }else{
                    console.log('Access denied. You must login');
                    return of(this.router.createUrlTree(['/login']));
                }
            })
        );
    }
}
