<cs-expandable-input [autoComplete$]="searchResultChanged$"
                     [opened]="opened"
                     [icon]="icon"
                     [placeholder]="placeholder"
                     (onChange)="searchTextChanged($event)"
                     (autoCompleteSelected)="searchResultSelected.emit($event)">
    <ng-template #autoCompleteItemTmpl let-item>
        <small [matTooltip]="getFullPath(item)">{{getDisplayPath(item)}}</small>
    </ng-template>
</cs-expandable-input>
