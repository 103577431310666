<form [formGroup]="dynamicForm" [attr.name]="formName" novalidate *ngIf="dynamicForm">
    <ng-container *ngFor="let control of formMetadata;let isLast = last" [ngSwitch]="isEditable(control)">
        <div class="mb-24" fxLayout="row" fxLayoutAlign="start start" *ngSwitchCase="false">
            <div fxFlex>
                <label class="as-mat-form-field-placeholder field-title">
                    {{control.label}}
                </label>

                <label class="as-mat-form-field-placeholder">
                    {{getDisplayValue(control)}}
                </label>
            </div>
        </div>
        <ng-container *ngSwitchCase="true" [ngSwitch]="control.type">
            <div class="mb-24" fxLayout="row" fxLayoutAlign="start start" *ngSwitchCase="isTextInputType(control)">
                <mat-icon class="mr-12 mt-12" *ngIf="control.icon">{{control.icon}}</mat-icon>
                <mat-form-field fxFlex>
                    <input name="{{control.id}}"
                           formControlName="{{control.id}}"
                           placeholder="{{control.label}}"
                           matInput
                           (keyup.enter)="onEnterKey(isLast)"
                           (blur)="onBlur(control)"
                           type="{{control.type}}">
                    <mat-error *ngIf="isControlInvalid(control)">
                        {{getControlError(control)}}
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="mb-24" fxLayout="row" fxLayoutAlign="start start" *ngSwitchCase="controlTypes.Select">
                <mat-form-field fxFlex>
                    <mat-select formControlName="{{control.id}}"
                                placeholder="{{control.label || ''}}"
                                (selectionChange)="onBlur(control)">
                        <mat-option *ngFor="let opt of control.options" [value]="opt.id">
                            {{opt.label}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="mb-24" fxLayout="row" fxLayoutAlign="start start" *ngSwitchCase="controlTypes.Slider">
                <div fxFlex>
                    <label class="as-mat-form-field-placeholder">
                        {{control.label}}
                    </label>

                    <mat-slider class="w-100-p"
                                formControlName="{{control.id}}"
                                [max]="control.options.max"
                                [min]="control.options.min"
                                [step]="1"
                                [thumbLabel]="false"
                                [tickInterval]="5"
                                (change)="onBlur(control)">
                    </mat-slider>
                </div>
            </div>
            <div class="mb-24" fxLayout="row" fxLayoutAlign="start start" *ngSwitchCase="controlTypes.TextArea">
                <mat-form-field fxFlex>
                <textarea matInput name="{{control.id}}"
                          formControlName="{{control.id}}"
                          placeholder="{{control.label}}"
                          (blur)="onBlur(control)">
                </textarea>
                </mat-form-field>
            </div>
        </ng-container>
    </ng-container>
</form>
