import {filter as _filter, isArray, isFunction, isObject, isString, some} from 'lodash-es';

export interface SearchOptions {
    getSearchableValues?: (obj: any, path: string) => any;
}

// @dynamic
export class FuseUtils {
    /**
     * Filter array by string
     *
     * @param mainArr
     * @param searchText
     * @param options
     * @returns {any}
     */
    public static filterArrayByString(mainArr: any[], searchText: string, options?: SearchOptions): any {
        if (searchText === '') {
            return mainArr;
        }

        searchText = searchText.toLowerCase();

        return _filter(mainArr, itemObj => {
            return this.searchInObj(itemObj, searchText, options);
        });
    }

    /**
     * Search in object
     *
     * @param itemObj
     * @param searchText
     * @param options
     * @param path
     * @returns {boolean}
     */
    public static searchInObj(itemObj: any, searchText: string, options?: SearchOptions, path?: string): boolean {
        const doUseOptions = options && isFunction(options.getSearchableValues);
        const target = doUseOptions ? options.getSearchableValues(itemObj, path) : itemObj;

        return some(target, (value: any, key: string) => {
            if (isString(value)) {
                return this.searchInString(value, searchText);
            } else if (isArray(value)) {
                return this.searchInArray(value, searchText, options, this.getPathString(path, key));
            } else if (isObject(value)) {
                return this.searchInObj(value, searchText, options, this.getPathString(path, key));
            } else {
                return false;
            }
        });
    }

    /**
     * Search in array
     *
     * @param arr
     * @param searchText
     * @param options
     * @param path
     * @returns {boolean}
     */
    public static searchInArray(arr: any[], searchText: string, options?: SearchOptions, path?: string): boolean {
        return some(arr, (value: any, index: number) => {
            if (isString(value)) {
                return this.searchInString(value, searchText);
            } else if (isArray(value)) {
                return this.searchInArray(value, searchText, options, this.getPathString(path, index));
            } else if (isObject(value)) {
                return this.searchInObj(value, searchText, options, this.getPathString(path, index));
            } else {
                return false;
            }
        });
    }

    private static getPathString(trail: string, key: string | number): string {
        if (trail) {
            const suffix = isString(key) ? key : `[${key}]`;
            return `${trail}.${suffix}`;
        } else {
            return isString(key) ? key as string : `[${key}]`;
        }
    }

    /**
     * Search in string
     *
     * @param value
     * @param searchText
     * @returns {any}
     */
    public static searchInString(value, searchText): any {
        return value.toLowerCase().includes(searchText);
    }

    /**
     * Generate a unique GUID
     *
     * @returns {string}
     */
    public static generateGUID(): string
    {
        function S4(): string
        {
            return Math.floor((1 + Math.random()) * 0x10000)
                       .toString(16)
                       .substring(1);
        }

        return S4() + S4();
    }

    /**
     * Toggle in array
     *
     * @param item
     * @param array
     */
    public static toggleInArray(item, array): void
    {
        if ( array.indexOf(item) === -1 )
        {
            array.push(item);
        }
        else
        {
            array.splice(array.indexOf(item), 1);
        }
    }

    /**
     * Handleize
     *
     * @param text
     * @returns {string}
     */
    public static handleize(text): string
    {
        return text.toString().toLowerCase()
                   .replace(/\s+/g, '-')           // Replace spaces with -
                   .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
                   .replace(/\-\-+/g, '-')         // Replace multiple - with single -
                   .replace(/^-+/, '')             // Trim - from start of text
                   .replace(/-+$/, '');            // Trim - from end of text
    }

    public static randomId(length: number = 10): string
    {
        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let name = '';

        for ( let i = 0; i < length; i++ )
        {
            name += chars.charAt(Math.floor(Math.random() * chars.length));
        }

        return name;
    }
}
