import {Component} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'cs-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
    public message = 'Are you sure?';

    public title = 'Confirm';
    public confirmButtonText = 'Confirm';
    public cancelButtonText = 'Cancel';


    constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>) {
    }

}
