import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {DynamicFormControlPermission, DynamicFormControlType, DynamicFormMetadata, SelectFieldOptions} from './dynamic-form-metadata.def';

import {find, forEach, isArray} from 'lodash-es';


export interface DynamicFormValueChange {
    control: DynamicFormMetadata;
    model: any;
    formValid: boolean;
}

@Component({
    selector: 'cs-dynamic-form',
    templateUrl: './dynamic-form.component.html',
    styleUrls: ['./dynamic-form.component.scss'],
    exportAs: 'dynamicForm'
})
export class DynamicFormComponent implements OnInit, OnChanges {

    @Input() formMetadata: DynamicFormMetadata[];

    @Output() onSubmitAttempt: EventEmitter<any> = new EventEmitter<any>();

    @Output() onValueChange: EventEmitter<DynamicFormValueChange> = new EventEmitter<DynamicFormValueChange>();

    dynamicForm: FormGroup;

    readonly controlTypes = DynamicFormControlType;

    formName: string;

    private formErrors: any;

    private hasCustomValidators;

    constructor(private formBuilder: FormBuilder) {
        const rand = Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000;
        this.formName = 'dynamicForm' + rand;
    }

    ngOnInit() {
        this.buildForm();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.formMetadata.currentValue) {
            this.buildForm();
        }
    }

    get model() {
        return this.dynamicForm.getRawValue();
    }

    isTextInputType(control: DynamicFormMetadata) {
        return (control.type === this.controlTypes.Text ||
            control.type === this.controlTypes.Password ||
            control.type === this.controlTypes.Number ||
            control.type === this.controlTypes.Email) ? control.type : '';
    }

    isControlInvalid(controlMetadata: DynamicFormMetadata) {
        const formControl = this.dynamicForm.get(controlMetadata.id);
        return formControl.dirty && formControl.invalid;
    }

    getControlError(controlMetadata: DynamicFormMetadata) {
        let message = '';
        forEach(controlMetadata.validationMessages, (msg, key) => {
            if (this.formErrors[controlMetadata.id][key]) {
                message = msg;
                return false; // break the loop
            }
        });
        return message;
    }

    isEditable(control: DynamicFormMetadata) {
        return !control.permission || control.permission === DynamicFormControlPermission.Write;
    }

    getDisplayValue(control: DynamicFormMetadata) {
        let val = this.model[control.id];
        if (control.type === DynamicFormControlType.Select) {
            const opt = (control.options as SelectFieldOptions[]).find(item => item.id === val);
            if (opt) {
                val = opt.label;
            }
        }
        return val;
    }

    isValid() {
        return this.dynamicForm.dirty && !this.dynamicForm.invalid;
    }

    onBlur(controlMetadata: DynamicFormMetadata) {
        const formControl = this.dynamicForm.get(controlMetadata.id);
        if (formControl.dirty && !formControl.invalid) {
            this.onValueChange.emit({
                control: controlMetadata,
                model: this.model,
                formValid: this.isValid()
            });
        }
    }

    onEnterKey(isLast) {
        if (isLast && this.isValid()) {
            this.onSubmitAttempt.emit(this.model);
        }
    }

    private buildForm() {
        const formData = {};
        this.formErrors = {};
        this.hasCustomValidators = false;
        forEach(this.formMetadata, (formControlData) => {
            let validators = [];
            if (formControlData.required) {
                validators.push(Validators.required);
            }

            if (formControlData.type === DynamicFormControlType.Email) {
                validators.push(Validators.email);
            }

            if (isArray(formControlData.validators) && formControlData.validators.length > 0) {
                validators = validators.concat(formControlData.validators);
                this.hasCustomValidators = true;
            }

            formData[formControlData.id] = [formControlData.value || '', validators];
            this.formErrors[formControlData.id] = {};
        });
        this.dynamicForm = this.formBuilder.group(formData, {updateOn: 'blur'});

        this.dynamicForm.valueChanges.subscribe(() => {
            this.onFormValuesChanged();
        });
    }

    private onFormValuesChanged() {
        // we may have custom validators which are dependent on other field values.
        // so validate all fields when a field is edited
        // if (this.hasCustomValidators) {
        //     forEach(this.dynamicForm.controls, control => control.updateValueAndValidity());
        // }

        for (const field in this.formErrors) {
            if (!this.formErrors.hasOwnProperty(field)) {
                continue;
            }

            // Clear previous errors
            this.formErrors[field] = {};

            // Get the control
            const control = this.dynamicForm.get(field);

            if (control && control.dirty && !control.valid) {
                this.formErrors[field] = control.errors;
            }
        }
    }
}
