import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';

import {AuthService} from './auth.service';

import {Observable} from 'rxjs';
import {map, take, tap} from 'rxjs/operators';
import {UserRole} from './user.model';

@Injectable()
export class ProjectAdminGuard implements CanActivate {
    constructor(private auth: AuthService, private router: Router) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        return this.auth.onUserChange$.pipe(
            take(1),
            map((user) => {
                return !!user && user.allow(UserRole.ProjectAdmin);
            }),
            tap((isAdmin) => {
                if (!isAdmin) {
                    console.log('Illegal operation. Access denied.');
                    this.auth.signOut();
                }
            }));
    }
}
