import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {AuthGuard, AuthService} from '@cultursys/core';
import {FuseForgotPasswordComponent} from './login/forgot-password/forgot-password.component';
import {VerifyLoginUrlComponent} from './verify-login-url/verify-login-url.component';

const routes: Routes = [
    {path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule)},
    {path: 'verify', component: VerifyLoginUrlComponent},
    {path: 'forgot-password', component: FuseForgotPasswordComponent},
    // project routes
    {
        path: 'apps',
        canActivate: [AuthGuard],
        resolve: {
            activeUser: AuthService
        },
        loadChildren: () => import('./main/apps/apps.module').then(m => m.AppsModule)
    },
    {path: '', pathMatch: 'full', redirectTo: '/apps/project/summary'},
    {path: '**', redirectTo: '/apps/project/summary'}
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { enableTracing: false, relativeLinkResolution: 'legacy' })
    ],
    exports: [
        RouterModule
    ],
    providers: [AuthGuard],
})
export class AppRoutingModule {
}
