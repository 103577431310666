import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {AddFolderDialogComponent} from '../add-folder-dialog/add-folder-dialog.component';

@Component({
    selector: 'cs-file-manager-dialog',
    templateUrl: './file-manager-dialog.component.html',
    styleUrls: ['./file-manager-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FileManagerDialogComponent implements OnInit {

    constructor(public dialogRef: MatDialogRef<FileManagerDialogComponent>) {
    }

    ngOnInit() {
    }

}
