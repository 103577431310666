import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

import {extend} from 'lodash-es';


export interface ErrorMessageConfig {
    message: string;
}

@Injectable()
export class ErrorRoutingService {

    errorConfig: ErrorMessageConfig = {
        message: 'Oops, something went terribly wrong!'
    };

    constructor(private router: Router) {
    }

    showErrorPage(config: ErrorMessageConfig): Promise<boolean> {
        extend(this.errorConfig, config);

        return this.router.navigate(['apps', 'error', 'default']);
    }
}
