<mat-toolbar class="p-0 h-44">
    <div fxFlex fxFill fxLayout="row" fxLayoutAlign="space-between center">
        <!--First Third: Logo and Menu-->
        <section
            fxFlex="1 0 auto"
            fxLayout="row"
            fxLayoutAlign="start end"
            class="h-100-p"
        >
            <div
                fxLayout="row"
                fxLayoutAlign="start center"
                class="mr-32"
                style="align-self: center"
            >
                <div class="logo ml-16">
                    <a href="https://www.pyxisculture.com/" target="_blank">
                        <img
                            class="logo-icon"
                            src="assets/images/logos/pyxis_logo.png"
                            alt="Pyxis Culture Logo"
                        />
                    </a>
                </div>
            </div>

            <cs-main-nav-menu fxFlex="0 0 500px"></cs-main-nav-menu>
        </section>

        <!--Second Third: Project Name -->
        <section
            class="overflow-hidden"
            fxFlex="1 1 auto"
            fxLayout="row"
            fxLayoutAlign="start center"
        >
            <div
                fxLayout="row"
                *ngIf="user?.activeProject && !hideMenu"
                class="overflow-hidden"
            >
                <div
                    fxLayout="row nowrap"
                    fxLayoutAlign="center center"
                    fxLayoutAlign.lt-lg="start center"
                    class="overflow-hidden"
                >
                    <img
                        *ngIf="user && user.activeProject.logoUrl"
                        [src]="user.activeProject.logoUrl"
                        class="h-40 mr-12"
                    />
                    <div class="project-title">
                        {{ user?.activeProject.name }}
                    </div>
                </div>
            </div>
        </section>

        <!--Third Section: User Menu -->
        <section
            fxFlex="1 1 40%"
            fxFlex.lt-xl="1 1 20%"
            fxLayout="row"
            fxLayoutAlign="end center"
        >
            <button
                mat-button
                [matMenuTriggerFor]="userMenu"
                class="user-button"
            >
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img
                        class="avatar h-24 w-24"
                        *ngIf="user"
                        [src]="user.avatar"
                    />
                    <span class="username mr-12" fxHide fxShow.gt-sm>{{
                        user?.name
                    }}</span>
                    <mat-icon class="s-16" fxHide.xs
                        >keyboard_arrow_down</mat-icon
                    >
                </div>
            </button>

            <mat-menu #userMenu="matMenu" [overlapTrigger]="false">
                <button mat-menu-item (click)="showProfilePage()">
                    <mat-icon>account_circle</mat-icon>
                    <span>My Profile</span>
                </button>

                <cs-project-picker
                    title="My Projects"
                    icon="file_copy"
                    (projectSelected)="setActiveProject($event)"
                    [allowAdd]="true"
                    #projectPicker
                >
                </cs-project-picker>

                <button mat-menu-item (click)="showFileManager()">
                    <mat-icon>folder</mat-icon>
                    <span>My Files</span>
                </button>

                <ng-container *ngIf="user?.isAdmin">
                    <mat-divider></mat-divider>

                    <button
                        mat-menu-item
                        (click)="toggleSidebarOpen('quickPanel')"
                    >
                        <mat-icon>settings</mat-icon>
                        <span>Project Settings</span>
                    </button>

                    <!--                    <button mat-menu-item routerLink="/apps/administrator/users">-->
                    <!--                        <mat-icon>account_box</mat-icon>-->
                    <!--                        <span>User Settings</span>-->
                    <!--                    </button>-->

                    <mat-divider></mat-divider>
                </ng-container>

                <button mat-menu-item class="" (click)="logout()">
                    <mat-icon>exit_to_app</mat-icon>
                    <span>Logout</span>
                </button>
            </mat-menu>

            <!--<div class="toolbar-separator"></div>-->

            <!--<button mat-icon-button-->
            <!--*ngIf="!hideMenu"-->
            <!--class="quick-panel-toggle-button"-->
            <!--(click)="toggleSidebarOpen('quickPanel')"-->
            <!--aria-label="Toggle quick panel">-->
            <!--<mat-icon class="icon">settings</mat-icon>-->
            <!--</button>-->
        </section>
    </div>
</mat-toolbar>
