import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';

import {BehaviorSubject, Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {isEmpty, some, filter as _filter} from 'lodash-es';

import {ExpandableInputAutoCompleteItem} from 'app/common/expandable-input/expandable-input.component';

import {Store} from '@ngxs/store';

import {FileManagerState} from '../store/file-manager.state';
import {FileRef} from '../file-ref.def';



@UntilDestroy()
@Component({
        selector: 'file-search',
        templateUrl: './file-search.component.html',
        styleUrls: ['./file-search.component.scss']
    })
export class FileSearchComponent implements OnInit, OnDestroy {

    @Input() opened = false;
    @Input() icon = 'search';
    @Input() placeholder = '';
    @Input() fileTypes: string[] = [];

    @Output() searchResultSelected = new EventEmitter<FileRef>();

    searchResultChanged$ = new BehaviorSubject<ExpandableInputAutoCompleteItem[]>([]);

    private searchTextChangeSource = new Subject<string>();

    constructor(private store: Store) {
    }

    ngOnInit() {
        this.searchTextChangeSource.asObservable()
            .pipe(
                debounceTime(250),
                untilDestroyed(this)
            )
            .subscribe(text => {
                if (text) {
                    const lowerCaseText = text.toLowerCase();
                    const files: FileRef[] = this.store.selectSnapshot(FileManagerState.files);
                    const filtered = _filter(files, (file: FileRef) => {
                        const matchesFileType = isEmpty(this.fileTypes) ? true : some(this.fileTypes, type => file.name.toLowerCase().endsWith('.' + type.toLowerCase()));
                        if (matchesFileType) {
                            return file.name.toLowerCase().includes(lowerCaseText) ||
                                some(file.path, pathElem => pathElem.toLowerCase().includes(lowerCaseText));
                        } else {
                            return false;
                        }
                    });
                    this.searchResultChanged$.next(filtered);
                }
            });

    }

    ngOnDestroy(): void {
    }

    searchTextChanged(text: string) {
        this.searchTextChangeSource.next(text);
    }

    getDisplayPath(file: FileRef) {
        const path = file.path.length === 0 ? '' : file.path.join('\\');
        const trimmedPath = path.length > 10 ? '...' + path.substr(path.length - 10, 10) : path;

        return trimmedPath.length > 0 ? trimmedPath + '\\' + file.name : file.name;
    }

    getFullPath(file: FileRef) {
        return [...file.path, file.name].join('/');
    }

}
