<div class="drop-zone-wrapper">
    <form>
        <input type="file" #fileSelectorInput (change)="preProcessFile($event.target.files)" hidden>
        <div class="image"
             (click)="fileSelectorInput.click()"
             [@imageHover]="isHovering ? 'hoveringImage' : 'notHoveringImage'"
             matTooltip="Drop new image here or click me to select"
             matTooltipPosition="below"
             dropZone
             (hovering)="dropzoneHovering($event)"
             (dropped)="preProcessFile($event)">

            <div class="avatar-overlay" [class.square]="!isCircle"></div>
            <ng-content></ng-content>
        </div>
    </form>
</div>
