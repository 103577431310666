import {clamp, cloneDeep, forEach, get, isEmpty, isNumber, kebabCase, pick, random, remove, sumBy} from 'lodash-es';

import {FuseUtils} from '../../@fuse/utils';
import {BehaviorSubject, Observable} from 'rxjs';
import {HistoricalValue} from './board.model';
import {ColorHelper} from './color.helper';
import {FileAttachment} from '@cultursys/file-manager';
import {KbArticleCategory, KbArticleCategoryProperty, KbCategoryHash} from '../main/apps/knowledgebase/article.model';
import {ISnippetList} from '../main/components/snippets/snippet.types';

export enum LinkOrigin {
    Input = 'inputs',
    Output = 'outputs'
}

export interface Question {
    id: string;
    label: string;
    score: number;
    worstCaseValue: string;
    min: number;
    max: number;
}

export interface Questionnaire {
    questions: Question[];
    minTotal: number;
    maxTotal: number;
}

export interface SwimLane {
    id: string;
    label: string;
    order: number;
    width?: number;
}

export class Node {
    key: string;
    name: string; // subscribe.state.node[123].name
    id: string;
    selector: HTMLElement;
    inputs: {};
    outputs: {};
    // jsPlumb connections hashed by node id
    connections: {};
    questionCount: number;
    min: number;
    max: number;
    maxValue: number;
    minValue: number;
    value: number;
    targetValue: number;
    normalizedValue: number;
    calculationDetails: string;
    score: number;
    initialScore: number;
    previousScore: number;
    position: { top: number, left: number };
    questionnaire: Questionnaire;
    recommendations: {
        snippets: ISnippetList,
        articleIds?: string[],
        text: string;
        attachments: Array<FileAttachment>
    };
    bestPractices: {
        snippets: ISnippetList,
        articleIds?: string[],
        text: string;
        attachments: Array<FileAttachment>
    };
    profile: {
        snippets: ISnippetList,
        articleIds?: string[],
        text: string;
        attachments: Array<FileAttachment>
    };
    references: {
        snippets: ISnippetList,
        articleIds?: string[],
        text: string;
        attachments: Array<FileAttachment>
    };

    isPositioned: boolean;
    isGauge = false;

    private _onNodeChange$ = new BehaviorSubject<Node>(this);

    percentageChange = 0;

    private palette: string[];

    constructor(nodeDef: any = {}) {
        const key = nodeDef.name,
            questionCount = (nodeDef.questionnaire && nodeDef.questionnaire.questions) ? nodeDef.questionnaire.questions.length || 1 : 1;

        this.name = nodeDef.name || 'Untitled Node';
        this.key = nodeDef.key || 'untitled-node-' + FuseUtils.generateGUID();
        this.id = nodeDef.id || 'node-' + FuseUtils.generateGUID();
        this.selector = null;
        this.inputs = nodeDef.inputs || {};
        this.outputs = nodeDef.outputs || {};
        // this.connections = {};
        this.questionCount = questionCount;
        this.min = questionCount;
        this.max = questionCount * 6;
        this.maxValue = questionCount * 6;
        this.minValue = questionCount;
        this.value = 0;
        this.targetValue = nodeDef.targetValue || 0;
        this.normalizedValue = 0;
        this.calculationDetails = '';
        this.profile = nodeDef.profile || {articleIds: [], text: '', attachments: [], snippets: []};
        this.recommendations = nodeDef.recommendations || {articleIds: [], text: '', attachments: [], snippets: []};
        this.bestPractices = nodeDef.bestPractices || {articleIds: [], text: '', attachments: [], snippets: []};
        this.references = nodeDef?.references || {articleIds: [], text: '', attachments: [], snippets: []};
        this.questionnaire = nodeDef.questionnaire || this.defaultQuestionnaire;
        this.isPositioned = false;
        this.position = nodeDef.position || {top: random(600), left: random(1000)};
        this.isGauge = nodeDef.isGauge || nodeDef.name === 'Dashboard';

        this.initialScore = this.isGauge ? 0 : this.max;
        this.previousScore = this.isGauge ? 0 : this.max;
        this.score = this.max;

        // we have moved min and max to questions from questionnaire. following code ensures backward compatibility.
        const questionnaireMin = Number(this.questionnaire['min']);
        const questionnaireMax = Number(this.questionnaire['max']);
        if (isNumber(questionnaireMin) && isNumber(questionnaireMax)) {
            this.questionnaire.questions.forEach(question => {
                if ((!isNumber(question.min) || isNaN(question.min))) {
                    question.min = questionnaireMin;
                }
                if ((!isNumber(question.max) || isNaN(question.max))) {
                    question.max = questionnaireMax;
                }
            });
        }

        this.setQuestionnaireRange();
    }

    get normalizationFactor() {
        return isEmpty(this.inputs) ? 1 : 1.5;
    }

    get defaultQuestionnaire() {
        return {
            questions: [],
            min: 1,
            max: 6,
            minTotal: 0,
            maxTotal: 0,
            minLabel: '1',
            maxLabel: '6'
        };
    }

    get onNodeChange$(): Observable<Node> {
        return this._onNodeChange$.asObservable();
    }

    private setQuestionnaireRange() {
        this.questionnaire.minTotal = sumBy(this.questionnaire.questions, q => q.min);
        this.questionnaire.maxTotal = sumBy(this.questionnaire.questions, q => q.max);
    }

    broadcastChange() {this._onNodeChange$.next(this)}

    colorize() {
        // if (this.selector) {
        //     // (<HTMLElement>this.selector.firstElementChild).style['background-color'] = colorGetter(this.normalizedScore * 100);
        //     const value = Math.round(this.normalizedScore * 100);
        //     const index = Math.round(clamp(value, 0, this.palette.length - 1));
        //     const track = this.selector.getElementsByClassName('ant-progress-circle-path')[0] as HTMLElement;
        //     if (track) {
        //         track.style.stroke = this.palette[index];
        //         console.log('colorize() ', this.name, index, this.palette[index]);
        //
        //     }
        //      // (<HTMLElement>this.selector.firstElementChild).style['background-color'] = this.palette[index];
        // }
    }

    getScoreColor() {
        const index = clamp(Math.round(this.normalizedScore * 100) - 1, 0, this.palette.length - 1);
        // console.log('getScoreColor() ', this.name, index, this.palette[index]);
        return this.palette[index];
    }

    setPalette(palette: string[]) {
        this.palette = palette;
    }

    getPalette(): string[] {
        return this.palette;
    }

    calculatePercentChange(newNormalizedScore): number {
        return newNormalizedScore - this.normalizedScore;
    }

    get isBackgroundDark() {
        const color = this.palette[clamp(Math.round(this.normalizedScore * 100), 0, 99)];
        return color ? ColorHelper.isBetterContrastOnBlack(color) : false;
    }

    addKbArticle(id: string, category: KbArticleCategory) {
        const type = KbCategoryHash[category];

        this[type].articleIds = get(this[type], 'articleIds', []);

        if (id && type &&  this[type].articleIds.indexOf(id) === -1) {
            this[type].articleIds.push(id);
        }
    }

    removeKbArticle(id: string, category: KbArticleCategory) {
        const type = KbCategoryHash[category];
        if (id && type) {
            const index = this[type].articleIds.indexOf(id);
            if (index > -1) {
                this[type].articleIds.splice(index, 1);
            }
        }
    }

    setPosition() {
        if (this.selector) {
            this.selector.style.top = this.position.top + 'px';
            this.selector.style.left = this.position.left + 'px';
            this.isPositioned = true;
        }
    }

    calculatePercentageChange(newScore: number, oldScore: number) {
        const oldNormalizedScore = this.normalizeSliderValue(oldScore);
        const newNormalizedScore = this.normalizeSliderValue(newScore);
        // const delta = oldScore !== 0 ? (this.normalizedScore - oldScore)/oldScore : 0;
        const delta = newNormalizedScore - oldNormalizedScore;

        this.percentageChange = delta;

        // console.log('Percentage change for ', this.name, ' = ', this.percentageChange);
    }

    setAbsolutePosition(width: number, height: number) {
        if (this.selector && width !== 0 && height !== 0) {
            this.selector.style.top = this.position.top * height + 'px';
            this.selector.style.left = this.position.left * width + 'px';
            this.isPositioned = true;
        }
    }

    get container() {
        return this.selector;
    }

    set displayName(name: string) {
        this.name = name;
        if (this.key.startsWith('untitled-node')) {
            this.key = kebabCase(this.name) + '-' + FuseUtils.generateGUID();
        }
    }

    get displayName(): string {
        return this.name;
    }

    addLink(origin: LinkOrigin, connectsTo: string, weight: number) {
        this[origin][connectsTo] = weight;
        // console.log('Adding Link ', origin, connectsTo, weight);
    }

    deleteLink(connectsTo: string) {
        // console.log('Deleting Link ', connectsTo);
        if (this.inputs.hasOwnProperty(connectsTo)) {
            delete this.outputs[connectsTo];
        } else if (this.outputs.hasOwnProperty(connectsTo)) {
            delete this.inputs[connectsTo];
        }
    }

    updateQuestionnaireScore() {
        // this.previousScore = this.score;
        this.setQuestionnaireRange();
        this.score = sumBy(this.questionnaire.questions, 'score');
        this.initialScore = this.score;
        // this.previousScore = oldScore;
    }

    applyHistoricQuestionScores(historicValues: HistoricalValue) {
        const driverObj = historicValues.driverScores[this.id];
        if (driverObj) {
            forEach(this.questionnaire.questions, question => {
                const scoreObj = driverObj.questions[question.id];
                const score = scoreObj ? Number(scoreObj.score) : 0;
                question.score = isNaN(score) ? 0 : score;
            });
            this.updateQuestionnaireScore();
            driverObj.score = this.normalizedScore;
        } else {
            console.warn('Historic value not found for node ', this.id);
        }
    }

    setArticle(type: KbArticleCategoryProperty, articleId: string): boolean {
        let success = false;
        if (this[type]) {
            this[type].articleIds[0] = articleId;
            success = true;
        }
        return success;
    }


    get normalizedScore() {
        return this.normalizeSliderValue(this.score);
    }

    normalizeSliderValue(val: number): number {
        const min = this.questionnaire.minTotal,
            max = this.questionnaire.maxTotal,
            range = (max - min);
        return this.isGauge ? 1 : range === 0 ? 0 : (val - min) / range;

    }

    absoluteScore(normalizedScore: number): number {
        const min = this.questionnaire.minTotal,
            max = this.questionnaire.maxTotal,
            range = (max - min),
            score = this.isGauge ? 1 : range === 0 ? 0 : (normalizedScore * range + min);
        return score;
    }

    addConnection(connectionEvent) {
        let canConnect = true;
        const params = connectionEvent.connection.getParameters();
        if (connectionEvent.connection) {
            // if (this.connections[connectionEvent.targetId]) {
            //     dashboardService.plumber.detach(this.connections[connectionEvent.targetId]);
            // }
            this.connections[connectionEvent.targetId] = connectionEvent.connection;
        } else {
            canConnect = false;
            console.warn('@Warning:Node::addConnection  Refusing to add node without parameters');
        }
        return canConnect;
    }

    deleteConnection(connectionEvent) {
        const canDelete = true;
        const params = connectionEvent.connection ? connectionEvent.connection.getParameters() : {};
        // if (!_.isEmpty(params)) {
        if (true) {
            delete this.connections[connectionEvent.targetId];
        } else {
            // canDelete = false;
            // console.warn('@Warning:Node::deleteConnection  Refusing to delete node without parameters');
        }
        return canDelete;
    }

    getConnectionTo(nodeId) {
        return this.connections[nodeId];
    }

    addQuestion(label: string, score = 1, worstCaseValue = 'min', id?: string) {
        const firstQ = this.questionnaire.questions[0];

        this.questionnaire.questions.push({
            id: isEmpty(id) ? FuseUtils.generateGUID() : id,
            label: label,
            score: score,
            worstCaseValue: worstCaseValue,
            min: firstQ ? firstQ.min : 1,
            max: firstQ ? firstQ.max : 6
        });
        this.updateQuestionnaireScore();
    }

    removeQuestion(question: Question) {
        remove(this.questionnaire.questions, {id: question.id});
        this.updateQuestionnaireScore();
    }

    onWorstCaseValueChange(worstCaseValue: string, question: Question) {
        const changed = question.worstCaseValue !== worstCaseValue;
        if (changed) {
            question.score = this.computeQuestionScore(question);
            question.worstCaseValue = worstCaseValue;
        }
        this.updateQuestionnaireScore();
    }

    setQuestionScore(score: number, question: Question) {
        if (question.worstCaseValue === 'max') {
            question.score = this.computeQuestionScore(question);
        } else {
            question.score = score;
        }
        this.updateQuestionnaireScore();
    }

    getQuestionDisplayScore(question: Question): number {
        if (question.worstCaseValue === 'max') {
            return this.computeQuestionScore(question);
        } else {
            return question.score;
        }
    }

    createDefaultHistoricValue(): { score: number; questions: { [id: string]: Question } } {
        const valueObj = {
            score: 0,
            questions: {}
        };
        this.questionnaire.questions.forEach(q => {
            const qCopy = cloneDeep(q);
            qCopy.score = q.worstCaseValue === 'min' ? q.min : q.max;
            valueObj.questions[q.id] = qCopy;
        });
        return valueObj;
    }

    toObject() {
        return pick(this, ['id', 'key', 'name', 'inputs', 'outputs', 'min', 'max', 'minValue', 'maxValue', 'value',
            'targetValue', 'normalizedValue', 'calculationDetails', 'score', 'position', 'questionnaire', 'isGauge', 'recommendations',
            'bestPractices', 'profile', 'references']);
    }

    private computeQuestionScore(question: Question) {
        const min = question.min;
        const max = question.max;
        return max + min - question.score;
    }

    public computeQuestionValue(question: Question, score = question.score) {
        const min = question.min;
        const max = question.max;
        const range = Math.abs(max - min);
        const normalized = range > 0 ? (score - min) / range : 0;
        return question.worstCaseValue === 'max' ? 1 - normalized : normalized;
    }

}
