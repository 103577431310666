import {Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'wi-croppie-dialog',
    templateUrl: './croppie-dialog.component.html',
    styleUrls: ['./croppie-dialog.component.scss']
})
export class CroppieDialogComponent {
    croppieImage: any;

    croppedImage: any;


    constructor(public dialogRef: MatDialogRef<CroppieDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    }


    newImageResultFromCroppie(data) {
        this.croppedImage = data;
    }
}
