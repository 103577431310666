import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {capitalize, cloneDeep, compact, countBy, find, flatten, forEach, get, isEmpty, isEqual, map as _map, omit, orderBy, reject, trimEnd, uniq} from 'lodash-es';
import {KbArticleCategory, KbArticleCategoryProperty, KbArticleDef, KbArticleStatus, KbCategoryHash} from '../main/apps/knowledgebase/article.model';
import {NzUploadXHRArgs} from 'ng-zorro-antd/upload';
import {finalize, map} from 'rxjs/operators';
import {AuthService, Board, Project} from '@cultursys/core';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {AppState} from '../store/app.state';
import {Store} from '@ngxs/store';
import {Node} from './node.model';

import {AngularFirestore} from '@angular/fire/compat/firestore';
import {MatSnackBar} from '@angular/material/snack-bar';
import * as lunr from 'lunr';
import {Facet, SnippetCategoryProperty} from '../main/components/snippets/snippet.types';

export interface LegacyArticle {
    id: string;
    type: KbArticleCategory;
    text: string;
    select: boolean;
    companies: string[];
    nodeId: string;
    nodeName: string;
    board: Board;
    boardName: string;
    node: Node;
}

// TODO cleanup observables???
@Injectable({
    providedIn: 'root',
})
export class KbArticlesService implements Resolve<any> {
    articles: KbArticleDef[] = [];
    article: KbArticleDef;



    onNewLegacyArticles: BehaviorSubject<LegacyArticle[]> = new BehaviorSubject([]);
    onArticlesChanged: BehaviorSubject<KbArticleDef[]> = new BehaviorSubject([]);
    onArticleAssigned: BehaviorSubject<KbArticleDef> | null = new BehaviorSubject(null);
    onArticleChanged: BehaviorSubject<KbArticleDef> | null = new BehaviorSubject(null);
    onIndexChange: BehaviorSubject<boolean> = new BehaviorSubject(false);

    filteredArticles: Array<{ value: any; label: string }> = [];

    facets: Facet[] = [
        {title: 'Article Type', idxKey: 'category', docKey: 'category', options: [], selected: [], showCount: 5, showFilter: false},
        {title: 'Companies', idxKey: 'company', docKey: 'companies', options: [], selected: [], showCount: 5, showFilter: true},
        {title: 'Key Words', idxKey: 'tag', docKey: 'tags', options: [], selected: [], showCount: 10, showFilter: true}
    ];


    project: Project;
    map: Board;
    node: Node;

    _selectedArticle: KbArticleDef;
    private _companyList: string[] = [];


    idx: any;

    doReset = false;

    constructor(private afs: AngularFirestore,
                private storage: AngularFireStorage,
                private authService: AuthService,
                private store: Store,
                private snackBar: MatSnackBar) {

        this.reactToProjectChange();

    }

    /**
     * Resolve
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.reactToProjectChange();
        // return ;
    }


    // get articles(): KbArticleDef[] {
    //     if (!this._articles) {
    //         this.loadArticles()
    //             .then(() => {
    //                 this.reactToProjectChange();
    //                 return this._articles;
    //             });
    //     } else {
    //         return this._articles;
    //     }
    // }

    public get selectedArticle(): KbArticleDef {
        return this._selectedArticle;
    }

    public getArticleById$(id: string): Observable<KbArticleDef> {
       this._selectedArticle = find(this.getAll(), {id: id});
        return of(this.selectedArticle);
    }

    public getArticleById(id: string): KbArticleDef {
       return find(this.getAll(), {id: id});
    }


    public selectArticleById(articleId: string) {
        this.fetchArticle$(articleId)
            .subscribe(article => {
                this._selectedArticle = article;
                this.onArticleChanged.next(article);
            });
    }

    get isLoaded(): boolean {
        return this.articles.length > 0;
    }

    get companyList(): string[] {
        if (isEmpty(this._companyList)) {
            this._companyList = compact(uniq(flatten(this.getAll().map( article => article.companies))));
        }
        return this._companyList;
    }



    public fetchArticle$(articleId: string): Observable<KbArticleDef> {
        const article = find(this.articles, {id: articleId});
        if (article) {
            this._selectedArticle = article;
            return of(this._selectedArticle);
        } else {
            return this.afs.doc<KbArticleDef>(`articles/${articleId}`)
                .get()
                .pipe(
                    map((snapshot) => {
                        if (snapshot.exists) {
                            this._selectedArticle = <KbArticleDef>snapshot.data();

                            this.articles.push(this._selectedArticle);
                        }
                        return this._selectedArticle;
                    })
                );
        }
    }

    public fetchArticle(id): Promise<KbArticleDef> {
        return this.fetchArticle$(id).toPromise();
    }


    public getAll(): KbArticleDef[] {
        if (isEmpty(this.articles)) {
        } else {
            return this.articles;
        }
    }

    initSearch(): KbArticleDef[] {
        const all = this.getAll();

        this.idx = lunr(function () {
            this.ref('id');
            this.field('tag', {boost: 8});
            this.field('content', {boost: 1});
            this.field('title', {boost: 2});
            this.field('slug', {boost: 1});
            this.field('company', {boost: 10});
            this.field('category', {boost: 15});
            this.field('fileName', {boost: 1});

            // the stemmer which creates tokens based on word stems creates pathologies.  Remove it at the cost of speed.
            this.pipeline.remove(lunr.stemmer);
            this.searchPipeline.remove(lunr.stemmer);

            // since lunr doesn't search in arrays, stringify 'em
            all.forEach((article) => {
                const kbDoc = cloneDeep(article);
                const doc = omit(kbDoc, ['tags', 'companies', 'media.fileName']);
                doc['tag'] = get(kbDoc, 'tags', []).toString().replace(/,/g, ' ');
                doc['company'] = get(kbDoc, 'companies', ['unknown']).toString().replace(/,/g, ' ');
                doc['filename'] = get(kbDoc, 'media.fileName', '');
                console.log('New doc added to index: ', doc);
                this.add(doc);
            });
        });

        return all;
    }

    search(query: string, facetFilter: string): KbArticleDef[] {
        if (isEmpty(this.idx)) { this.initSearch(); }

        let hits: KbArticleDef[];
        let searchFor: string;
        let res: any;

        // remove any incomplete query symbols at the end of the query
        searchFor = trimEnd(query, ' _-+!~^');

        if (isEmpty(query + facetFilter)) {
            hits = [];
        } else {
            let fuzzy = searchFor.replace(/\b\w+\b/g, `$&*`);
            fuzzy = fuzzy.replace('*:', ':');
            fuzzy = fuzzy.replace('**', '*');
            // console.log('Searching again for ', fuzzy + facetFilter);
            console.log('Searching for: ', fuzzy + facetFilter);
            res = this.idx.search(fuzzy + facetFilter);
            hits = _map(res, (hit): KbArticleDef => find(this.articles, {id: hit.ref}));
        }
        return hits;
    }

    public saveArticle(article: KbArticleDef, showSnack = true): Promise<any> {
        const oldArticle = find(this.articles, {id: article.id});
        // Is the article actually different?
        if (isEqual(oldArticle, article)) {
            return new Promise((resolve) => resolve({}));
        }

        return this.afs.doc<KbArticleDef>(`articles/${article.id}`).set(article)
            .then((docRef) => {
                this.articles = reject(this.articles, {id: article.id});
                this.articles.push(article);
                this.addNewCompanyToList(article);
                this.initSearch();
                this.onArticleChanged.next(article);
                this.onIndexChange.next(true);

                if (showSnack) {
                    // Show the success message
                    this.snackBar.open('Article Saved', 'OK', {
                        verticalPosition: 'top',
                        duration: 2000
                    });
                }
                return of({});
            })
            .catch((error) => {
                console.error('Error saving article: ', error);
            });
    }

    public assignArticleToDriver(nodeId: string, articleCategory =  this._selectedArticle.category) {
        const category = KbCategoryHash[articleCategory];
        const board = this.project.activeBoard;
        const node = board.nodeDefs[nodeId];

        if (node) {
            node[category].articleIds[0] = this.selectedArticle.id;

            this.project.saveBoard(board)
                .then(() => {
                    this.onArticleAssigned.next(this._selectedArticle);
                    this.snackBar.open(`Article successfully assigned to driver ${node.name}`, 'OK', {
                        verticalPosition: 'top',
                        duration: 5000
                    });
                })
                .catch(err => {
                    console.error(`Unable to assign article to driver ${node.name}`, err);
                    this.snackBar.open(`Unable to assign article to driver ${node.name}`, 'OK', {
                        verticalPosition: 'top',
                        duration: 5000
                    });
                });
        }

    }


    public unAssignArticleFromDriver(nodeId: string, category:  SnippetCategoryProperty ) {
        const board = this.project.activeBoard;
        const node = board.nodeDefs[nodeId];

        if (node) {
            node[category].articleIds = [];

            this.project.saveBoard(board)
                .then(() => {
                    this.snackBar.open(`Article successfully un-assigned to driver ${node.name}`, 'OK', {
                        verticalPosition: 'top',
                        duration: 5000
                    });
                })
                .catch(err => {
                    console.error(`Unable to assign article to driver ${node.name}`, err);
                    this.snackBar.open(`Unable to assign article to driver ${node.name}`, 'OK', {
                        verticalPosition: 'top',
                        duration: 5000
                    });
                });
        }

    }

    public deleteArticle(article): Promise<any> {
        return this.afs.doc<KbArticleDef>(`articles/${article.id}`).delete().then(() => {
            // remove the article from the collection
            this.articles = reject(this.articles, {id: article.id});

            // Since there is no add() / delete() on the index, rebuild it
            this.initSearch();

            this.onIndexChange.next(true);

            this.snackBar.open('Article Deleted', 'OK', {
                verticalPosition: 'top',
                duration: 2000
            });
        }).catch((error) => {
            console.error('Error deleting article: ', error);
        });
    }

    public selectArticle(article: KbArticleDef) {
        this._selectedArticle = article;
        this.onArticleChanged.next(this._selectedArticle);
    }

    public getFacets(): Facet[] {
        forEach(this.facets, (facet: Facet) => {
            const options = compact(flatten(_map(this.articles, facet.docKey)));
            const occurence = countBy(options);
            facet.options = orderBy(uniq(options).map((option: string) => {
                return {label: capitalize(option), value: option, checked: false, disabled: false, count: occurence[option]};
            }), ['count', 'value'], ['desc', 'asc']);
        });

        return this.facets;
    }

    public async importLegacyArticles(selectedArticles: LegacyArticle[] = []) {

        const mapArticles: KbArticleDef[] = [];
        forEach(selectedArticles, (article: LegacyArticle) => {
            if (article.select) {
                const kbArticle: KbArticleDef = this.createArticleFromNode(article);
                mapArticles.push(kbArticle);
            }
        });

        const mapCount = mapArticles.length - 1;
        const promises = mapArticles.map((article, i) => this.saveArticle(article, mapCount === i));

        try {
            await Promise.all(promises);
            await this.saveMaps();
            this.articles = [];
            this.initSearch();
            return this.project.update({isKbImported: true});
        } catch (err) {
            this.project.isKbImported = false;
            this.snackBar.open(`Unable to imported and index articles from ${this.project.name}`, 'OK', {
                verticalPosition: 'top',
                duration: 5000
            });
        }
    }

    public retrieveLegacyArticles(): LegacyArticle[] {
        const mapArticles: LegacyArticle[] = [];
        if (!this.project.isKbImported) {
            forEach(this.project.boards, (board) => {
                forEach(board.nodes, (
                    node: Node) => {
                    forEach(KbArticleCategory, (category) => {
                        const type = KbCategoryHash[category];
                        const hasAssignedArticle = get(node[type], 'articleIds', []).length > 0;
                        if (!hasAssignedArticle) {
                            const legacyArticle = this.createLegacyArticleDef(board, node, category);
                            if (legacyArticle) {
                                mapArticles.push(legacyArticle);
                            }
                        }
                    });
                });
            });
        }
        return mapArticles;
    }

    // public snippetize(article: KbArticleDef): void {
    //     this.snippetList.ingestArticle(article);
    // }

    public uploadFile = (item: NzUploadXHRArgs) => {
        const file = item.file;
        const filePath = `kb/${file.uid}`;
        const fileRef = this.storage.ref(filePath);
        const task = this.storage.upload(filePath, file);

        return task.snapshotChanges().pipe(
            finalize(() => {
                fileRef.getDownloadURL().subscribe(result => {
                    item.onSuccess(result, item.file, result);
                });
            })
        )
            .subscribe(
                (result) => {
                    const event = {percent: 0};
                    event.percent = (result.bytesTransferred / result.totalBytes) * 100;
                    item.onProgress(event, item.file);
                },
                err => {
                    item.onError(err, item.file);
                }
            );
    }

    public createArticle(): KbArticleDef {
        this._selectedArticle = {
            id: this.afs.createId(),
            author: this.authService.activeUser.fullName,
            slug: '',
            title: `New Article`,
            content: '',
            snippets: [],
            lastModified: new Date().toUTCString(),
            companies: isEmpty(this.authService.activeUser.activeProject.company) ? [] : [this.authService.activeUser.activeProject.company],
            tags: [],
            category: KbArticleCategory.Practice,
            media: [],
            status: KbArticleStatus.Draft,
            isPublic: false,
        };

        return this.selectedArticle;

    }

    public get companyFilter(): string {
        const company = isEmpty(this.authService.activeUser.activeProject.company) ? '' : this.authService.activeUser.activeProject.company;
        return ` +companies:"${company}"`;
    }

    public isArticleAssignedToNode(nodeName: string, type: KbArticleCategory): boolean {
        if (!isEmpty(nodeName)) {
            const node = this.map.getNodeByName(nodeName);
            const category = KbCategoryHash[type];
            const articles = get(node, [category, 'articleIds'], []);
            return !isEmpty(articles);
        } else {
            return false;
        }
    }

    // private loadArticles(): Promise<KbArticleDef[]> {
    //     return new Promise(resolve => {
    //         this.afs.collection<KbArticleDef>('articles')
    //             .get()
    //             .subscribe((articlesSnapshot) => {
    //                 this.articles = articlesSnapshot.docs.map((doc) => <KbArticleDef>doc.data());
    //                 this.articles.forEach((article) => this.snippetize(article));
    //                 return resolve(this.articles);
    //             });
    //     });
    // }


    private createArticleFromNode(legacyArticle: LegacyArticle): KbArticleDef {

        const type = KbCategoryHash[legacyArticle.type];
        const attachments = get(legacyArticle.node, [type, 'attachments'], []);
        // const articleSnippets = this.snippets.ingestArticle(legacyArticle.text, legacyArticle.type,
        const article: KbArticleDef = {
            id: legacyArticle.id,
            author: this.authService.activeUser.fullName,
            slug: '',
            title: `${legacyArticle.nodeName}`,
            content: legacyArticle.text,
            snippets: [],
            lastModified: new Date().toUTCString(),
            companies: [...legacyArticle.companies],
            tags: [legacyArticle.nodeName, this.map.name],
            category: legacyArticle.type,
            media: attachments.map((attachment) => {
                return {
                    id: attachment.id,
                    version: 1,
                    fileName: attachment.name,
                    url: ''
                };
            }),
            status: KbArticleStatus.Draft,
            isPublic: false,
        };
        const articleIds = get(legacyArticle.node[type], 'articleIds', null);
        if (!articleIds) {
            legacyArticle.node[type]['articleIds'] = [];
        }
        legacyArticle.node[type].articleIds[0] = legacyArticle.id;
        console.log('Verify: ', legacyArticle.board.getNodeById(legacyArticle.node.id)[type].articleIds, legacyArticle.id);
        return article;
    }

    private createLegacyArticleDef(board: Board, node: Node, type: KbArticleCategory): LegacyArticle {
        const category = KbCategoryHash[type];
        const articleId = this.afs.createId();
        const companies = isEmpty(this.authService.activeUser.activeProject.company) ? [] : [this.authService.activeUser.activeProject.company];
        const hasNoContent = isEmpty(get(node, [category, 'text'], ''));
        if (hasNoContent) {
            return null;
        } else {
            return {
                id: articleId,
                type: type,
                text: node[category].text,
                companies: companies,
                nodeName: node.name,
                select: true,
                nodeId: node.id,
                board: board,
                boardName: board.name,
                node: node
            };
        }

    }

    private addNewCompanyToList(article: KbArticleDef) {
        this._companyList = uniq([...this._companyList, ...article.companies]);
    }

    public listProcessedProjects() {
        this.afs.collection('projects', ref => ref.where('isKbImported', '==', true))
            .get()
            .subscribe((snapshot) => {
                const projects = snapshot.docs.map((doc) => doc.data());
                console.log('Projects with Kb imported', projects);
            });
    }

    saveMaps(dirtyBoard: Board = null, updateProject = true): Promise<boolean> {
        const promises = [];

        if (dirtyBoard) {
            promises.push(this.project.saveBoard(dirtyBoard));
        } else {
            forEach(this.project.boards, (board) => promises.push(this.project.saveBoard(board)));
        }

        return Promise.all(promises)
            .then(() => {
                const msg = updateProject
                    ? `Articles from ${this.project.name} successfully imported and indexed.  Please review and set status to 'Published'`
                    : `Article successfully assigned to driver`;

                if (updateProject) {
                    this.project.update({isKbImported: true});
                }
                this.snackBar.open(msg, 'OK', {
                    verticalPosition: 'top',
                    duration: 5000
                });
                return Promise.resolve(true);
            })
            .catch(err => {
                this.project.isKbImported = false;
                this.snackBar.open(`Unable to imported and index articles from ${this.project.name}`, 'OK', {
                    verticalPosition: 'top',
                    duration: 5000
                });
                return Promise.resolve(false);
            });

    }


    public resetMaps() {
        const promises = [];

        forEach(this.project.boards, (board) => {
            forEach(board.nodes, (node) => {
                if (node.bestPractices) {
                    node.bestPractices.articleIds = [];
                }
                if (node.recommendations) {
                    node.recommendations.articleIds = [];
                }
            });
            console.log('Reseting maps for ', board);
            promises.push(this.project.saveBoard(board));
        });

        Promise.all(promises)
            .then(() => {
                this.project.update({isKbImported: false});
                this.snackBar.open(`${this.project.name} article references successfully removed'`, 'OK', {
                    verticalPosition: 'top',
                    duration: 5000
                });
            })
            .catch(err => {
                this.project.isKbImported = false;
                this.snackBar.open(`Unable to reset articles from ${this.project.name}`, 'OK', {
                    verticalPosition: 'top',
                    duration: 5000
                });
            });
    }


    private reactToProjectChange() {
        this.store
            .select(AppState.activeProjectId)
            .subscribe((event) => {
                this.project = this.authService.activeUser.activeProject;
                this.map = this.authService.activeUser.activeProject.activeBoard;
                if (!this.project.isKbImported) {
                    const newArticles = this.retrieveLegacyArticles();
                    this.onNewLegacyArticles.next(newArticles);
                }
            });
    }

}
