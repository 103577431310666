import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSelectModule} from '@angular/material/select';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';

import {FuseSearchBarModule, FuseShortcutsModule} from '@fuse/components';
import {FuseSharedModule} from '@fuse/shared.module';

import {ToolbarComponent} from 'app/layout/components/toolbar/toolbar.component';
import {FileUploadModule, PipesModule} from '@cultursys/common';
import {DynamicFormModule} from '@cultursys/dynamic-form';
import {ProjectFormComponent} from './project-form/project-form.component';
import {UserProfileComponent} from './user-profile/user-profile.component';
import {NavMenuComponent} from './nav-menu/nav-menu.component';
import {FileManagerModule} from '@cultursys/file-manager';

import {ProjectPickerModule} from 'app/layout/components/project-picker/project-picker.module';
import {NzMenuModule} from 'ng-zorro-antd/menu';

@NgModule({
    declarations: [
        ToolbarComponent,
        ProjectFormComponent,
        UserProfileComponent,
        NavMenuComponent
    ],
    imports: [
        RouterModule,
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatProgressBarModule,
        MatToolbarModule,
        MatFormFieldModule,
        MatAutocompleteModule,
        MatDialogModule,
        MatSelectModule,
        MatDividerModule,
        MatTabsModule,
        MatTooltipModule,

        NzMenuModule,

        FuseSharedModule,
        FuseSearchBarModule,
        FuseShortcutsModule,
        DynamicFormModule,

        FileUploadModule,
        FileManagerModule,
        PipesModule,
        ProjectPickerModule
    ],
    exports: [
        ToolbarComponent
    ],
})
export class ToolbarModule {
}
