<div fxLayout="row nowrap" fxLayoutAlign="space-between center" fxFlex="1 0 auto">

    <!--VIEW NAVIGATOR-->
    <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="ml-24 w-240 h-120">
        <button mat-button
                class="h-32 mb-4 nav-link-button"
                [disabled]="!view.enabled"
                *ngFor="let view of appViews; let first = first;"
                [routerLink]="view.path"
                [ngClass]="{'mat-elevation-z6 mat-accent-400-bg active': isActiveView(view)}">
            {{view.name}}
        </button>
    </div>
    <!-- / VIEW NAVIGATOR-->

    <!-- CONTEXT NAV MENU -->

    <router-outlet name="menu" fxLayout="row nowrap" fxLayoutAlign="center center"></router-outlet>
    <!-- / CONTEXT NAV MENU -->

    <!-- PERIOD NAVIGATOR -->

    <div class="mx-20 h-96 w-100 mt-8 text-shadow" fxLayout="column" fxLayoutAlign="space-between center">
        <div class="font-size-34 line-height-34">{{currentPeriodTitle$ | async}}</div>
        <div class="h3  font-weight-500">2018</div>
        <div fxLayout="row nowrap" fxLayoutAlign="space-between center">
            <button mat-icon-button (click)="previousPeriod()" [disabled]="currentPeriod == 0">
                <mat-icon aria-label="Previous Period">navigate_before</mat-icon>
            </button>
            <button mat-icon-button (click)="nextPeriod()"
                    [disabled]="currentPeriod === map?.numHistoricPeriods - 1">
                <mat-icon aria-label="Next Period">navigate_next</mat-icon>
            </button>
        </div>
    </div>

    <!-- /PERIOD NAVIGATOR -->
</div>
