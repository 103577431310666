<mat-form-field class="mat-search_field" [@slideInOut]="searchVisible">
    <input #input
           matInput
           [matAutocomplete]="auto"
           type="text"
           name="auto-expand-input"
           [placeholder]="placeholder"
           [(ngModel)]="text"
           (ngModelChange)="onChange.emit(text)"
           (blur)="onInputBlur(input.value)"
           (keyup.enter)="onEntering(input.value)"
           (focus)="onFocusChange(input.value)" />

    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="onAutoCompleteSelected($event)">
        <mat-option *ngFor="let item of autoComplete$ | async" [value]="item.id">
            <ng-container *ngTemplateOutlet="autoCompleteItemTmpl; context: {$implicit: item}">
            </ng-container>
        </mat-option>
    </mat-autocomplete>

</mat-form-field>
<span class="mat-search_icons" [class.mat-search_icons--active]="searchVisible">
  <mat-icon class="mat-search_icon-close" (click)="close()" matRipple>close</mat-icon>
  <mat-icon class="mat-search_icon-search" (click)="open()" matRipple>{{icon}}</mat-icon>
</span>
