<div class="pr-4 pl-20 overflow-hidden" fxFill fxLayout="column" fxLayoutAlign="space-between stretch" *ngIf="project">
    <div class=" pr-20" fxLayout="column" fxLayoutAlign="start stretch" fusePerfectScrollbar>
        <h2>Project Settings</h2>
        <h3>Client Logo</h3>
        <cs-file-upload class="logo-container"
                        path="project_logos"
                        matTooltip="Drop new image here or click me to select one"
                        [fileName]="project.id"
                        [isCircle]="false"
                        (onUploaded)="onLogoUploaded($event)"
                        (onImageCropped)="onLogoCropped($event)">
            <img *ngIf="logoBlob" [src]="logoBlob"
                 class="avatar contact-avatar m-0 natural"
                 [alt]="project.name"/>
            <img *ngIf="!logoBlob" [src]="project.logoUrl" class="avatar contact-avatar natural"/>
        </cs-file-upload>

        <cs-dynamic-form [formMetadata]="formMetadata"
                         (onValueChange)="onProjectPropertyChange($event)">
        </cs-dynamic-form>

        <div class="mb-12">
            <mat-form-field>
                <mat-select [(ngModel)]="selectedCurrency"
                            (selectionChange)="onSelectCurrency($event)"
                            placeholder="Select currency">
                    <mat-option>
                        <ngx-mat-select-search [(ngModel)]="currencyFilter"
                                               (ngModelChange)="filterCurrency()"
                                               noEntriesFoundLabel="No currency found"
                                               placeholderLabel="Search">
                        </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let currency of filteredCurrencies$ | async" [value]="currency">
                        {{currency.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>


        <mat-form-field fxFlex>
            <mat-chip-list #teamChipList>
                <mat-chip
                    *ngFor="let designation of designations"
                    [selectable]="true"
                    [removable]="true"
                    (removed)="removeDesignation(designation)">
                    {{designation}}
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                    placeholder="Designations..."
                    #roleInput
                    [matChipInputFor]="teamChipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    [matChipInputAddOnBlur]="true"
                    (matChipInputTokenEnd)="addDesignation($event)"
                />
            </mat-chip-list>
        </mat-form-field>

        <div fxLayout="row" fxLayoutAlign="space-between center" class="h-24 mt-16">
            <span>Assessment Report</span>
            <file-search fxFlexAlign="end"
                         (searchResultSelected)="onReportFileSelected($event, 'cprData')"
                         [fileTypes]="['pdf']">
            </file-search>
        </div>
        <div class="secondary-text mt-4 mb-28 v-center-content h-20">
            <div *ngIf="cprReportFile">
                <span class=" mr-4 mdi-18px mdi mdi-file-pdf" style="color: darkred"></span>
                <a [href]="cprReportFile.url" target="_blank">
                    {{cprReportFile.name}}
                </a>
                <button mat-icon-button (click)="deleteReport('cprData')" class="s-20 ml-8">
                    <mat-icon [inline]="true">delete</mat-icon>
                </button>
            </div>
            <span *ngIf="!cprReportFile">No report selected yet</span>
        </div>

        <!--<div fxLayout="row" fxLayoutAlign="space-between center" class="h-24 mt-16">-->
        <!--<span>Assurance Report</span>-->
        <!--<file-search fxFlexAlign="end"-->
        <!--(searchResultSelected)="onReportFileSelected($event, 'assuranceData')"-->
        <!--[fileTypes]="['pdf']">-->
        <!--</file-search>-->
        <!--</div>-->
        <!--<div class="secondary-text mt-4 mb-28 v-center-content h-20">-->
        <!--<div *ngIf="assuranceReportFile">-->
        <!--<span class=" mr-4 mdi-18px mdi mdi-file-pdf" style="color: darkred"></span>-->
        <!--<a [href]="assuranceReportFile.url" target="_blank">-->
        <!--{{assuranceReportFile.name}}-->
        <!--</a>-->
        <!--<button mat-icon-button (click)="deleteReport('assuranceData')" class="s-20 ml-8">-->
        <!--<mat-icon [inline]="true">delete</mat-icon>-->
        <!--</button>-->
        <!--</div>-->
        <!--<span *ngIf="!assuranceReportFile">No report selected yet</span>-->
        <!--</div>-->

        <mat-slide-toggle *ngIf="user?.canEditMap"
                          class="my-16"
                          [checked]="(uiPreferences$ | async).showMapEditor"
                          (change)="toggleMapEditor()">
            Show Map Editor (Alt-e)
        </mat-slide-toggle>
        <mat-expansion-panel class="my-20">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Edit Heat Map
                </mat-panel-title>
                <mat-panel-description fxLayout="row nowrap" fxLayoutAlign="start center">
                    <span *ngFor="let color of project.palette; let i = index;" class="h-16 w-16"
                          style.background="{{color}}"></span>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <cs-palette-generator [palette]="project.palette"
                                  (paletteChange)="onPaletteChange($event)">
            </cs-palette-generator>
        </mat-expansion-panel>
    </div>
    <div class="py-4 mt-8 mr-8" fxFlex="0 0 98px" *ngIf="user?.isAdmin">
        <div class="divider-with-text">
            <span>Project Actions</span>
        </div>
        <div fxLayout="row nowrap" fxLayoutAlign="space-between center" class="button-group-divider">
            <button mat-button color="accent" (click)="deleteProject()">
                Delete
            </button>
            <span class="vertical-separator"></span>
            <button mat-button color="accent" (click)="cloneProject()">Clone</button>
            <span class="vertical-separator"></span>
            <button mat-button color="accent" (click)="downloadProject()">Download</button>
            <span class="vertical-separator"></span>
            <button mat-button color="accent" (click)="projectFileSelector.click()">Upload</button>
        </div>
        <a #downloadElem hidden></a>
        <input type="file" hidden (change)="uploadProject($event.target.files[0], projectFileSelector)"
               #projectFileSelector>
    </div>
</div>
