import {get} from 'lodash-es';

const SIMILARITY_THRESHOLD = 0.85;

export class StringUtils {
    public static generateID(): string {
        function S4(): string {
            return Math.floor((1 + Math.random()) * 0x10000000)
                .toString(16)
                .substring(1);
        }

        return S4() + S4() + S4();
    }

    public static scrubHtml(text: string): string {
        return text.replace(/(<.+?>)/g, '').replace(/./g, '');
    }


    public static compareTwoStrings(first, second): number {
        first = first.replace(/\s+/g, '')
        second = second.replace(/\s+/g, '')

        if (first === second) return 1; // identical or empty
        if (first.length < 2 || second.length < 2) return 0; // if either is a 0-letter or 1-letter string

        let firstBigrams = new Map();
        for (let i = 0; i < first.length - 1; i++) {
            const bigram = first.substring(i, i + 2);
            const count = firstBigrams.has(bigram)
                ? firstBigrams.get(bigram) + 1
                : 1;

            firstBigrams.set(bigram, count);
        }

        let intersectionSize = 0;
        for (let i = 0; i < second.length - 1; i++) {
            const bigram = second.substring(i, i + 2);
            const count = firstBigrams.has(bigram)
                ? firstBigrams.get(bigram)
                : 0;

            if (count > 0) {
                firstBigrams.set(bigram, count - 1);
                intersectionSize++;
            }
        }

        return (2.0 * intersectionSize) / (first.length + second.length - 2);
    }

    public static findBestMatch(mainString, targetStrings):{ ratings: number[], bestMatch: {target: string, rating: number}, bestMatchIndex: number }  {
        if (!StringUtils.areArgsValid(mainString, targetStrings)) throw new Error('Bad arguments: First argument should be a string, second should be an array of strings');

        const ratings = [];
        let bestMatchIndex = 0;

        for (let i = 0; i < targetStrings.length; i++) {
            const currentTargetString = targetStrings[i];
            const currentRating = StringUtils.compareTwoStrings(mainString, currentTargetString)
            ratings.push({target: currentTargetString, rating: currentRating})
            if (currentRating > ratings[bestMatchIndex].rating) {
                bestMatchIndex = i
            }
        }


        const bestMatch = ratings[bestMatchIndex]

        return { ratings: ratings, bestMatch: bestMatch, bestMatchIndex: bestMatchIndex };
    }


    public static isSimilar = (first: string, second: string, cutOff: number = SIMILARITY_THRESHOLD): boolean => StringUtils.compareTwoStrings(first, second) >= cutOff;

    public static  arrayHasSimilarItem = (arr: any[], itemToTest: string, property: string = 'text'): boolean => {
        return arr.some(item =>  StringUtils.isSimilar(itemToTest, get(item, property, ''), SIMILARITY_THRESHOLD));
    }

    private static  areArgsValid(mainString, targetStrings) {
        if (typeof mainString !== 'string') return false;
        if (!Array.isArray(targetStrings)) return false;
        if (!targetStrings.length) return false;
        return !targetStrings.find(function (s) {
            return typeof s !== 'string'
        });

    }
}
