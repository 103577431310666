import {EMPTY_SNIPPET, Facet, ISnippet, ISnippetList} from '../snippet.types';
import {
    camelCase,
    capitalize,
    cloneDeep,
    compact,
    countBy,
    filter,
    find, findIndex,
    flatten,
    forEach,
    get,
    groupBy,
    isEmpty,
    isEqual,
    keys,
    last,
    map as _map,
    omit,
    orderBy,
    reject, remove,
    some,
    startCase,
    trim,
    trimEnd,
    uniq,
    uniqWith
} from 'lodash-es';
import Fuse from 'fuse.js';
import {SortDirection} from '@angular/material/sort';
import {BehaviorSubject, Observable} from 'rxjs';
import {KbArticlesService} from '../../../../core/articles.service';
import {Injectable} from '@angular/core';
import * as lunr from 'lunr';
import {AuthService, StringUtils} from '@cultursys/core';

export const SIMILARITY_THRESHOLD = 0.85;


export const titleCase = (sentence: string): string => startCase(camelCase(sentence));


// Updater/sanitizer run before a snippet is addded to the list.
export const snippetSanitizer = (snippet: ISnippet): ISnippet => {
    // Clean-up text
    snippet.text = textSanitizer(snippet.text);

    // Clean-up sources/attachments
    const sources = snippet?.sources || [];
    const isValid = sources.reduce((isOk, source) => { return isOk = source?.url && source?.filename && isOk }, true);

    snippet.sources = isValid ? snippet.sources : [];

    return snippet;
};

export const textSanitizer = (txt: string): string => {
    let res: string;

    // 1. Remove HTML & Trim blanks
    let plainText = trim(removeHTML(txt));
    res = trim(txt);

    // 2. replace incongruent punctuation, like ,. ., . .
    res = res.replace(/&nbsp;|,\.|\.,|\. \./g, '');

    // 3. Check to see if the plain text is missing a period
    const isMissingPeriod = (last(plainText) !== '.') && (plainText.slice(-3) !== '...') && (plainText.slice(-1) !== ':');
    const isParagraph = txt.slice(-4) === '<\/p>';

    if (!isParagraph) {
        const closingTag = isMissingPeriod ? '.<\/p>' : '<\/p>';
        res = `<p>${capitalize(res)}${closingTag}`;
    } else if (isMissingPeriod) {
        res = `${res.slice(0, -4)}.<\/p>`;
    }
    return res;
};


const removeHTML = (txt: string): string => txt.replace(/(<.+?>)/g, '');

const fuseOptions = {
    shouldSort: true,
    threshold: 0.1,
    isCaseSensitive: false,
    minMatchCharLength: 2,
    useExtendedSearch: true,
    ignoreLocation: true,
    findAllMatches: true,
    maxPatternLength: 32,
};

const defaultFacetOptions = {options: [], selected: [], showCount: 5, page: 1};


@Injectable({
    providedIn: 'root'
})
export class SnippetList {
    private _snippetList: ISnippetList = [];
    private _facetedList: ISnippetList = [];
    private _sortKey = 'text';
    private _sortOrder: SortDirection = 'asc';
    private _query = '';

    public _filteredSnippetList$: BehaviorSubject<ISnippetList> = new BehaviorSubject<ISnippetList>([]);
    public updateSnippet$: BehaviorSubject<ISnippet> = new BehaviorSubject<ISnippet>(null);
    public totalCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    public countFiltered$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    public facets$: BehaviorSubject<Facet[]> = new BehaviorSubject<Facet[]>([]);

    private _newSnippet$: BehaviorSubject<ISnippet> = new BehaviorSubject<ISnippet>(null);
    private _staleSnippet$: BehaviorSubject<ISnippet> = new BehaviorSubject<ISnippet>(null);
    private _searchText$: BehaviorSubject<string> = new BehaviorSubject<string>('');


    facets: Facet[] = [
        {title: 'Data Category', idxKey: 'category', docKey: 'category', showFilter: false, ...defaultFacetOptions},
        {title: 'Companies', idxKey: 'company', docKey: 'companies', showFilter: true, ...defaultFacetOptions},
        {title: 'Drivers', idxKey: 'drivers', docKey: 'drivers', showFilter: true, ...defaultFacetOptions},
        {title: 'Topics', idxKey: 'tag', docKey: 'topics', showFilter: true, ...defaultFacetOptions, showCount: 10}
    ];

    _selectedFacets: Record<string, string>[] = [];//Record<'category' | 'companies' | 'topics', string>[] = []

    private lunrIndex: any;  //


    constructor(private auth: AuthService,
                private articles: KbArticlesService) {
    }

    get all(): ISnippetList {
        return orderBy([...this._snippetList], ['lastModified'], ['desc']);
    }

    set all(snippets: ISnippetList) {
        snippets.forEach(s => this.add(s));
        this._snippetList = orderBy([...snippets], ['lastModified'], ['desc']);
        this.initializeFacets();
        this.updateFacets();
        this.broadcastList();
    }

    get allFiltered(): ISnippetList {
        // return the faceted list filtered by the latest query string
        return this.filter();
    }

    get filteredList$(): Observable<ISnippetList> {
        return this._filteredSnippetList$.asObservable();
    }

    get newSnippet$(): Observable<ISnippet> {
        return this._newSnippet$.asObservable();
    }

    get staleSnippet$(): Observable<ISnippet> {
        return this._staleSnippet$.asObservable();
    }

    set sortKey(key: string) {
        this._sortKey = key;
    }

    set sortOrder(order: SortDirection) {
        this._sortOrder = order;
    }

    get activeFacets(): Record<string, string>[] {
        return this._selectedFacets;
    }

    get searchText$(): Observable<string> {
        return this._searchText$.asObservable();
    }

    get count(): number {
        return this._snippetList.length;
    }

    get countFiltered(): number {
        return this._filteredSnippetList$.value.length;
    }

    // get countFiltered$(): Observable<number> {
    //     return this._filteredSnippetList$.pipe(
    //         map(() => this.countFiltered())
    //     );
    // }


    set query(searchTxt: string) {
        this._query = searchTxt;
        this._searchText$.next(searchTxt);

    }

    dedupList(): ISnippet[] {
        const dups = [];
        const dupSnippets = [];

        const list = uniqWith(this._snippetList, (arrVal, otherVal) => {
            const sim = StringUtils.isSimilar(arrVal.text, otherVal.text, SIMILARITY_THRESHOLD);
            // const sim = arrVal.text === otherVal.text

            if (sim) {
                dups.push([arrVal.text, otherVal.text, StringUtils.compareTwoStrings(arrVal.text, otherVal.text)]);
                dupSnippets.push(otherVal);
            }
            return sim;
        });
        console.table(dups);

        dupSnippets.forEach(s => this.delete(s));

        return list;
    }

    add(snippet: ISnippet): ISnippet {
        return this.update(snippet);
    }

    update(snippet: ISnippet, dedup = false): ISnippet {
        let newSnippet = snippetSanitizer(snippet);

        // Remove the snippet from the list
        const existingSnippet = remove(this._snippetList, {uid: newSnippet.uid});

        if (dedup) {
            // Let's make sure we're not upserting a duplicate...
            const matchingSnippet = this.getBestMatch(newSnippet);

            // If so merge it the new with existing snippet
            if (matchingSnippet) {
                newSnippet = this.merge(newSnippet, matchingSnippet);
            }
        }
        this._snippetList.push(newSnippet);

        this.updateFacets();
        this.broadcastList();
        return newSnippet;
    }

    delete(snippet: ISnippet): ISnippetList {
        this._snippetList = filter(this._snippetList, (s) => s.uid !== snippet.uid);
        this.updateFacets();
        this.broadcastList();
        return this._snippetList;
    }

    clear(): void {
        this._snippetList = [];
        this.clearFacetSelection();
    }

    get(id: string): ISnippet {
        return find(this._snippetList, {uid: id});
    }


    merge(source: ISnippet, target?: ISnippet): ISnippet {
        let mergedSnippet = {...EMPTY_SNIPPET, ...source};
        let similarSnippet: ISnippet = null;

         if (!target) {
            similarSnippet = this.findDuplicate(source);
        } else {
            similarSnippet = target;
        }

        if (similarSnippet && (source.category === similarSnippet.category)) {
            // For category, uid, and text, use the latest one since they are all similar
            mergedSnippet.category = similarSnippet.category;
            mergedSnippet.uid = similarSnippet.uid;
            mergedSnippet.text = source.text;

            // For array properties, merge arrays
            mergedSnippet.topics = uniq(mergedSnippet.topics.concat(similarSnippet.topics));
            mergedSnippet.companies = uniq(mergedSnippet.companies.concat(similarSnippet.companies));
            mergedSnippet.sources = uniqWith(mergedSnippet.sources.concat(similarSnippet.sources), isEqual);
            mergedSnippet.drivers = uniq(similarSnippet.drivers.concat(similarSnippet.drivers));
            return mergedSnippet;
        } else {
            return source;
        }
    }

    public getBestMatch(snippet): ISnippet {
        if (this.count === 0 ) {
            return null;
        }

        // In case the snippet is in the list, remove it before attempting to find a match
        const list = filter(this._snippetList, s => s.uid !== snippet.uid);
        const textFragments = list.map( s => s.text);

        const {bestMatchIndex, bestMatch} = StringUtils.findBestMatch(snippet.text, textFragments);

        if (bestMatchIndex && bestMatch.rating > SIMILARITY_THRESHOLD) {
            // console.log('Found a best match for: ', snippet.text, bestMatch?.target, bestMatch?.rating);
            return this.get(list[bestMatchIndex]['uid']);
        } else {
            // console.log('!!! No best match for: ', snippet.text, bestMatch?.target, bestMatch?.rating);
            return null
        }
    }


    sortBy(list: ISnippetList = this._snippetList, prop: string = this._sortKey, order: SortDirection = this._sortOrder): ISnippetList {
        return orderBy(list, [prop], [order === '' ? 'asc' : order]);
    }

    //  SEARCH RELATED METHODS
    //  ---------------------
    initSearch(): ISnippetList {
        const all = this._facetedList;

        this.lunrIndex = lunr(function () {
            const me = this;

            this.ref('uid');
            this.field('text', {boost: 10});
            this.field('topics', {boost: 5});
            this.field('companies', {boost: 10});
            this.field('category', {boost: 15});
            this.field('sources', {boost: 5});
            this.field('drivers', {boost: 5});

            // the stemmer which creates tokens based on word stems creates pathologies.  Remove it at the cost of speed.
            this.pipeline.remove(lunr.stemmer);
            this.searchPipeline.remove(lunr.stemmer);

            // since lunr doesn't search in arrays, stringify 'em
            all.forEach((article) => {
                const kbDoc: ISnippet = cloneDeep(article);
                const doc = omit(kbDoc, ['topics', 'companies', 'sources', 'drivers']);
                doc['topics'] = get(kbDoc, 'topics', []).toString().replace(/,/g, ' ');
                doc['companies'] = get(kbDoc, 'companies', ['unknown']).toString().replace(/,/g, ' ');
                doc['sources'] = get(kbDoc, 'sources', []).map(s => s.filename).toString().replace(/,/g, ' ');
                doc['drivers'] = get(kbDoc, 'drivers', []).toString().replace(/,/g, ' ');
                // if (!isEmpty(article.sources)) {
                //     console.log('New doc added to index: ', doc);
                // }
                me.add(doc as ISnippet);
            }, this);
        });

        return all;
    }

    search(query: string): ISnippetList {
        if (isEmpty(this.lunrIndex)) {
            this.initSearch();
        }

        let hits: ISnippetList;
        let searchFor: string;
        let res: any;

        // remove any incomplete query symbols at the end of the query
        searchFor = trimEnd(query, ' _-+!~^');

        if (isEmpty(query)) {
            hits = [...this._facetedList];
        } else {
            let fuzzy = searchFor.replace(/\b\w+\b/g, `$&*`);
            fuzzy = fuzzy.replace('*:', ':');
            fuzzy = fuzzy.replace('**', '*');
            res = this.lunrIndex.search(fuzzy);
            hits = compact(_map(res, (hit): ISnippet => find(this._facetedList, {uid: hit.ref})));
        }
        return hits;
    }


    filter(queryString: string = this._query): ISnippetList {
        if (!isEmpty(queryString)) {
            this.query = queryString.toLowerCase();
            const filteredList = this.search(queryString); //this._facetedList.filter(snippet => values(snippet).toString().toLowerCase().includes(this._query));
            const sortedList = this.sortBy(filteredList);
            this._filteredSnippetList$.next(sortedList);
            return sortedList;

        } else {
            this.query = '';
            const sortedFacetList = this.sortBy(this._facetedList);
            this._filteredSnippetList$.next(sortedFacetList);
            return sortedFacetList;
        }

    }

    getUniqueValuesByKey(property: string): string[] {
        return uniq(flatten(_map(this._snippetList, property)));
    }


    //  FACET RELATED METHODS
    //  ---------------------
    initializeFacets(): void {
        this.updateFacets();
    }

    clearFacetSelection(): void {
        this._facetedList = [];
        this._selectedFacets = [];
        this.facets.forEach(f => f.options.forEach(o => o.checked = false));
        this.updateFacets();
        this.filter();
    }


    updateFacets(): Facet[] {
        // update the list filtered by facets
        this.applyFacets();

        forEach(this.facets, (facet: Facet) => {
            // Get all possible options
            const options = (compact(flatten(_map(this._snippetList, facet.docKey)))).map(o => titleCase(o));

            // Count the occurrences of options after filtering
            const occurrences = countBy(compact(flatten(_map(this._facetedList, facet.docKey))));

            facet.options = orderBy(uniq(options).map((option: string) => {
                const isChecked = some(this._selectedFacets, {[facet.docKey]: option});
                const count = get(occurrences, option, 0);
                return {label: titleCase(option), value: option, checked: isChecked, disabled: false, count: count, group: facet.docKey};
            }), ['value', 'count'], ['asc', 'desc']);
        });

        this.broadcastList();

        return this.facets;
    }


    updateFacetedList(group: string, facetLabel: string, isChecked: boolean): ISnippetList {
        // console.log('Selected facets: {', group, ' : ', facetLabel, ' }');
        const facetGroup = find(this.facets, {docKey: group});
        const isValidOption = !!find(facetGroup?.options, (f => f.label.toLowerCase() === facetLabel.toLowerCase()));

        if (isValidOption) {
            if (isChecked) {
                this._selectedFacets.push({[group]: facetLabel});
            } else {
                this._selectedFacets = reject(this._selectedFacets, {[group]: facetLabel});
            }

            // Failsafe: delete all dups
            this._selectedFacets = uniqWith(this._selectedFacets, isEqual);

            this.updateFacets();
        } else {
            console.warn('Trying to check a facet option which doesn\'t exist', group, facetLabel);
        }
        return this.filter();
    }


    removeDuplicates(snippet: ISnippet): void {
        if (isEmpty(snippet) || isEmpty(this._snippetList)) {
            return;
        }

        const {bestMatchIndex, ratings} = StringUtils.findBestMatch(snippet.text, this._snippetList.map(s => s.text));
        const dups = this._snippetList.filter((snippet, index) => ratings[index] >= SIMILARITY_THRESHOLD);
        this.all = this._snippetList.filter((snippet, index) => ratings[index] < SIMILARITY_THRESHOLD);
    }


    findDuplicate(snippet: ISnippet | string): ISnippet {
        if (isEmpty(snippet) || isEmpty(this._snippetList)) {
            return null;
        }

        const target: string = get(snippet, ['text'], '');
        const {bestMatchIndex, ratings} = StringUtils.findBestMatch(target, this._snippetList.map(s => s.text));
        return ratings[bestMatchIndex] >= SIMILARITY_THRESHOLD ? this._snippetList[bestMatchIndex] : null;
    }


    applyFacets(): ISnippetList {
        // See https://fusejs.io/api/query.html for the search query syntax
        if (this._selectedFacets.length > 0) {

            // Construct the selected key values for a given facet which should match on OR
            const groupedFacets = groupBy(this._selectedFacets, (o) => keys(o)[0]);

            const searcheableKeys = keys(groupedFacets);

            // Construct the search query such that intra-facet values match on OR and values between facets match with AND,
            // e.g., {$and: [{$or: [{type: 'bestPractice'}]}, {$or: [{category: 'security'}, {category: 'cyber'}]}]}
            const searchObj = {
                $and: _map(groupedFacets, (facetValues) => {
                    return {
                        $or: _map(facetValues, (facetValue) => {
                            const category = keys(facetValue)[0];
                            return {[category]: `^${facetValue[category]}`};
                        })
                    };
                })
            };

            const fuse = new Fuse(this._snippetList, {...fuseOptions, keys: searcheableKeys});
            this._facetedList = fuse.search(searchObj).map((res) => res.item);
        } else {
            this._facetedList = this._snippetList;
        }

        this.initSearch();
        return this._facetedList;
    }

    broadcastList() {
        this.initSearch();
        this._filteredSnippetList$.next(this.filter());
        this.totalCount$.next(this._snippetList.length);
        this.facets$.next(this.facets);
        this.countFiltered$.next(this._filteredSnippetList$.value.length);
    }
}
