<ul nz-menu [nzMode]="'horizontal'" class="cs-top-nav">
    <li nz-menu-item routerLink="/apps/project/summary" [nzSelected]="isActiveView('/apps/project/summary')">
        Dashboard
    </li>
    <li nz-menu-item routerLink="/apps/project/metrics" [nzSelected]="isActiveView('/apps/project/metrics')">
        Business Metrics
    </li>
    <li nz-menu-item routerLink="/apps/project/culture-map" [nzSelected]="isActiveView('/apps/project/culture-map')">
        Maps
    </li>
    <li nz-submenu  *fuseIfOnDom>
        <span title (click)="navigateTo('/apps/project/reports/list')">Reports</span>
        <ul>
                     <li nz-menu-item *ngFor="let menuItem of reportMenuItems; trackBy: trackMenuItem"
                        [hidden]="!!menuItem?.hidden"
                        [routerLink]="menuItem.path"
                        [nzSelected]="isActiveView(menuItem.path)">
                        {{menuItem.title}}
                    </li>
        </ul>
    </li>
    <li nz-submenu *ngIf="isAdmin" nzTitle="Admin">
        <ul>
            <li nz-menu-item *ngFor="let menu of configMenuItems; trackBy: trackMenuItem"
                [routerLink]="menu.path"
                [nzSelected]="isActiveView(menu.path)">
                {{menu.title}}
            </li>
        </ul>
    </li>

</ul>
