import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

import {filter, first} from 'rxjs/operators';

import {FuseConfigService} from '@fuse/services/config.service';
import {fuseAnimations} from '@fuse/animations';
import {AuthService} from '@cultursys/core';

@Component({
    selector: 'fuse-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    animations: fuseAnimations
})
export class FuseLoginComponent implements OnInit {
    loginForm: FormGroup;
    loginFormErrors: any;
    emailSent = false;
    loginWithPassword = true;
    message = '';

    submitted = false;

    constructor(private fuseConfig: FuseConfigService,
                private formBuilder: FormBuilder,
                private afAuth: AngularFireAuth,
                private auth: AuthService,
                private router: Router) {
        // Configure the layout
        this.fuseConfig.config = {
            layout: {
                navbar   : {
                    hidden: true
                },
                toolbar  : {
                    hidden: true
                },
                footer   : {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };

        this.loginFormErrors = {
            email: {},
            password: {}
        };
    }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required]
        });

        this.loginForm.valueChanges.subscribe(() => {
            this.onLoginFormValuesChanged();
        });
    }

    sendLoginLink() {
        const email = this.loginForm.getRawValue().email;
        const actionCodeSettings = {
            url: `${window.location.origin}/verify`,
            handleCodeInApp: true
        };
        this.afAuth
            .sendSignInLinkToEmail(email, actionCodeSettings)
            .then(() => {
                window.localStorage.setItem('emailForSignIn', email);
                this.emailSent = true;
                this.message = 'An email has been sent to you with the login link';
            })
            .catch(err => {
                this.message = 'An error occured while emailing you the login link. Try again';
            });
    }

    signInWithEmail() {
        this.submitted = true;
        this.auth
            .emailLogin(this.loginForm.value.email, this.loginForm.value.password)
            .then((user) => {
                if (user) {
                    this.afterSignIn();
                } else {
                    this.submitted = false;
                    this.loginFormErrors['password'] = {
                        validationFail: true
                    };
                }
            });
    }

    onSubmit() {
        if (this.loginWithPassword) {
            this.signInWithEmail();
        } else {
            this.sendLoginLink();
        }
    }

    /// Shared
    private afterSignIn() {
        // Do after login stuff here, such router redirects, toast messages, etc.
        this.auth
            .onUserChange$
            .pipe(
                filter(user => !!user),
                first()
            )
            .subscribe(() => {
                this.router.navigate(['/apps/project/summary']);
            });
    }

    onLoginFormValuesChanged() {
        for (const field in this.loginFormErrors) {
            if (!this.loginFormErrors.hasOwnProperty(field)) {
                continue;
            }

            // Clear previous errors
            this.loginFormErrors[field] = {};

            // Get the control
            const control = this.loginForm.get(field);

            if (control && control.dirty && !control.valid) {
                this.loginFormErrors[field] = control.errors;
            }
        }
    }

    onToggleLoginWithPassword(event: MatSlideToggleChange) {
        this.loginWithPassword = event.checked;
        if (!event.checked) {
            this.loginForm.patchValue({password: ''});
        }
    }
}
