import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {clone, pullAt} from 'lodash-es';
import {ColorHelper} from '../../../../core/color.helper';

const constructIndicesArray = (count: number) => (Array(100)).fill(0).map((v, i) => Math.floor(i * count / 100));


@Component({
    selector: 'cs-palette-generator',
    templateUrl: './palette-generator.component.html',
    styleUrls: ['./palette-generator.component.scss']
})
export class PaletteGeneratorComponent implements OnInit {

    @Input() palette: string[];
    @Output() paletteChange = new EventEmitter<string[]>();

    private defaultColor = '#ffffff';
    private indices;

    constructor() {
    }

    ngOnInit() {
        this.indices = constructIndicesArray(this.palette.length);
    }

    addItem(): void {
        this.palette.push(this.defaultColor);
        this.indices = constructIndicesArray(this.palette.length);
        this.rebuildPalette({color: this.defaultColor}, this.palette.length - 1);
    }

    rebuildPalette(event: {color: string}, index: number) {
        const colors = clone(this.palette);
        colors[index] = event.color;
        console.log('Palette ', colors);
        this.paletteChange.emit(colors);
    }

    getRange(index: number): string {
        const first = this.indices.indexOf(index) + 1;
        const last =  this.indices.lastIndexOf(index) + 1;
        return `${first} ~ ${last}`;
    }

    deleteItem(i) {
            pullAt(this.palette, i);
        this.indices = constructIndicesArray(this.palette.length);
    }

    isBackgroundDark(color){
        return !ColorHelper.isBetterContrastOnBlack(color);
    }
}
