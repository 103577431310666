<div fxLayout="column" fxLayoutAlign="start start" class="px-24">
    <div *ngFor="let color of palette; let i = index;">
        <div fxLayout="row nowrap" fxLayoutAlign="start center">
            <div class="mr-24 w-56">{{getRange(i)}}</div>
            <input [value]="color"
                   [style.background]="color"
                   [style.color]="isBackgroundDark(color) ? 'white' : 'black'"
                   [cpSaveClickOutside]="true"
                   class="color-picker-field"
                   [colorPicker]="color"
                   cpPosition="top"
                   (cpSliderDragEnd)="rebuildPalette($event, i)"/>
            <button mat-icon-button color="warn" (click)="deleteItem(i)">
                <mat-icon inline="true">close</mat-icon>
            </button>
        </div>
    </div>

    <button class="mt-16" mat-stroked-button color="accent" (click)="addItem()">
        <mat-icon>add</mat-icon>Add A Color
    </button>
</div>
