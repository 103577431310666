import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import {AngularFirestore, AngularFirestoreDocument} from '@angular/fire/compat/firestore';
import {MatDialog} from '@angular/material/dialog';

import {Store} from '@ngxs/store';

import {ToastrService} from 'ngx-toastr';

import {get} from 'lodash-es';

import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

import {FuseConfigService} from '@fuse/services/config.service';
import {FuseSidebarService} from '@fuse/components/sidebar/sidebar.service';

import {AuthService, User} from '@cultursys/core';
import {UpdateBucket} from '@cultursys/file-manager';
import {UserProfileComponent} from './user-profile/user-profile.component';
import {BoardGuards} from 'app/core/board-guards.service';
import {UpdateActiveProjectId} from 'app/store/app.actions';
import {FileManagerDialogComponent} from 'app/main/apps/file-manager/file-manager-dialog/file-manager-dialog.component';

@UntilDestroy()
@Component({
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit, OnDestroy {
    user: User = null;

    showLoadingBar: boolean;
    horizontalNav: boolean;
    noNav: boolean;

    private dialogRef: any;

    hideMenu = false;

    constructor(private router: Router,
                private store: Store,
                private fuseConfig: FuseConfigService,
                private sidebarService: FuseSidebarService,
                private auth: AuthService,
                private afs: AngularFirestore,
                private toasty: ToastrService,
                private cdr: ChangeDetectorRef,
                private boardGuard: BoardGuards,
                public dialog: MatDialog) {
        router.events
            .pipe(untilDestroyed(this))
            .subscribe(event => {
                if (event instanceof NavigationStart) {
                    this.showLoadingBar = true;
                }

                if (event instanceof NavigationEnd) {
                    this.showLoadingBar = false;
                }
            });

        this.fuseConfig
            .config
            .pipe(untilDestroyed(this))
            .subscribe((settings) => {
                this.hideMenu = settings.layout.toolbar.hideMenu;
                this.horizontalNav = settings.layout.navigation === 'top';
                this.noNav = settings.layout.navigation === 'none';
            });
    }

    ngOnInit(): void {
        this.auth
            .onUserChange$
            .pipe(untilDestroyed(this))
            .subscribe((user) => {
                this.user = user;
            });

        window.onbeforeunload = (event) => {
            const hasUnsavedData = this.auth.activeUser && get(this, 'user.activeProject.activeBoard.isDirty', false);
            const message = hasUnsavedData ? 'You have unsaved data. Are you sure you want to exit?' : undefined;
            if (typeof event === 'undefined') {
                event = window.event;
            }
            if (event) {
                event.returnValue = message;
            }

            console.log('Exit event ', event);
            return message;
        };
    }

    ngOnDestroy(): void {
        // For AOT, this method must be present, even if empty!
        // Otherwise 'ng build --prod' will optimize away any calls to ngOnDestroy,
        // even if the method is added by the @TakeUntilDestroy decorator
    }


    setActiveProject(project) {
        this.boardGuard
            .checkIfBoardDirty()
            .then(() => {
                this.store.dispatch(new UpdateActiveProjectId(project.id));
            });
    }

    toggleSidebarOpened(key) {
        this.sidebarService.getSidebar(key).toggleOpen();
    }

    showFileManager() {
        const projectId = this.auth.activeUser.activeProject.id;
        this.store.dispatch(new UpdateBucket(`project-documents/${projectId}`));

        this.dialog.open(FileManagerDialogComponent, {
            panelClass: 'file-manager-dialog'
        });
    }

    logout() {
        this.boardGuard
            .checkIfBoardDirty()
            .then(() => this.auth.signOut());
    }

    showProfilePage() {
        this.dialogRef = this.dialog.open(UserProfileComponent, {
            panelClass: 'contact-form-dialog',
            data: {
                contact: this.user,
                action: 'profile'
            }
        });

        this.dialogRef.afterClosed()
            .subscribe((response: { user: User }) => {
                if (response) {
                    const userRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${this.user.uid}`);
                    return userRef.update({
                        name: response.user.name,
                        lastName: response.user.lastName,
                        company: response.user.company
                    });
                }
            });
    }

    toggleSidebarOpen(key): void {
        this.sidebarService.getSidebar(key).toggleOpen();
    }

}
