import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import {NgxsModule} from '@ngxs/store';

import {ClipboardModule} from 'ngx-clipboard';

import {FileManagerService} from './file-manager.service';
import {FileManagerComponent} from './file-manager.component';
import {FileManagerFileListComponent} from './file-list/file-list.component';
import {FileManagerMainSidebarComponent} from './sidebars/main/main.component';
import {NoopComponent} from '../../components/noop/noop.component';
import {NoopComponentModule} from '../../components/noop/noop-component.module';
import {FileManagerState} from './store/file-manager.state';
import {ConfirmDialogModule, PipesModule} from '@cultursys/common';
import {AddFolderDialogComponent} from './add-folder-dialog/add-folder-dialog.component';
import { FileManagerDialogComponent } from './file-manager-dialog/file-manager-dialog.component';
import {CommonModule} from '@angular/common';
import {FusePipesModule} from '../../../../@fuse/pipes/pipes.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FuseDirectivesModule} from '../../../../@fuse/directives/directives';
import {ExpandableInputModule} from '../../../common/expandable-input/expandable-input.module';
import { FileAttachmentsComponent } from './file-attachments/file-attachments.component';
import { FileSearchComponent } from './file-search/file-search.component';
import {FileManagerUtils} from './file-manager-utils.service';


const routes: Routes = [
    {
        path: '**',
        component: NoopComponent,
        outlet: 'menu'
    },
    {
        path: '**',
        component: FileManagerComponent,
        children: [],
        resolve: {
            files: FileManagerService
        }
    }
];

@NgModule({
    declarations: [
        FileManagerComponent,
        FileManagerFileListComponent,
        FileManagerMainSidebarComponent,

        AddFolderDialogComponent,

        FileManagerDialogComponent,

        FileAttachmentsComponent,

        FileSearchComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,

        FlexLayoutModule,

        FuseDirectivesModule,
        FusePipesModule,

        // RouterModule.forChild(routes),

        NgxsModule.forFeature([FileManagerState]),

        MatButtonModule,
        MatCardModule,
        MatDialogModule,
        MatDividerModule,
        MatIconModule,
        MatListModule,
        MatRippleModule,
        MatSlideToggleModule,
        MatTableModule,
        MatInputModule,
        MatFormFieldModule,
        MatToolbarModule,
        MatTooltipModule,
        MatProgressBarModule,

        ClipboardModule,

        ExpandableInputModule,

        PipesModule,

        NoopComponentModule,
        ConfirmDialogModule
    ],
    providers: [
        FileManagerService,
        FileManagerUtils
    ],
    exports: [
        FileManagerComponent,
        FileAttachmentsComponent,
        FileSearchComponent
    ],
})
export class FileManagerModule {
}
