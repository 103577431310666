import {Injectable} from '@angular/core';

import {cloneDeep, forEach, groupBy, indexOf, meanBy, reverse, set, sortBy, take, takeRight} from 'lodash-es';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class HealthSurveyService {

    readonly surveyData = [
        {
            'id': 1,
            'question': 'The senior leadership team  works well together.',
            'group': 'Leadership Team',
            'data': [
                3.5,
                3
            ]
        },
        {
            'id': 2,
            'question': 'There is openness, candor and good personal relationships among the senior team.',
            'group': 'Leadership Team',
            'data': [
                3.8,
                3.2
            ]
        },
        {
            'id': 3,
            'question': 'The  leadership team has all the right skills and a good understanding of the business',
            'group': 'Leadership Team',
            'data': [
                3.6,
                3.4
            ]
        },
        {
            'id': 4,
            'question': 'The senior leaders function as one aligned team, not independent silos or "fiefdoms".',
            'group': 'Leadership Team',
            'data': [
                2.7,
                2
            ]
        },
        {
            'id': 5,
            'question': 'Collaboration and information sharing between functions and subregions is encouraged and promoted by the senior executives.',
            'group': 'Leadership Team',
            'data': [
                3.7,
                2.1
            ]
        },
        {
            'id': 6,
            'question': 'Meetings are highly productive and efficient.',
            'group': 'Meetings',
            'data': [
                2.5,
                3
            ]
        },
        {
            'id': 7,
            'question': 'Strategy review meetings keep the focus on strategy',
            'group': 'Meetings',
            'data': [
                2.5,
                3
            ]
        },
        {
            'id': 8,
            'question': 'During meeting there is open dialgoues and everyone is engaged.',
            'group': 'Meetings',
            'data': [
                2.7,
                1.9
            ]
        },
        {
            'id': 9,
            'question': 'Meetings result in timely and effective business decisions',
            'group': 'Meetings',
            'data': [
                2.5,
                1.8
            ]
        },
        {
            'id': 10,
            'question': 'After every staff meeting, we hold "down the line" communications about information and issues.',
            'group': 'Meetings',
            'data': [
                3,
                1.6
            ]
        },
        {
            'id': 11,
            'question': 'The organization structure enables the delivery of our strategic and business goals',
            'group': 'Organization Structure',
            'data': [
                3,
                2.1
            ]
        },
        {
            'id': 12,
            'question': 'The organizatio structure promotes cross functional collaboration and decision making',
            'group': 'Organization Structure',
            'data': [
                2.6,
                1.3
            ]
        },
        {
            'id': 13,
            'question': 'Problem solving interactions between business functions and subregions are highly collaborative.',
            'group': 'Organization Structure',
            'data': [
                3.4,
                2
            ]
        },
        {
            'id': 14,
            'question': 'The  business functions are aligned with the overall strategic goals and objectives of the company',
            'group': 'Organization Structure',
            'data': [
                3,
                1.1
            ]
        },
        {
            'id': 15,
            'question': 'Authority levels and responsibilities within the  organization are well defined.',
            'group': 'Organization Structure',
            'data': [
                2.5,
                2.1
            ]
        },
        {
            'id': 16,
            'question': 'The Vision of where it will be in 3-5 years is well understood.',
            'group': 'Vision and Values',
            'data': [
                3,
                1.5
            ]
        },
        {
            'id': 17,
            'question': 'The company Mission and Purpose clearly focuses on delivering business value to customers.',
            'group': 'Vision and Values',
            'data': [
                2.4,
                2
            ]
        },
        {
            'id': 18,
            'question': 'There exists a written and strong code of ethics and business values within this company.',
            'group': 'Vision and Values',
            'data': [
                4.3,
                2.9
            ]
        },
        {
            'id': 19,
            'question': 'Customer satisfaction is a highly promoted value in this company',
            'group': 'Vision and Values',
            'data': [
                3.5,
                2.7
            ]
        },
        {
            'id': 20,
            'question': 'Negative behaviour towards team members, customers or suppliers by any person is not allowed to continue.',
            'group': 'Vision and Values',
            'data': [
                3.2,
                1.8
            ]
        },
        {
            'id': 21,
            'question': 'I feel confident we have the right 3-5 year strategy to grow the company.',
            'group': 'Strategy & Execution',
            'data': [
                3,
                2.3
            ]
        },
        {
            'id': 22,
            'question': 'The company strategic plan and business objectives are clear and well understood',
            'group': 'Strategy & Execution',
            'data': [
                2.8,
                1.3
            ]
        },
        {
            'id': 23,
            'question': 'There is a great sense of urgency to move the business forward and to grow the company.',
            'group': 'Strategy & Execution',
            'data': [
                3.9,
                3.9
            ]
        },
        {
            'id': 24,
            'question': 'We have a clear, meaningful and effective strategy review process',
            'group': 'Strategy & Execution',
            'data': [
                2.5,
                2.2
            ]
        },
        {
            'id': 25,
            'question': 'Strategic activities and plans are equally as important as short term sales and revenue objectives.',
            'group': 'Strategy & Execution',
            'data': [
                2.4,
                1.2
            ]
        },
        {
            'id': 26,
            'question': 'There is a high degree of pride in our products, our company and our value to society.',
            'group': 'Corporate Culture',
            'data': [
                4.4,
                5.2
            ]
        },
        {
            'id': 27,
            'question': 'Personal ccountability and “ownership of issues” is strong in this company.',
            'group': 'Corporate Culture',
            'data': [
                3.4,
                2.7
            ]
        },
        {
            'id': 28,
            'question': 'The culture is one of “sense of urgency” to get the job done',
            'group': 'Corporate Culture',
            'data': [
                3.6,
                4
            ]
        },
        {
            'id': 29,
            'question': 'On the whole, people have a positive, upbeat and optimistic attitude towards the company',
            'group': 'Corporate Culture',
            'data': [
                3.5,
                2.7
            ]
        },
        {
            'id': 30,
            'question': 'This is a culture where we actively develop and mentor people to improve and grow their skills and capabilities',
            'group': 'Corporate Culture',
            'data': [
                2.8,
                1.5
            ]
        },
        {
            'id': 31,
            'question': 'We have excellent data, insight and understanding on the needs of our customers.',
            'group': 'Customer Focus',
            'data': [
                2.3,
                2
            ]
        },
        {
            'id': 32,
            'question': 'We have robust processes for obtaining customer feedback.',
            'group': 'Customer Focus',
            'data': [
                2.1,
                2.1
            ]
        },
        {
            'id': 33,
            'question': 'Customer satisfaction is one of the key performance metrics within this company.',
            'group': 'Customer Focus',
            'data': [
                2.4,
                2.2
            ]
        },
        {
            'id': 34,
            'question': 'Products and business processes are designed from the customer point of view.',
            'group': 'Customer Focus',
            'data': [
                2.9,
                2
            ]
        },
        {
            'id': 35,
            'question': 'We regularly gather and review the "Voice of the Customer',
            'group': 'Customer Focus',
            'data': [
                2.1,
                1.5
            ]
        },
        {
            'id': 36,
            'question': 'This company is well poised to grow and expand',
            'group': 'Future',
            'data': [
                5.1,
                4
            ]
        },
        {
            'id': 37,
            'question': 'There is a strong focus on growing the business and beating the competition',
            'group': 'Future',
            'data': [
                4.3,
                4
            ]
        },
        {
            'id': 38,
            'question': 'I feel this is a great place for me to develop my skills and grow my career.',
            'group': 'Future',
            'data': [
                4.8,
                3
            ]
        }
    ];

    readonly seriesNames = ['Senior Management', 'Middle Management'];

    readonly onChartHover$: BehaviorSubject<any> = new BehaviorSubject<any>(0);

    private surveyGroupAverageHash: any = {
        'Future': [0, 1, 2]
    };

    constructor() {
        const byGroup = groupBy(this.surveyData, 'group');
        forEach(byGroup, (items, groupName) => {
            this.surveyGroupAverageHash[groupName] = [];
            for (let i = 0; i < this.seriesNames.length; i++) {
                this.surveyGroupAverageHash[groupName].push(meanBy(items, item => item.data[i]));
            }
        });
    }

    getStrengths(seriesName: string, numItems: number = 5) {
        const seriesIndex = indexOf(this.seriesNames, seriesName);

        const sorted = sortBy(this.surveyData, surveyItem => surveyItem.data[seriesIndex]);
        return reverse(takeRight(sorted, numItems));
    }

    getWeaknesses(seriesName: string, numItems: number = 5) {
        const seriesIndex = indexOf(this.seriesNames, seriesName);

        const sorted = sortBy(this.surveyData, surveyItem => surveyItem.data[seriesIndex]);
        return take(sorted, numItems);
    }

    getSurveyItemByIndex(questionIndex: number) {
        return this.surveyData[questionIndex];
    }

    getSurveyGroupAverage(groupName: string) {
        return this.surveyGroupAverageHash[groupName];
    }

    getDataByDifference() {
        const clone = cloneDeep(this.surveyData);
        forEach(clone, item => {
            const diff = item.data[0] - item.data[1];
            // item.data[0] *= Math.sign(diff);
            // item.data[1] = item.data[0] - diff;
            item.data[0] = diff;

            set(item, 'diff', diff);
        });
        return sortBy(clone, 'diff');
    }
}
