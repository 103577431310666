import produce from 'immer';

import {catchError, tap} from 'rxjs/operators';
import {merge} from 'rxjs';

import {clone, filter, find, forEach, isEqual, some, sortBy, values} from 'lodash-es';

import {Action, Selector, State, StateContext} from '@ngxs/store';

import {AddFolderInCurrentFolder, DeleteFile, FileListUpdateSuccess, SetCurrentFolder, UpdateBucket, UploadFile, UploadFileToCurrentFolder} from './file-manager.actions';
import {FileManagerService} from '../file-manager.service';
import {FileRef} from '../file-ref.def';
import {Injectable} from '@angular/core';


export interface FileManagerStateModel {
    files: { [key: string]: FileRef };
    bucket: string;
    currentFolder: string[];
}

@State({
    name: 'fileManager',
    defaults: {
        files: {},
        bucket: '',
        currentFolder: []
    }
})
@Injectable()
export class FileManagerState {

    constructor(private fileService: FileManagerService) {
    }

    @Selector()
    static files(stateModel: FileManagerStateModel): FileRef[] {
        return values(stateModel.files);
    }

    @Selector()
    static filesInCurrentFolder(stateModel: FileManagerStateModel): FileRef[] {
        const filtered = filter(stateModel.files, file => isEqual(file.path, stateModel.currentFolder));
        const sorted = sortBy<FileRef>(filtered, [(file => file.isFolder ? 0 : 1), (file => file.name)]);
        return sorted;
    }

    @Selector()
    static currentFolder(stateModel: FileManagerStateModel): string[] {
        return stateModel.currentFolder;
    }

    @Selector()
    static bucket(stateModel: FileManagerStateModel): string {
        return stateModel.bucket;
    }

    /**************************************** ACTIONS ****************************************************************/
    @Action(UpdateBucket)
    updateBucket(context: StateContext<FileManagerStateModel>, action: UpdateBucket) {
        const state = context.getState();
        if (state.bucket !== action.bucket) {
            const nextState = produce(context.getState(), draft => {
                draft.bucket = action.bucket;
            });

            context.setState(nextState);

            this.fileService.getFiles(action.bucket);
        }
    }

    @Action(FileListUpdateSuccess)
    fileListUpdateSuccess(context: StateContext<FileManagerStateModel>, action: FileListUpdateSuccess) {
        let filesChanged = false;
        const state = context.getState();
        const sorted = sortBy<FileRef>(action.files, [(file => file.isFolder ? 0 : 1), (file => file.name)]);

        const nextState = produce(context.getState(), draft => {
            draft.files = {};
            // check if any files were added
            forEach(sorted, file => {
                if (!filesChanged) {
                    filesChanged = !state.files.hasOwnProperty(file.id);
                }
                draft.files[file.id] = file;
            });

            // check if any files were deleted
            if (!filesChanged) {
                filesChanged = some(state.files, file => !some(action.files, {id: file.id}));
            }
        });


        if (filesChanged) {
            context.setState(nextState);
        }
    }

    @Action(SetCurrentFolder)
    setCurrentFolder(context: StateContext<FileManagerStateModel>, action: SetCurrentFolder) {
        const nextState = produce(context.getState(), draft => {
            draft.currentFolder = action.folder;
        });

        context.setState(nextState);
    }

    @Action(UploadFileToCurrentFolder)
    uploadFileToCurrentFolder(context: StateContext<FileManagerStateModel>, action: UploadFileToCurrentFolder) {
        const state = context.getState();

        // const fileRef = this.fileService.getFileRef(action.file.name, state.currentFolder.join('/'), false, action.file.size);
        // context.setState(this.addFileToState(state, [fileRef]));

        const existingFile = find(state.files, file => {
            return file.name === action.file.name && isEqual(file.path, state.currentFolder);
        });

        return this.fileService
            .uploadFile(action.file, state.bucket, state.currentFolder, existingFile)
            .pipe(
                tap((fileRef) => {
                    if (existingFile) {
                        const nextState = produce(context.getState(), draft => {
                            draft.files[existingFile.id].size = fileRef.size;
                            draft.files[existingFile.id].url = fileRef.url;
                        });
                        context.setState(nextState);
                    }
                }),
                catchError(err => {
                    console.error('Error uploading the file ', err);
                    // context.setState(this.deleteFilesFromState(context.getState(), [fileRef]));
                    throw err;
                })
            );
    }

    @Action(UploadFile)
    uploadFile(context: StateContext<FileManagerStateModel>, action: UploadFile) {
        const state = context.getState();

        const folder = action.path.split('/');
        const existingFile = find(state.files, file => {
            return file.name === action.file.name && isEqual(file.path, folder);
        });

        return this.fileService
            .uploadFile(action.file, state.bucket, folder, existingFile)
            .pipe(
                tap((fileRef) => {
                    if (existingFile) {
                        const nextState = produce(context.getState(), draft => {
                            draft.files[existingFile.id].size = fileRef.size;
                            draft.files[existingFile.id].url = fileRef.url;
                        });
                        context.setState(nextState);
                    }
                }),
                catchError(err => {
                    console.error('Error uploading the file ', err);
                    throw err;
                })
            );
    }

    @Action(AddFolderInCurrentFolder)
    addFolder(context: StateContext<FileManagerStateModel>, action: AddFolderInCurrentFolder) {
        const state = context.getState();

        const folder = this.fileService.getFileRef(action.folderName, state.bucket, state.currentFolder, true);
        context.setState(this.addFileToState(state, [folder]));

        return this.fileService
            .addFolder(folder, state.bucket)
            .catch(err => {
                console.error('Error creating folder ', err);
                context.setState(this.deleteFilesFromState(state, [folder]));
                throw err;
            });
    }

    @Action(DeleteFile)
    deleteFile(context: StateContext<FileManagerStateModel>, action: DeleteFile) {
        const state = context.getState();

        let filesToDelete = [action.file];
        if (action.file.isFolder) {
            filesToDelete = this.fileService.getFilesInFolder(action.file, state.files);
        }

        context.setState(this.deleteFilesFromState(state, filesToDelete));

        const observables = [this.fileService.deleteFiles(filesToDelete, state.bucket)];
        if (action.file.isFolder) {
            const directoriesToDelete = filter(filesToDelete, {isFolder: true});
            directoriesToDelete.push(action.file);
            observables.push(this.fileService.deleteFolders(directoriesToDelete, state.bucket));
        }

        return merge(...observables)
            .pipe(
                catchError(err => {
                    console.error('Error creating folder ', err);
                    context.setState(this.addFileToState(context.getState(), [action.file]));
                    throw err;
                })
            );
    }

    private addFileToState(state: FileManagerStateModel, files: FileRef[]): FileManagerStateModel {
        const nextState = produce(state, draft => {
            forEach(files, file => {
                draft.files[file.id] = file;
            });
        });
        return nextState;
    }

    private deleteFilesFromState(state: FileManagerStateModel, files: FileRef[]): FileManagerStateModel {
        const nextState = produce(state, draft => {
            forEach(files, file => delete draft.files[file.id]);
        });
        return nextState;
    }
}
