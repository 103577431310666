import {ChangeDetectorRef, Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import {forEach} from 'lodash-es';
import {TransferFunctionName, TransferFunctions} from '../../core/transfer-functions.model';

@Component({
    selector: 'cs-edit-edge-function-dialog',
    templateUrl: './edit-edge-function-dialog.component.html',
    styleUrls: ['./edit-edge-function-dialog.component.scss']
})
export class EditEdgeFunctionDialogComponent {

    // easingFunctions: any[];
    // transferFunction: TransferFunctionName;
    // impact: number[];

    weight: number;

    constructor(public dialogRef: MatDialogRef<EditEdgeFunctionDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private cdr: ChangeDetectorRef) {
        // this.easingFunctions = [
        //     {key: TransferFunctions.getFullDisplayName(TransferFunctionName.Linear), value: TransferFunctionName.Linear},
        //     {key: TransferFunctions.getFullDisplayName(TransferFunctionName.EaseInMedium), value: TransferFunctionName.EaseInMedium},
        //     {key: TransferFunctions.getFullDisplayName(TransferFunctionName.EaseOutMedium), value: TransferFunctionName.EaseOutMedium},
        //     {key: TransferFunctions.getFullDisplayName(TransferFunctionName.EaseInOutMedium), value: TransferFunctionName.EaseInOutMedium}
        // ];
        //
        // this.transferFunction = this.data.transferFunction;
        // this.impact = this.data.impact;

        this.weight = this.data.weight;
    }


    save() {
        // forEach(this.impact, (val, index) => {
        //     let numVal = Number(val);
        //     if (isNaN(numVal)) {
        //         numVal = this.data.impact[index];
        //     }
        //     this.impact[index] = numVal;
        // });

        if (isNaN(this.weight)) {
            this.weight = this.data.weight;
        }

        this.dialogRef.close({
            // transferFunction: this.transferFunction,
            // impact: this.impact
            weight: this.weight
        });
    }

    close(): void {
        this.dialogRef.close();
    }
}
