import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {AngularFireAuth} from '@angular/fire/compat/auth';

import {Observable} from 'rxjs';
import {switchMap, take} from 'rxjs/operators';

import {environment} from '../../../environments/environment';

@Injectable()
export class AuthHeaderInterceptor implements HttpInterceptor {

    constructor(private afAuth: AngularFireAuth) {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.url.startsWith(environment.firebaseApiRoot)) {
            // add authorization header with jwt token if available
            return this.afAuth.idToken
                .pipe(
                    take(1),
                    switchMap(token => {
                        req = req.clone({
                            setHeaders: {
                                Authorization: `Bearer ${token}`
                            }
                        });
                        return next.handle(req);
                    })
                );
        } else {
            return next.handle(req);
        }
    }
}
