import {NgModule} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';

import {FuseSharedModule} from '@fuse/shared.module';
import {EditEdgeFunctionDialogComponent} from './edit-edge-function-dialog.component';
import {PlumberService} from './plumber.service';

@NgModule({
    declarations: [
        EditEdgeFunctionDialogComponent
    ],
    imports: [
        MatToolbarModule,
        MatIconModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,

        FuseSharedModule
    ],
    exports: [
        EditEdgeFunctionDialogComponent
    ],
    providers: [
        PlumberService
    ],
})
export class PlumberModule {
}
