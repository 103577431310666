import {Injectable} from '@angular/core';

import {Store} from '@ngxs/store';

import {find} from 'lodash-es';

import {Observable} from 'rxjs';

import {FileManagerState} from './store/file-manager.state';
import {UploadFile} from './store/file-manager.actions';
import {FileRef} from './file-ref.def';

@Injectable()
export class FileManagerUtils {

    constructor(private store: Store) {
    }

    findFileById(id: string): FileRef {
        return find(this.store.selectSnapshot(FileManagerState.files), {id: id});
    }

    uploadFile(file: File, path: string): Observable<any> {
        return this.store.dispatch(new UploadFile(file, path));
    }
}
