import {Injectable} from '@angular/core';
import {AngularFireStorage} from '@angular/fire/compat/storage';

import {Observable, of} from 'rxjs';
import {catchError, delay, map, retryWhen, switchMap, take} from 'rxjs/operators';


@Injectable()
export class StorageService {

    constructor(private storage: AngularFireStorage) {
    }

    getDownloadUrlWithDelay(path: string): Observable<string> {
        const initialDelay = 2000;
        return of([])
            .pipe(
                delay(initialDelay),
                switchMap(() => this.storage.ref(path).getDownloadURL()),
                retryWhen(errors => {
                    return errors
                        .pipe(
                            delay(initialDelay),
                            take(5)
                        );
                })
            );
    }

    getDownloadUrl(path: string, ignoreCache: boolean = false): Observable<string> {
        if (path) {
            return this.storage
                .ref(path)
                .getDownloadURL()
                .pipe(
                    map(url => {
                        return url;
                    }),
                    catchError(err => {
                        console.log('Error ', err);
                        return of(path);
                    })
                );
        }
    }
}
