import {Injectable} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot} from '@angular/router';

import {get} from 'lodash-es';

import {ConfirmDialogComponent} from 'app/common/confirm-dialog/confirm-dialog.component';

import {AuthService} from './auth.service';
import {User} from './user.model';
import {CultureMapComponent} from '../main/apps/culture-map/culture-map.component';
import {filter, first} from 'rxjs/operators';

@Injectable()
export class BoardGuards implements CanDeactivate<CultureMapComponent> {

    constructor(private auth: AuthService, private dialog: MatDialog) {

    }

    canDeactivate(component: CultureMapComponent, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot): Promise<boolean> {
        return this.checkIfBoardDirty();
    }

    checkIfBoardDirty(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.auth
                .onUserChange$
                .pipe(
                    filter(user => !!user),
                    first()
                )
                .subscribe((user: User) => {
                    const isMapDirty = get(user, 'activeProject.activeBoard.isDirty', null);
                    if (isMapDirty) {
                        const confirmDialogRef = this.dialog.open(ConfirmDialogComponent);
                        confirmDialogRef.componentInstance.message = 'You have unsaved changes.<br>Do you want to save the map?';
                        confirmDialogRef.componentInstance.title = 'Save Changes';
                        confirmDialogRef.componentInstance.confirmButtonText = 'Save';
                        confirmDialogRef.componentInstance.cancelButtonText = 'Later';

                        confirmDialogRef.afterClosed().subscribe(result => {
                            if (result) {
                                user.activeProject
                                    .saveBoard(user.activeProject.activeBoard)
                                    .then(() => resolve(true))
                                    .catch(() => resolve(true));
                            } else {
                                resolve(true);
                            }
                        });
                    } else {
                        resolve(true);
                    }
                });
        });
    }
}
