import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';

import {FuseSharedModule} from '@fuse/shared.module';

import {FooterComponent} from 'app/layout/components/footer/footer.component';
import {KnowledgeBaseArticleComponent} from './article/article.component';

@NgModule({
    declarations: [
        FooterComponent,
        KnowledgeBaseArticleComponent
    ],
    imports: [
        RouterModule,

        MatButtonModule,
        MatIconModule,
        MatToolbarModule,
        MatDialogModule,

        FuseSharedModule
    ],
    exports: [
        FooterComponent
    ],
})
export class FooterModule {
}
