import { FuseUtils } from '@fuse/utils';
import {invert} from 'lodash-es';
import {ISnippetList} from '../../components/snippets/snippet.types';

export enum KbArticleCategory {
    Action = 'Action Plan',
    Practice = 'Best Practice',
    About = 'About',
    Profile = 'Profile',
    References = 'References',
    Blank = ''
}

export enum KbArticleCategoryProperty {
    Action = 'recommendations',
    Practice = 'bestPractices',
    About = 'about',
    Profile = 'profile',
    References = 'references',
    Blank = ''
}

export const KbCategoryHash: Record<KbArticleCategory, KbArticleCategoryProperty> = {
    [KbArticleCategory.Action]: KbArticleCategoryProperty.Action,
    [KbArticleCategory.Practice]: KbArticleCategoryProperty.Practice,
    [KbArticleCategory.About]: KbArticleCategoryProperty.Profile,
    [KbArticleCategory.Profile]: KbArticleCategoryProperty.Profile,
    [KbArticleCategory.References]: KbArticleCategoryProperty.References,
    [KbArticleCategory.Blank]: KbArticleCategoryProperty.Blank,
};

export const PropertyToCategoryHash = invert(KbCategoryHash);


export enum KbArticleStatus {
    Published= 'published',
    Draft = 'draft',
    Archived = 'archived'
}
export interface MediaDescriptor {
    id: string;
    version: number;
    fileName: string;
    url: string;
}

export interface KbArticleDef {
    id:         string;
    author:     string;
    lastModified:   string;
    content:        string;
    snippets: ISnippetList;
    title:       string;
    slug:       string;
    companies: string[];
    isPublic: boolean;
    category:   KbArticleCategory;
    section?: string;
    tags:       string[];
    status:     KbArticleStatus;
    media: MediaDescriptor[];
    isSnippitized?: boolean;
}


export class KbArticle
{
    id:         string;
    author:     string;
    lastModified:   string;
    content:        string;
    snippetIds: string[];
    title:       string;
    slug:       string;
    companies: string[];
    isPublic: boolean;
    category:   KbArticleCategory;
    section?: string;
    tags:       string[];
    status:     KbArticleStatus;
    media: MediaDescriptor[];
    isSnippitized: boolean;


    constructor(articleDef?: KbArticleDef)     {
        const article: KbArticleDef | any = articleDef || {};
        this.id = article?.id || FuseUtils.generateGUID();
        this.snippetIds = article?.snippetIds || [];
        this.author = article.author || '';
        this.slug = article.slug || '';
        this.title = article.title || '';
        this.content = article.content || '';
        this.lastModified = article.lastModified || '';
        this.companies = article.companies || [];
        this.tags = article.tags || [];
        this.category = article.category || KbArticleCategory.Practice;
        this.media = article.media || [];
        this.status = article.status || KbArticleStatus.Draft;
        this.isPublic = article.isPublic || false;

        this.isSnippitized = this.snippetIds.length > 0;
    }

}
