import {Pipe, PipeTransform} from '@angular/core';
import {FuseUtils} from '@fuse/utils';
import {get} from 'lodash-es';

@Pipe({name: 'csfilter'})
export class FilterPipe implements PipeTransform {
    /**
     * Transform
     *
     * @param {any[]} mainArr
     * @param {string} searchText
     * @param {string[]} searcheableFields to match for searchText
     * @returns {any}
     */
    transform(mainArr: any[], searchText: string): any {
        if (searchText.length < 2) {  return mainArr; }
        const res = FuseUtils.filterArrayByString(mainArr, searchText);
        console.log('Filtering: ', searchText, mainArr, res);
        return res;
    }
}
