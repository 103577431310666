import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {LiveSupportService} from '../../../core/live-support.service';
import { MatDialog } from '@angular/material/dialog';
import {KnowledgeBaseArticleComponent} from './article/article.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';




@UntilDestroy()
@Component({
        selector: 'footer',
        templateUrl: './footer.component.html',
        styleUrls: ['./footer.component.scss']
    })
export class FooterComponent implements OnInit, OnDestroy {

    liveSupportConfig = {
        isOperatorOnline: false,
        message: 'Help me out'
    };

    currentYear = new Date().getFullYear();

    readonly articles = {
        terms: {
            title: 'Terms of Use',
            content: 'Terms and conditions will appear here!'
        },
        privacy: {
            title: 'Privacy Policy',
            content: 'Privacy policy will appear here!'
        }
    };

    constructor(private matDialog: MatDialog,
                private cdr: ChangeDetectorRef,
                private liveSupport: LiveSupportService) {
    }

    ngOnInit(): void {
        this.liveSupport
            .onOperatorStatusChange$
            .pipe(untilDestroyed(this))
            .subscribe(status => {
                this.liveSupportConfig.isOperatorOnline = (status === 'online');
                this.liveSupportConfig.message = this.liveSupportConfig.isOperatorOnline ? 'Help me out now' : 'Help me out';
                this.cdr.detectChanges();
            });
    }

    // With ngx-takeuntil-destroy, for AOT this method must be present, even if empty!
    // Otherwise 'ng build --prod' will optimize away any calls to ngOnDestroy,
    // even if the method is added by the @TakeUntilDestroy decorator
    ngOnDestroy(): void {
    }

    readArticle(type: string) {
        this.matDialog.open(KnowledgeBaseArticleComponent, {
            panelClass: 'knowledgebase-article-dialog',
            data: {article: this.articles[type]}
        });
    }

    launchSupport() {
        this.liveSupport.startLiveChat();
    }

}
