<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-grey-100-bg m-0">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{dialogTitle}}</span>
            <button mat-button class="mat-icon-button"
                    (click)="dialogRef.close()"
                    aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 m-0" fusePerfectScrollbar>
        <cs-dynamic-form [formMetadata]="formMetadata"
                         (onSubmitAttempt)="save(form.model)"
                         #form="dynamicForm">
        </cs-dynamic-form>
    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">
        <button mat-raised-button
                (click)="save(form.model)"
                [disabled]="!form.isValid()"
                class="save-button mat-accent"
                aria-label="SAVE">
            SAVE
        </button>
    </div>
</div>
