import {clamp, map} from 'lodash-es';

export class ColorHelper {

    static hexToRGB(hexColor, opacity) {
        const hex = hexColor.replace('#', '');
        const rgbColor = [
            parseInt(hex.substring(0, 2), 16),
            parseInt(hex.substring(2, 4), 16),
            parseInt(hex.substring(4, 6), 16)
        ];
        return rgbColor;
    }


    static luminance(r, g, b) {
        const a = map([r, g, b], (v) => {
            v /= 255;
            return v <= 0.03928
                ? v / 12.92
                : Math.pow((v + 0.055) / 1.055, 2.4);
        });
        return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
    }

    static contrast(rgb1, rgb2) {
        return (this.luminance(rgb1[0], rgb1[1], rgb1[2]) + 0.05)
            / (this.luminance(rgb2[0], rgb2[1], rgb2[2]) + 0.05);
    }



    // static isBetterContrastOnBlack(hexColor: string) {
    //     const backgroundColor = ColorHelper.hexToRGB(hexColor, 1);
    //
    //     const blackColorContrast = ColorHelper.contrast([1, 1, 1], backgroundColor);
    //     const whiteColorContrast = ColorHelper.contrast(backgroundColor, [255, 255, 255]);
    //     const isDark = blackColorContrast > whiteColorContrast;
    //
    //     return isDark;
    // }

    static isBetterContrastOnBlack(hexcolor: string){

        let r, g, b: number;

        // Check if RGB format...just in case
        if (hexcolor.slice(0, 3) !== 'rgb') {

            // If a leading # is provided, remove it
            if (hexcolor.slice(0, 1) === '#') {
                hexcolor = hexcolor.slice(1);
            }

            // If a three-character hexcode, make six-character
            if (hexcolor.length === 3) {
                hexcolor = hexcolor.split('').map(function (hex) {
                    return hex + hex;
                }).join('');
            }

            // Convert to RGB value
            r = parseInt(hexcolor.substr(0, 2), 16);
            g = parseInt(hexcolor.substr(2, 2), 16);
            b = parseInt(hexcolor.substr(4, 2), 16);

        } else {

             [r, g, b] = hexcolor
                            .replace('rgba(', '')
                            .replace('rgb(', '')
                            .replace(')', '')
                            .split(',')
                            .map((val) => parseInt(val, 10) );
        }


        // Get YIQ ratio
        const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;

        // Check contrast
        return (yiq >= 128);

    }

    static generatePalette(basePalette: string[]): string[] {
        const count = basePalette.length;
        const indices = (Array(100)).fill(0).map((v, i) => Math.floor(i * count / 100));
        const palette = indices.map((i) => basePalette[i]);
        return palette;
    }



}
