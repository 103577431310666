<image-cropper
    [imageFile]="imageFile"
    [maintainAspectRatio]="isCircle"
    [aspectRatio]="1"
    [resizeToWidth]="256"
    [cropperMinWidth]="128"
    [onlyScaleDown]="false"
    [roundCropper]="isCircle"
    format="png"
    outputType="base64"
    (imageCropped)="imageCropped($event)"
    [alignImage]="'center'">
</image-cropper>
