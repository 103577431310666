import {Injectable} from '@angular/core';

import {isEmpty, isFunction} from 'lodash-es';


import {BehaviorSubject} from 'rxjs';
import {filter, take} from 'rxjs/operators';

import {AuthService} from './auth.service';
import {IUser} from './user.model';

declare var Tawk_API: any;

@Injectable()
export class LiveSupportService {

    readonly onOperatorStatusChange$ = new BehaviorSubject<string>('offline');

    constructor(private auth: AuthService) {

    }

    updateUserInfo() {
        if (Tawk_API) {
            Tawk_API.onChatMinimized = () => {
                this.hideWidget();
            };

            Tawk_API.onStatusChange = (status) => {
                const isHidden = Tawk_API.isChatHidden();
                this.notifyStatusChange(status);
                if (isHidden) {
                    setTimeout(() => {
                        this.hideWidget();
                    });
                }
            };

            this.hideWidget();

            this.auth
                .onUserChange$
                .pipe(
                    filter(user => !isEmpty(user)),
                    take(1)
                )
                .subscribe( async(user: IUser) => {
                    if (Tawk_API && isFunction(Tawk_API.setAttributes)) {
                        // TODO move to a cloud function
                    //     const crypto = window.crypto;
                    //     let secret = environment.tawkApiKey; // the secret key
                    //     let enc = new TextEncoder();
                    //     let algorithm = { name: "HMAC", hash: "SHA-256" };
                    //
                    //     let key = await crypto.subtle.importKey("raw", enc.encode(secret), algorithm, false, ["sign", "verify"]);
                    //     let signature = await crypto.subtle.sign(algorithm.name, key, enc.encode(user.email));
                    //     let digest = btoa(String.fromCharCode(...new Uint8Array(signature)));
                    //
                    //     console.log(digest);
                    //
                    //
                    //
                    //
                    //
                    //     const key = sjcl.codec.utf8String.toBits(environment.tawkApiKey);
                    //     const out = (new sjcl.misc.hmac(key, sjcl.hash.sha256)).mac(user.email);
                    //
                    //     Tawk_API.setAttributes({
                    //             'name': user.name,
                    //             'email': user.email,
                    //             'hash': sjcl.codec.hex.fromBits(out)
                    //         },
                    //         (error) => {
                    //             if (error) {
                    //                 console.error('@ERROR Error updating the name and email on Tawk ', error);
                    //             } else {
                    //
                    //             }
                    //         });
                    }
                });
        }
    }

    notifyStatusChange(status: string) {
        this.onOperatorStatusChange$.next(status);
    }

    startLiveChat() {
        if (Tawk_API) {
            Tawk_API.showWidget();
            Tawk_API.maximize();
        }
    }


    private hideWidget() {
        if (Tawk_API && isFunction(Tawk_API.isChatHidden) && !Tawk_API.isChatHidden()) {
            Tawk_API.hideWidget();
        }
    }
}
