<mat-card class="dialog-layout-wrapper">
    <mat-toolbar fxFlex="0 0 64px" class="mat-grey-100-bg m-0">
        <mat-toolbar-row>
            <span class="title dialog-title">File Manager</span>
            <span class="toolbar-spacer"></span>
            <button mat-button class="mat-icon-button"
                    (click)="dialogRef.close()"
                    aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <mat-card-content class="m-0 p-0">
        <file-manager></file-manager>
    </mat-card-content>

    <!--<mat-card-actions class="m-0 px-16" fxLayout="row" fxLayoutAlign="space-between center">-->
        <!--<button mat-raised-button-->
                <!--(click)="dialogRef.close()"-->
                <!--class="mat-accent"-->
                <!--aria-label="SAVE">-->
            <!--CLOSE-->
        <!--</button>-->
    <!--</mat-card-actions>-->
</mat-card>


