import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {AngularFireStorage} from '@angular/fire/compat/storage';

import {BehaviorSubject, Observable, merge, from, of} from 'rxjs';
import {filter, map, switchMap, tap} from 'rxjs/operators';

import {Store} from '@ngxs/store';

import {filter as _filter, forEach, isEmpty, kebabCase, last, lowerCase, without} from 'lodash-es';

import {FileRef} from './file-ref.def';
import {FileListUpdateSuccess} from './store/file-manager.actions';

const fileTypeHash = {
    'doc': 'file-word',
    'docx': 'file-word',
    'xls': 'file-excel',
    'xlsx': 'file-excel',
    'csv': 'file-excel',
    'pdf': 'file-pdf',
    'ppt': 'file-powerpoint',
    'pptx': 'file-powerpoint',
    'pps': 'file-powerpoint',
    'ppsx': 'file-powerpoint',
    'txt': 'file-document',
    'mp3': 'file-music',
    'mp4': 'file-video',
    'avi': 'file-video',
    'mkv': 'file-video',
    'png': 'file-image',
    'jpg': 'file-image',
    'jpeg': 'file-image',
    'gif': 'file-image'
};

export interface FileUploadProgressEvent {
    total: number;
    transferred: number;
    isActive: boolean;
    percentage: number;
}

@Injectable()
export class FileManagerService implements Resolve<any> {

    readonly onFileUploadProgress$: BehaviorSubject<FileUploadProgressEvent> = new BehaviorSubject<FileUploadProgressEvent>({
        transferred: 0,
        total: 0,
        isActive: false,
        percentage: 0
    });

    /**
     * Constructor
     *
     * @param afs
     * @param storage
     * @param store
     */
    constructor(
        private afs: AngularFirestore,
        private storage: AngularFireStorage,
        private store: Store
    ) {
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        of(true);
    }

    /**
     * Get files
     *
     * @returns {Promise<any>}
     */
    getFiles(bucket: string) {
        this.afs
            .collection<any>(`${bucket}/files`)
            .valueChanges()
            .subscribe(fileDocs => {
                const files = [];
                forEach(fileDocs, fileDoc => {
                    const folders = without(fileDoc.path.replace(`${bucket}`, '').split('/'), '');
                    const extension = lowerCase(last(fileDoc.name.split('.')));
                    const fileType = fileDoc.isFolder ? 'folder' : fileTypeHash[extension] || 'file-question';

                    const doc: FileRef = {
                        id: fileDoc.id,
                        name: fileDoc.name,
                        isFolder: fileDoc.isFolder || false,
                        fileType: fileType,
                        path: folders,
                        parent: last(folders) || ''
                    };

                    if (!fileDoc.isFolder) {
                        doc.size = fileDoc.size;
                        doc.url = fileDoc.url;
                    }

                    files.push(doc);
                });

                this.store.dispatch(new FileListUpdateSuccess(files));
            });
    }

    uploadFile(file: File, bucket: string, folder: string[], existingFileRef?: FileRef): Observable<any> {
        const bucketParts = without(bucket.split('/'), '');
        const path = [...bucketParts, ...folder].join('/');
        const isOverwriting = !isEmpty(existingFileRef);

        let docRef;
        let docId;
        if (isOverwriting) {
            docId = existingFileRef.id;
            docRef = this.afs.doc(`${bucket}/files/${docId}`);

        } else {
            docRef = this.afs.collection(`${bucket}/files`).ref.doc();
            docId = docRef.id;
        }

        const metadata = {fileId: docRef.id};
        const uploadTask = this.storage.upload(`${path}/${file.name}`, file, {customMetadata: metadata});

        const newFileRef = {
            id: docId,
            name: file.name,
            path: path,
            isFolder: false,
            url: '',
            size: file.size
        };

        return uploadTask
            .snapshotChanges()
            .pipe(
                tap((snapshot) => {
                    if (snapshot.state === 'running') {
                        const pct = snapshot.totalBytes === 0 ? 0 : snapshot.bytesTransferred / snapshot.totalBytes;
                        this.onFileUploadProgress$.next({
                            transferred: snapshot.bytesTransferred,
                            total: snapshot.totalBytes,
                            isActive: snapshot.totalBytes !== snapshot.bytesTransferred,
                            percentage: pct * 100
                        });
                    }
                }),
                switchMap(snapshot => {
                    // workaround for the bug https://github.com/angular/angularfire2/issues/1805
                    // the snapshot changes does not emit success state
                    return from(uploadTask);
                }),
                switchMap(snapshot => {
                    if (snapshot.state === 'success') {
                        return from(snapshot.ref.getDownloadURL());
                    } else {
                        return of(null);
                    }
                }),
                switchMap((downloadUrl: string) => {
                    if (downloadUrl) {
                        newFileRef.url = downloadUrl;
                        return from(docRef.set(newFileRef)).pipe(map(() => newFileRef));
                    } else {
                        return of(null);
                    }
                }),
                filter(fileRef => !isEmpty(fileRef))
            );
    }

    addFolder(folder: FileRef, bucket: string) {
        const bucketParts = without(bucket.split('/'), '');
        return this.afs.doc(`${bucket}/files/${folder.id}`).set({
            id: folder.id,
            name: folder.name,
            path: [...bucketParts, ...folder.path].join('/'),
            isFolder: true
        });
    }

    deleteFiles(files: FileRef[], bucket: string): Observable<any> {
        const observables = [];

        const bucketParts = without(bucket.split('/'), '');
        forEach(files, file => {
            const path = [...bucketParts, ...file.path, file.name].join('/');

            // delete the file from the bucket
            if (!file.isFolder) {
                const deleteFileObservable = this.storage
                    .ref(path)
                    .delete();
                observables.push(deleteFileObservable);
            }

            // delete the fire entry from database
            const deleteDocObservable = from(this.afs.doc(`${bucket}/files/${file.id}`).delete());
            observables.push(deleteDocObservable);
        });
        return merge(...observables);
    }

    deleteFolders(folders: FileRef[], bucket: string): Observable<any> {
        const promises = [];
        forEach(folders, folder => {
            promises.push(this.afs.doc(`${bucket}/files/${folder.id}`).delete());
        });
        return from(Promise.all(promises));
    }

    // getFileRef(id: string, fileName: string, path: string, isFolder: boolean, size: number = 0): FileRef {
    getFileRef(fileName: string, bucket: string, folders: string[], isFolder: boolean, size: number = 0): FileRef {
        const collection = this.afs.collection(`${bucket}/files`);
        const docRef = collection.ref.doc();

        const extension = lowerCase(last(fileName.split('.')));
        const fileType = isFolder ? 'folder' : fileTypeHash[extension] || 'file-question';

        const doc: FileRef = {
            id: docRef.id,
            name: fileName,
            path: folders,
            parent: last(folders) || '',
            fileType: fileType,
            isFolder: isFolder
        };
        if (!isFolder) {
            doc['size'] = size;
        }

        return doc;
    }

    getFilesInFolder(folder: FileRef, allFiles: FileRef[] | { [key: string]: FileRef }) {
        let files = [];
        if (folder.isFolder) {
            files = _filter(allFiles, (file: FileRef) => file.path.includes(folder.name));
        }
        return files;
    }

    getFileType(fileName: string) {
        if (fileName) {
            const extension = last(fileName.split('.'));
            return fileTypeHash[extension] || 'file-question';
        } else {
            return 'file-question';
        }
    }

    private getFileId(path: string, fileName: string) {
        return kebabCase(path) + kebabCase(fileName);
    }
}
