import {FileAttachment} from '@cultursys/file-manager';

import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';

import {isEmpty, take, find, forEach, remove, some} from 'lodash-es';

import {Store} from '@ngxs/store';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {Subject, combineLatest} from 'rxjs';
import {switchMap} from 'rxjs/operators';

import {FileManagerState} from '@cultursys/file-manager';
import {FileRef} from '../file-ref.def';
import {FileManagerService} from '../file-manager.service';

@UntilDestroy()
@Component({
        selector: 'file-attachments',
        templateUrl: './file-attachments.component.html',
        styleUrls: ['./file-attachments.component.scss']
    })
export class FileAttachmentsComponent implements OnInit, OnChanges, OnDestroy {

    @Input() attachments: Array<FileAttachment>;
    @Input() disabled = false;
    @Input() placeholder = 'Search files...';

    @Output() attachmentAdded = new EventEmitter<FileRef>();
    @Output() attachmentRemoved = new EventEmitter<FileRef | FileAttachment>();

    fileAttachments: FileRef[] = [];

    missingFiles: any[] = [];

    private fileAttachmentsSource = new Subject();
    private fileAttachments$ = this.fileAttachmentsSource.asObservable();

    constructor(private store: Store, private fileService: FileManagerService) {
        combineLatest(this.store.select(FileManagerState.files), this.fileAttachments$)
            .pipe(
                untilDestroyed(this)
            )
            .subscribe(([files, attachments]) => {
                this.missingFiles = [];
                forEach(this.attachments, attachment => {
                    if (!some(this.fileAttachments, {id: attachment.id})) {
                        const file = find(files, {id: attachment.id});
                        if (file) {
                            this.fileAttachments.push(file);
                        } else {

                            this.missingFiles.push({
                                ...attachment,
                                fileType: this.fileService.getFileType(attachment.name)
                            });
                            console.warn('File not found for attachment ', attachment);
                        }
                    }
                });
            });
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.attachments) {
            this.fileAttachments = [];
            this.fileAttachmentsSource.next(changes.attachments.currentValue);
        }
    }

    // With ngx-takeuntil-destroy, for AOT this method must be present, even if empty!
    // Otherwise 'ng build --prod' will optimize away any calls to ngOnDestroy,
    // even if the method is added by the @TakeUntilDestroy decorator
    ngOnDestroy(): void {
    }

    getFileDisplayName(file: FileRef | FileAttachment) {
        const nameParts = file.name ? file.name.split('.') : ['Unknown', ''];
        return take(nameParts, nameParts.length - 1);
    }

    onFileAttached(file: FileRef) {
        if (!some(this.fileAttachments, {id: file.id})) {
            this.fileAttachments.push(file);
            this.attachmentAdded.emit(file);
        }
    }

    onFileRemoved(file: FileRef | FileAttachment) {
        const removed = remove(this.fileAttachments, {id: file.id});
        if (isEmpty(removed)) {
            remove(this.missingFiles, missingFile => missingFile.id === file.id);
        }
        this.attachmentRemoved.emit(file);
    }
}
