import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import {FileUploadComponent} from './file-upload.component';
import {DropZoneModule} from '../drop-zone';
import {CroppieModule} from '../croppie';
import {CroppieDialogComponent} from './croppie-dialog.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {FlexModule} from '@angular/flex-layout';

@NgModule({
    declarations: [
        FileUploadComponent,
        CroppieDialogComponent
    ],
    imports: [
        CommonModule,
        FormsModule,

        MatButtonModule,
        MatIconModule,
        MatProgressBarModule,
        MatDialogModule,

        CroppieModule,

        DropZoneModule,
        MatTooltipModule,
        FlexModule
    ],
    exports: [
        FileUploadComponent
    ],
})
export class FileUploadModule {
}
