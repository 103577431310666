import {Injectable, NgZone} from '@angular/core';

import {find, forEach, includes, isEmpty, isUndefined} from 'lodash-es';

import 'jsplumb/dist/js/jsplumb';
import {Board} from '@cultursys/core';

import {alg} from 'graphlib';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {EditEdgeFunctionDialogComponent} from './edit-edge-function-dialog.component';
import {Node} from '../../core/node.model';

declare var jsPlumb: any;

@Injectable()
export class PlumberService {

    private commonConnector = {
        ConnectionOverlays: [['Arrow', {location: 1, visible: true, width: 11, length: 11, id: 'ARROW'}]],
        Connector: ['StateMachine', {curviness: 10, proximityLimit: 150}],
        EndpointStyle: {fill: '#bdbdbd'},
        Endpoint: ['Dot', {radius: 10, maxConnections: -1}],
        PaintStyle: {strokeWidth: 3, stroke: '#bdbdbd'},
        Container: 'plumber',
        DragOptions: {cursor: 'move', zIndex: 2000}
    };

    private plumber: any;
    private graph: any;
    private dialogRef: MatDialogRef<EditEdgeFunctionDialogComponent>;
    private tooltip: HTMLElement;
    private showTooltip = false;
    private board: Board;

    constructor(private dialog: MatDialog, private ngZone: NgZone) {
    }

    initMap(map: Board) {
        if (this.board) {
            this.destroy();
        }

        this.board = map;
        this.commonConnector.Container = this.board.container;
        this.plumber = jsPlumb.getInstance(this.commonConnector);
        window['plumber'] = this.plumber;

        this.tooltip = document.getElementById('connection-tooltip');
        this.graph = this.board.graph;

        this.bindPlumberEvents();
        this.initPlumber();
    }

    setShapes() {
        const shapes: any = this.plumber.getSelector('.shape'); // :not(.dashboard-gauge)
        // make everything draggable
        this.plumber.draggable(shapes, {
            containment: true,
            stop: () => {
                this.board.markAsDirty();
            }
        });
    }

    makeDraggable(nodeSelector) {
        this.plumber.draggable(nodeSelector, {containment: true});
    }

    private bindPlumberEvents() {
        this.plumber.bind('beforeDrop', (params: any) => {
            let connectIt = false;
            const newEdge = {v: params.sourceId, w: params.targetId, weight: 1, label: '1x'};

            console.log('@beforeDrop with connection ', params.sourceId, ' --> ', params.targetId, ' with params: ', this.board.graph.edge(params.sourceId, params.targetId));

            if (!this.graph.hasEdge(params.sourceId, params.targetId)) {
                const transferFunc = this.board.transferFunction;
                this.graph.setEdge(newEdge, {label: `${params.sourceId} -- 1x -> ${params.targetId}`, func: transferFunc});

                if (alg.isAcyclic(this.graph)) {
                    params.connection.bind('dblclick', this.onLabelDblClick);
                    this.board.refreshAllLinks();
                    this.board.updateNodeValues();

                    // console.log('@beforeDrop now adding connection ', params.sourceId, ' --> ', params.targetId, ' with params: ', newEdge);
                    connectIt = true;
                    this.setConnectionTooltip(params.connection, newEdge);
                } else {
                    console.warn('New connection would create a circular reference');
                    this.graph.removeEdge(params.sourceId, params.targetId);
                }

                this.board.markAsDirty();
            } else {
                console.warn('New connection already exists');
            }

            return connectIt;
        });

        this.plumber.bind('connectionMoved', (params) => {
            this.board.markAsDirty();
            return false;

            // const parameters = this.getParameters(params);
            //
            // const sourceChanged = params.originalSourceId !== params.newSourceId,
            //     originalParams = {sourceId: params.originalSourceId, targetId: params.originalTargetId};
            //
            // const stdParams = sourceChanged
            //     ? {sourceId: params.newSourceId, targetId: params.originalTargetId}
            //     : {sourceId: params.originalSourceId, targetId: params.newTargetId};
            //
            // // console.log('@connectionMoved original connection ', originalParams.sourceId, ' --> ', originalParams.targetId, ' with params: ', parameters);
            //
            //
            // if (this.board.connectionExists(originalParams)) {
            //     // console.log('@connectionMoved deleting connection ', originalParams.sourceId, ' --> ', originalParams.targetId);
            //     const weight = parseFloat(params.connection['getLabel']());
            //     this.board.deleteConnection(originalParams);
            // }
            //
            // return true;
        });

        this.plumber.bind('beforeDetach', (params) => {

            if (this.graph.hasEdge(params.sourceId, params.targetId)) {
                this.graph.removeEdge(params.sourceId, params.targetId);
                this.board.refreshAllLinks();
                this.board.updateNodeValues();
            }
            this.board.markAsDirty();

            return true;
        });
    }

    private initPlumber() {
        this.setShapes();

        // suspend drawing and initialise.
        this.plumber.batch(() => {
            forEach(this.board.nodes, (node) => {
                const source = this.plumber.getSelector('#' + node.id);


                if (!node.isGauge) {
                    const sourceLink = this.makeSource(source);

                }

                this.makeTarget(source);
            });

            // loop through them and connect each one to each other one.
            forEach(this.board.nodes, (node) => {
                const source = this.plumber.getSelector('#' + node.id);

                forEach(node.outputs, (weight, targetNodeId) => {

                    const target = this.plumber.getSelector('#' + targetNodeId);
                    const thickness = Math.max(1, Math.round((weight - 1) * 10 + 1));
                    const connectionParams = {
                        source: source,
                        target: target,
                        anchors: [
                            ['Perimeter', {shape: 'Ellipse'}],
                            ['Perimeter', {shape: 'Ellipse'}]
                        ],
                        deleteEndpointsOnDetach: true,
                        paintStyle: {strokeWidth: thickness, stroke: '#bdbdbd'}
                    };

                    const edge = this.board.graph.edge(node.id, targetNodeId);
                    const connection = this.plumber.connect(connectionParams);
                    this.setConnectionTooltip(connection, edge);
                    connection.bind('dblclick', this.onLabelDblClick);
                });
            });

            this.setAllWeights();
        });
    }

    setAsSource(id: string) {
        this.makeSource(this.plumber.getSelector('#' + id));
    }

    setAsTarget(srcId: string) {
        this.makeTarget(this.plumber.getSelector('#' + srcId));
    }

    private makeTarget(source: any) {
        return this.plumber.makeTarget(source, {
            isTarget: true,
            anchor: ['Perimeter', {shape: 'Ellipse'}],
            maxConnections: -1,
            allowLoopback: false,
            endpoint: ['Dot', {radius: 10}],
            paintStyle: {fill: '#ffa500'}
        });
    }

    setAllWeights() {
        const connections = this.plumber.select();

        forEach(this.board.nodes, (node) => {
            forEach(node.outputs, (weight, targetNodeId) => {

                connections.each(c => {
                    if (c.sourceId === node.id && c.targetId === targetNodeId) {

                        const realWeight = this.board.graph.edge(node.id, targetNodeId).weight;
                        const thickness = Math.max(1, Math.round((realWeight - 1) * 10 + 1));

                        if (!isNaN(thickness)) {
                            c.setPaintStyle({strokeWidth: thickness, stroke: '#bdbdbd'});
                        }
                    }
                });


            });
        });
    }

    private makeSource(source: any): any {
        return this.plumber.makeSource(source, {
            // filter: ':not(.inner-shape)',
            filter: function (event: MouseEvent, element) {

                return includes((event.target as HTMLElement).classList, 'is-drag-source');
                //
                // let isSource = true;
                //
                // if (event.target.hasClass()) && ancestor.className.includes('not-a-source'))
                // forEach(event.path, (ancestor => {
                //     if (isString(ancestor.className) && ancestor.className.includes('not-a-source')) {
                //         isSource = false;
                //         return false;
                //     }
                // }));
                // return isSource;
            },
            anchor: ['Perimeter', {shape: 'Ellipse'}],
            endpoint: ['Dot', {radius: 10}],
            maxConnections: -1,
            paintStyle: {fill: '#ffa500'},
            dragOptions: {cursor: 'cursor', zIndex: 3000}
        });
    }

    repaintAll() {
        this.plumber.repaintEverything();
    }

    destroy() {
        this.plumber.unbind();
        this.plumber.clear();
        this.plumber.reset();
    }

    deleteConnections(node: Node) {
        this.plumber.remove(node.id);
        this.repaintAll();
    }

    private getParameters(params) {
        const res = !isEmpty(params.connection) ? params.connection.getParameters() : {sourceNode: '?', weight: 1, targetNode: '?', move: true};
        return isEmpty(res) ? {sourceNode: '?', weight: 1, targetNode: '?', move: false} : res;
    }

    private onLabelDblClick = (params) => {
        // https://stackblitz.com/angular/pxglmmxpyejr?file=app%2Fdialog-overview-example-dialog.html
        if (!this.dialogRef) {
            const sourceId = params.component ? params.component.sourceId : params.endpoints[0].elementId;
            const targetId = params.component ? params.component.targetId : params.endpoints[1].elementId;

            this.ngZone.run(() => {
                const edge = this.board.graph.edge(sourceId, targetId);
                if (edge) {
                    this.dialogRef = this.dialog.open(EditEdgeFunctionDialogComponent, {
                        panelClass: 'connection-param-dialog',
                        data: {
                            weight: isNaN(edge.weight) ? 1 : edge.weight
                            // transferFunction: edge.func,
                            // impact: edge.impact
                        }
                    });

                    this.dialogRef.afterClosed().subscribe(result => {
                        if (result) {
                            // const label = TransferFunctions.getShortDisplayName(result.transferFunction);

                            const id = params.component ? params.component.id : params.id;
                            const connection: any = find(this.plumber.getConnections(), {id: id});

                            // edge.func = result.transferFunction;
                            // edge.impact = result.impact;
                            edge.weight = result.weight;


                            // if (connection) {
                            //     connection.setLabel(label);
                            //     connection.getLabelOverlay().setLocation(-20);
                            // }

                            this.board.updateNodeValues();
                            this.setAllWeights();
                            this.board.markAsDirty();
                        }
                        this.dialogRef = null;
                    });
                }
            });
        }
        //
        // const weight = window.prompt('Please enter the weight');
        // this['setLabel'](weight);

        // TODO Set Linear, In&Out, In, Out on this.graph.nodes ->
        //     'Linear: = 'linear'
        //     'EaseInMedium' =    'easeInCubic',
        //     'EaseOutMedium' =   'easeOutCubic',
        //     'EaseInOutMedium' = 'easeInOutCubic',

        // this.board.graph.edge(sourceId, targetId).label.transferFunction = ....,
        //
        // const board: Board = this['getParameter']('board');
        // const targetId = this['getParameter']('targetId');
        // const sourceId = this['getParameter']('sourceId');
        //
        // board.getNodeById('sourceId').addLink(LinkOrigin.Output, targetId, Number(weight));
        // board.getNodeById('targetId').addLink(LinkOrigin.Input, sourceId, Number(weight));
    }

    private setConnectionTooltip(connection, edge) {
        connection.bind('mouseover', (conn, ev) => {
            this.showTooltip = true;
            setTimeout(() => {
                if (this.showTooltip) {
                    const label = isUndefined(edge.weight) ? '1' : edge.weight.toString();
                    this.tooltip.innerHTML = label;
                    this.tooltip.style.display = 'block';
                    this.tooltip.style.top = ev.y + 20 + 'px';
                    this.tooltip.style.left = ev.x + 10 + 'px';
                    this.tooltip.style.height = '30px';
                    this.tooltip.style.width = '40px';
                    this.tooltip.style.display = 'absolute';
                }
            }, 100);

        });

        connection.bind('mouseout', (conn) => {

            this.showTooltip = false;
            this.tooltip.innerHTML = '';
            this.tooltip.style.top = '0';
            this.tooltip.style.left = '0';
            this.tooltip.style.height = '0';
            this.tooltip.style.width = '0';
            this.tooltip.style.display = 'none';
        });
    }
}
