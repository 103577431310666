import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import {FuseSharedModule} from '@fuse/shared.module';

import {FuseLoginComponent} from './login.component';
import {FuseForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {NoopComponent} from '../main/components/noop/noop.component';
import {ExecutiveSummaryComponent} from '../main/apps/exec-summary/executive-summary.component';
import {NoopComponentModule} from '../main/components/noop/noop-component.module';

const routes: Routes = [
    {
        path: '',
        component: FuseLoginComponent
    }
];

@NgModule({
    declarations: [
        FuseLoginComponent,
        FuseForgotPasswordComponent
    ],
    imports: [
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatInputModule,
        MatSlideToggleModule,

        FuseSharedModule,
        NoopComponentModule,
        RouterModule.forChild(routes)
    ]
})
export class LoginModule {
}
