export interface FileRef {
    id: string;
    name: string;
    isFolder: boolean;
    parent: string;
    path: string[];
    fileType: string;
    size?: number;
    url?: string;
}
