import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import {slice, some} from 'lodash-es';

import {Select, Store} from '@ngxs/store';


import {fuseAnimations} from '@fuse/animations/index';

import {FileManagerService, FileUploadProgressEvent} from './file-manager.service';
import {FileManagerState} from './store/file-manager.state';
import {AddFolderInCurrentFolder, SetCurrentFolder, UploadFileToCurrentFolder} from './store/file-manager.actions';
import {ToastrService} from 'ngx-toastr';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {FileManagerUtils} from './file-manager-utils.service';
import {FileRef} from './file-ref.def';
import {catchError} from 'rxjs/operators';



@UntilDestroy()
@Component({
        selector: 'file-manager',
        templateUrl: './file-manager.component.html',
        styleUrls: ['./file-manager.component.scss'],
        encapsulation: ViewEncapsulation.None,
        animations: fuseAnimations
    })
export class FileManagerComponent implements OnInit, OnDestroy {

    @Select(FileManagerState.currentFolder) currentFolder$;

    selected: any;
    progressEvent: FileUploadProgressEvent;

    /**
     * Constructor
     *
     */
    constructor(private _fileManagerService: FileManagerService,
                private dialog: MatDialog,
                private toasty: ToastrService,
                private fileManagerUtils: FileManagerUtils,
                private fileManagerService: FileManagerService,
                private cdr: ChangeDetectorRef,
                private store: Store) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.fileManagerService
            .onFileUploadProgress$
            .pipe(untilDestroyed(this))
            .subscribe(event => {
                this.progressEvent = event;
                this.cdr.detectChanges();
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    setFolderIndex(index: number) {
        const currentFolder = this.store.selectSnapshot(FileManagerState.currentFolder);
        if (index === -1) {
            this.store.dispatch(new SetCurrentFolder([]));
        } else if (currentFolder.length > index + 1) {
            this.store.dispatch(new SetCurrentFolder(slice(currentFolder, 0, index + 1)));
        }
    }

    uploadFile(file: File, fileSelector: HTMLInputElement) {
        if (file) {
            this.store
                .dispatch(new UploadFileToCurrentFolder(file))
                .subscribe(() => {
                    },
                    err => {
                        this.toasty.error('Failed to upload file. Try again', 'Upload File', { timeOut: 5000, closeButton: true});
                    });

            fileSelector.value = '';
        }
    }

    onSearchResultSelected(file: FileRef) {
        this.store.dispatch(new SetCurrentFolder(file.path));
    }

    onNewFolderName(folderName) {
        if (folderName) {
            const files = this.store.selectSnapshot(FileManagerState.filesInCurrentFolder);
            const hasFolderWithSameName = some(files, file => file.isFolder && file.name === folderName);

            if (hasFolderWithSameName) {
                this.toasty.info('Folder with that name already exists!', 'Add Folder', {timeOut: 2000});
            } else {
                this.store.dispatch(new AddFolderInCurrentFolder(folderName));
            }
        }
    }

}
