import { Pipe, PipeTransform } from '@angular/core';
import {FuseFilterService} from '../../core/fuse-filter.service';


@Pipe({ name: 'fuseFilter' })
export class FuseFilterPipe implements PipeTransform {

    constructor(private fuseService: FuseFilterService) { }

    transform(collection: Array<Object>, searchString: string, options = {}): any {
        return this.fuseService.search(collection, searchString, options);
    }
}
