import {Inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {AngularFirestore} from '@angular/fire/compat/firestore';

import {Store} from '@ngxs/store';

import firebase from 'firebase/compat/app';

import {extend, filter as _filter, forEach, isEmpty} from 'lodash-es';
import {BehaviorSubject, forkJoin, Observable, of} from 'rxjs';
import {filter, map, switchMap, take} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {GetProjectsSuccess} from '../store/app.actions';
import {ProjectDef} from './project.model';
import {User, UserRole} from './user.model';
import {ErrorRoutingService} from './error-routing.service';


@Injectable()
export class AuthService implements Resolve<User> {

    readonly onUserChange$: BehaviorSubject<User | null | undefined> = new BehaviorSubject<User | null | undefined>(undefined);

    private currentUser: User;

    // since fire base switches account on creating a new one, we create the account with a
    // secondary connection so that the admin session on primary connection is interrupted
    private readonly secondaryApp;

    constructor(private afAuth: AngularFireAuth,
                private afs: AngularFirestore,
                private router: Router,
                private store: Store,
                private errorRouting: ErrorRoutingService,
                @Inject('UserFactory') private userFactory) {

        this.secondaryApp = firebase.initializeApp(environment.firebase, 'secondary');
        this.watchUser();
    }

    get activeUser() {
        return this.currentUser;
    }

    get isAdmin(): boolean {
        return this.currentUser.isAdmin;
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<User> | Promise<User> | User {
        let message = 'Because we encountered an internal error';
        return this.onUserChange$
            .pipe(
                filter(user => !isEmpty(user)),
                take(1),
                switchMap(user => {
                    if (this.activeUser) {
                        return this.downloadUserProjects();
                    } else {
                        throw new Error('Failed to download user data');
                    }
                }),
                switchMap(projects => {
                    const filteredProjects = _filter(projects, project => !isEmpty(project));
                    if (isEmpty(filteredProjects)) {
                        message = 'Because you do not have any projects assigned to you yet';
                        return of(null);
                    } else {
                        return this.store.dispatch(new GetProjectsSuccess(filteredProjects));
                    }
                })
            );
    }


    downloadUserProjects() {
        const projectsData = [];
        forEach(this.activeUser.projects, project => {
            const projectData = this.afs
                .doc<ProjectDef>(`projects/${project.projectId}`)
                .snapshotChanges()
                .pipe(
                    take(1),
                    map(snapshot => {
                        if (snapshot.payload.exists) {
                            return snapshot.payload.data();
                        } else {
                            return null;
                        }
                    })
                );
            projectsData.push(projectData);
        });

        if (isEmpty(projectsData)) {
            return of([]);
        } else {
            return forkJoin(projectsData);
        }
    }

    private watchUser() {
        this.afAuth.authState
            .pipe(
                filter(user => !!user),
                switchMap((user) => {
                    if (user) {
                        return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
                    } else {
                        return of(null);
                    }
                }),
                map((newUser) => {
                    if (newUser) {
                        if (!this.currentUser || this.currentUser.uid !== newUser.uid) {
                            this.currentUser = this.userFactory(newUser);
                        }
                    } else {
                        this.currentUser = null;
                    }
                    return this.currentUser;
                })
            )
            .subscribe(user => {
                console.log('User changed ', user);
                this.onUserChange$.next(user);
            });
    }

    //// Email/Password Auth ////
    addUser(user: User, password: string) {
        if (this.currentUser.allow(UserRole.ProjectAdmin)) {
            let newUser: User;

            return this.secondaryApp.auth().createUserWithEmailAndPassword(user.email, password)
                .then((backendUser) => {
                    newUser = this.userFactory(extend(user, {uid: backendUser.user.uid}));
                    return this.afs.doc(`users/${newUser.uid}`).set(newUser.toObject());
                })
                .then(() => {
                    return Promise.resolve(newUser);
                })
                .catch((error) => {
                    this.handleError(error);
                    return Promise.resolve(null);
                });
        } else {
            console.warn('Illegal operation by user');
            this.signOut();
            return Promise.resolve(null);
        }
    }

    emailSignUp(user: User, password: string) {
        return this.afAuth.createUserWithEmailAndPassword(user.email, password)
            .then((credential) => {
                const userObj: User = extend(user, {uid: credential.user.uid});
                return userObj.save();
            })
            .catch((error) => this.handleError(error));
    }

    emailLogin(email: string, password: string) {
        return this.afAuth.signInWithEmailAndPassword(email, password)
            .then((response) => {
                this.watchUser();
                // return this.updateUserData(user); // if using firestore
                return this.onUserChange$;
            })
            .catch((error) => this.handleError(error));
    }

    // Sends email allowing user to reset password
    resetPassword(email: string) {
        const fbAuth = firebase.auth();

        return fbAuth.sendPasswordResetEmail(email)
            .then(() => console.log('Password update email sent'))
            .catch((error) => this.handleError(error));
    }

    signOut() {
        this.router
            .navigate(['/login'])
            .then(() => {
                this.onUserChange$.next(undefined);
                this.currentUser = null;
                return this.afAuth.signOut();
            })
            .then(() => {
                window.location.reload();
            });
    }

    // If error, console log and notify user
    private handleError(error: Error) {
        console.error(error);
    }
}
