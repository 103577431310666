import {AngularFirestore} from '@angular/fire/compat/firestore';
import {BehaviorSubject, Observable} from 'rxjs';

import {Injectable} from '@angular/core';
import {Attachment, EMPTY_SNIPPET, ISnippet, ISnippetList, SnippetCategory, SnippetCategoryHash, SnippetCategoryProperty, SnippetPropertyToCategoryHash} from './snippet.types';

import {FuseUtils} from '@fuse/utils';

import {SnippetList, snippetSanitizer, textSanitizer, titleCase} from './snippet-list/snippet-list.model';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {chunk, cloneDeep, defaults, flatten, forEach, get, isEmpty, pullAt, startsWith, without} from 'lodash-es';
import {AuthService, Board, StringUtils} from '@cultursys/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {filter} from 'rxjs/operators';
import {Store} from '@ngxs/store';
import {Node} from '../../../core/node.model';
import {KbArticlesService} from '../../../core/articles.service';


/**
 * This service provides an API to manage the published Snippets
 *
 * @param SnippetProject
 */

@Injectable({
    providedIn: 'root'
})
export class SnippetService {

    private _snippetList$: BehaviorSubject<ISnippetList> = new BehaviorSubject<ISnippetList>([]);
    private _filteredSnippetList$: BehaviorSubject<ISnippetList> = new BehaviorSubject<ISnippetList>([]);

    private _query = '';

    private listIsLoaded = false;

    public toggleModalArticleEditor$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);


    constructor(private afs: AngularFirestore,
                private auth: AuthService,
                private articles: KbArticlesService,
                private store: Store,
                private snackBar: MatSnackBar,
                private _snippetList: SnippetList) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------


    get list$(): Observable<ISnippetList> {
        return this._snippetList$.asObservable();
    }

    get filteredList$(): Observable<ISnippetList> {
        return this._snippetList._filteredSnippetList$.asObservable();
    }

    get list(): ISnippetList {
        return this._snippetList.all;
    }


    get snippets(): SnippetList {
        return this._snippetList;
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Gets all saved snippetService. Called by the resolver
     *
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        // console.log('^^^^^^^ Resolving Snippets.....');

        return this.fetchSnippetList();
    }

    getSnippetById(uid: string): ISnippet {
        return this._snippetList.get(uid);
    }


    getBlankSnippet(): ISnippet {
        const blank = cloneDeep(EMPTY_SNIPPET);
        blank.uid = FuseUtils.randomId(20);
        return blank;
    }

    getSnippetClone(snippet: ISnippet): ISnippet {
        const newSnippet = cloneDeep(snippet);
        newSnippet.uid = FuseUtils.randomId(20);
        return newSnippet;
    }

    /**
     * Persists the info for a new mapping
     *
     */
    async add(snippet: ISnippet): Promise<ISnippet> {
        // Save eagerly
        const newSnippet = this._snippetList.add(snippet);

        try {
            await this.afs.collection<ISnippet>(`snippets`)
                .doc(newSnippet.uid)
                .set(newSnippet);

        } catch (error) {
            console.error('Error adding snippet document: ', error);
            // Rollback
            this._snippetList.delete(newSnippet);
            throw new Error(`Error adding snippet document: ${error}`);
        }
        return newSnippet;
    }

    async update(snippet: ISnippet): Promise<ISnippet> {
        // Update eagerly
        const newSnippet = this._snippetList.update(snippet);

        try {
            await this.afs.doc<ISnippet>(`snippets/${newSnippet.uid}`).set(newSnippet);

        } catch (e) {
            console.error('Error updating document with ', e, newSnippet);
            throw new Error(`Error updating document: ${e}`);
        }

        return newSnippet;
    }

    async batchUpdate(snippets: ISnippet[]): Promise<ISnippet[]> {
        const results: ISnippet[] = [];

        // Get a new write batch
        let batch = this.afs.firestore.batch();

        snippets.forEach(snippet => {
            // Update eagerly
            const newSnippet = this._snippetList.update(snippet);
            // if (snippet.uid !== newSnippet.uid) {
            //     console.log('!!! MERGE !!! ', snippet, newSnippet);
            // }
            const snippetRef = this.afs.firestore.collection('snippets').doc(snippet.uid);
            batch.set(snippetRef, snippet);
            results.push(newSnippet);
        });

        try {
            // Commit the batch
            await batch.commit();
            console.log('Snippets batched written: ', snippets);
        } catch (e) {
            console.error('Error updating document with ', e, snippets);
            throw new Error(`Error updating document: ${e}`);
        }

        return results;
    }

    async delete(snippet: ISnippet): Promise<boolean> {
        try {
            // Update eagerly
            this._snippetList.delete(snippet);

            await this.afs
                .collection<ISnippet>(`snippets`)
                .doc(`${snippet.uid}`)
                .delete();


            // console.log('Document successfully deleted!');
            return true;
        } catch (error) {
            console.error('Error removing document: ', error);
            // Rollback
            this._snippetList.add(snippet);

            throw new Error(`Error deleting document: ${error}`);
        }
    }

    async deleteCollection(): Promise<void> {
        const batchSize = 50;
        const snippetsRef = this.afs.firestore.collection('snippets');
        const snippetsSnapshot = await snippetsRef.get();

        console.log(snippetsSnapshot);

        await Promise.all(chunk(snippetsSnapshot.docs, batchSize).map(async (snippetBatch) => {
            try {
                // Get a new batch
                let batch = this.afs.firestore.batch();

                snippetBatch.forEach(snippetRef => batch.delete(snippetRef.ref));

                // Commit the batch
                await batch.commit();
                console.log('Snippets batched deleted: ', snippetBatch);

            } catch (e) {
                console.error('Error deleting document with ', e);
                throw new Error(`Error updating document: ${e}`);
            }
        }));
    }

    public async resetCollection(snippets: ISnippet[]): Promise<void> {
        await this.deleteCollection();
        console.log('!!!!!!!!! Collection deleted');
        await this.batchUpdate(snippets);
        console.log('!!!!!!!!! Collection updated');

    }

    public saveNodeSnippets(nodeName: string, articleCategory: SnippetCategory, snippets: ISnippetList) {
        const category = SnippetCategoryHash[articleCategory];
        const project = this.auth.activeUser.activeProject;
        const board = project.activeBoard;
        const node = board.getNodeByName(nodeName);

        if (node && category) {
            node[category]['snippets'] = snippets;
            // board.node = node;
            // console.log('Updated Board: ', board.getNodeByName(nodeName)[category]['snippets']);
            node.broadcastChange();

            project.saveBoard(board).then(() => {
                return true;
                // this.snackBar.open(`Article successfully assigned to driver ${node.name}`, 'OK', {
                //     verticalPosition: 'top',
                //     duration: 5000
                // });
            })
                .catch(err => {
                    console.error(`Unable to assign article to driver ${node.name}`, err);
                    this.snackBar.open(`Unable to assign article to driver ${node.name}`, 'OK', {
                        verticalPosition: 'top',
                        duration: 5000
                    });
                });
        } else {
            console.log(`INVALID node name or snippet category. Unable to assign ${category} article to driver ${node.name}`);

        }
    }

    async fetchSnippetList(): Promise<ISnippetList> {
        try {
            this._snippetList.clear();
            this._query = '';

            const cache: ISnippet[] = [];

            const querySnapshot = await this.afs.collection<ISnippet>(`snippets`).get().toPromise();

            // Push the query result into the cache
            querySnapshot.docs.forEach((doc) => {
                // assign properties and any (new) properties with their default value
                const newISnippet = defaults(doc.data(), EMPTY_SNIPPET);
                cache.push(newISnippet);
                // Add to cache, if a similar one does not exist
                // const isSimilar = StringUtils.arrayHasSimilarItem(cache, newISnippet?.text, 'text');
                // if (!isSimilar) {
                //     cache.push(newISnippet);
                // }
            });

            this._snippetList.all = cache;
            this._snippetList.initializeFacets();
            // this._broadcastList(this._snippetList.all);

            this.observeNewSnippets();
            this.watchNewProject();

            // Flag successful load
            this.listIsLoaded = true;

            return this._snippetList.all;

        } catch (err) {
            console.error(err);
            return [];
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------


    private _sanitizeSources(snippet: ISnippet): ISnippet {

        const cleanSnippet = {...snippet};
        cleanSnippet.sources.forEach(s => {
            s.url = s?.url || '';
            s.filename = s?.filename || '';
        });

        return cleanSnippet;
    }


    // private _broadcastList(snippets: ISnippetList = this._snippetList.all) {
    //     this._snippetList$.next(snippets);
    //     if (this._query.length > 0) {
    //         this._filteredSnippetList$.next(this._snippetList.filter(this._query));
    //     } else {
    //         this._filteredSnippetList$.next(snippets);
    //     }
    // }


    private generateSearcheableIndex(snippet: ISnippet): void {
        // // Update searchable index: since we only care if 1 of the squirrel's properties contains the query string
        // // and not in which property the match was found, just create a string with all property values
        //
        // // flattening recursive callback: if it's an object return its values else 'key:value'
        // const fmd = (v,k,o) => isMap(v) ? flatMapDeep(fromPairs([...v.entries()]), fmd) : isObject(v) ? flatMapDeep(values(v), fmd) : `${k}:${v}`.toLowerCase();
        //
        // // remove the serialized values and the current index...so they don't get indexed
        //
        // // join all unique results and boolean values
        // snippet.searchIndex = uniq(flatMapDeep(snippet, fmd)).join(' ');
        // console.log('Flatmap: ', snippet.searchIndex);

    }

    public async snippitizeBoardNodes(board: Board): Promise<void> {
        const company = this.auth.activeUser.activeProject.company;
        forEach(board.nodes, async (node: Node) => {
            console.log('Start Snippitizing ', board.name, ' ---> ', node.name, node['bestPractices']);
            await this.ingestNodeArticles(node, board.name, company);
        });
    }

    async ingestNodeArticles(node: Node, boardName: string, company: string): Promise<boolean> {
        // For each of the node's article categories, prep the metadata then parse the text into snippets
        // let results: ISnippet[] = [];
        const results =  await Promise.all(['bestPractices', 'profile', 'recommendations', 'references'].map(async (categoryProp) => {
            return await this.getNodeSnippets(node, categoryProp as SnippetCategoryProperty);

            // console.log('-------> Raw snippets from node: ', node.name, categoryProp, results, results.length);
        }));

        const snippets = flatten(results);

        if (!isEmpty(snippets)) {
            this.batchUpdate(snippets);
        }
        return true
    }

    async getNodeSnippets(node: Node, categoryProp: SnippetCategoryProperty): Promise<ISnippet[]> {
        const metaArticle = get(node, [categoryProp], []);
        const category = SnippetPropertyToCategoryHash[categoryProp] as SnippetCategory;
        let snippets = (get(metaArticle, ['snippets'], [])).map(snippetSanitizer);
        const legacyArticle = get(metaArticle, ['text'], null);
        const isSnippitized = snippets.length > 0;
        const kbArticleId = get(metaArticle, ['articleIds', 0], null);


        if (!isSnippitized) {
            const company = this.auth.activeUser.activeProject.company;
            let kbArticle;
            if (kbArticleId) {
                kbArticle = await this.articles.fetchArticle(kbArticleId);
            }

            const articleContent = legacyArticle || kbArticle;
            if (!isEmpty(articleContent)) {
                snippets = this.ingestArticle(articleContent, category, [], [node.name], [company], metaArticle?.attachments);
            }
            // console.log('Ingested: ', node.name, categoryProp, node[categoryProp], snippets, articleContent);
        }

        return snippets;

    }

    ingestArticle(article: string, category: SnippetCategory, ogTags: string[], ogDrivers: string[], ogCompanies: string[], media: any[]): ISnippet[] {
        // console.log('Ingesting ', category);
        let tags = ogTags.map(t => titleCase(t));
        let drivers = ogDrivers.map(d => titleCase(d));
        let companies = ogCompanies.map(c => titleCase(c));
        const sources: Attachment[] = media.map(m => ({url: m.url, filename: m?.filename || m?.fileName}));

        let result: ISnippet[] = [];
        let fragments: string[];

        // Split the article by paragraph to create a snippet fragment.  Exclude <br>.
        let rawSnippets: string[];
        rawSnippets = without(article?.split(/<p>(.*?)<\/p>/g), '', '<br>') || [];

        if (isEmpty(rawSnippets)) {
            rawSnippets = article?.split(/\n\n/g);
        }

        if (!isEmpty(rawSnippets)) {

            // Clean-up by trimming, capitalizing and adding a period at the end of the last sentence.
            fragments = rawSnippets.map(s => textSanitizer(s));

            // Fix any snippet that starts as <a....>...</a> by appending it to the previous one
            let indiciesToDelete = [];
            forEach(fragments, (fragment, index) => {
                if (startsWith(fragment, '<a')) {
                    if (index === 0) {
                        fragments[1] = fragment + ' ' + fragments[1];
                        indiciesToDelete.push(0);
                    } else {
                        fragments[index - 1] = fragments[index - 1] + ' ' + fragment;
                        indiciesToDelete.push(index);
                    }
                }
            });

            // Remove the anchor fragments
            pullAt(fragments, indiciesToDelete);

            // Look for an identical snippet (by text)
            forEach(fragments, (fragment, index) => {
                const newSnippet = {
                    uid: FuseUtils.randomId(20),
                    text: fragment,
                    topics: tags,
                    drivers,
                    companies,
                    sources,
                    category: category,
                    order: index + 1
                };
                result.push(newSnippet);
            });
        }
        return result;
    }


    private observeNewSnippets() {
        this.snippets.newSnippet$.pipe(
            filter(s => this.listIsLoaded)
        )
            .subscribe((snippet) => {
                if (snippet) {
                    console.log('*** New Snippet watcher: ', snippet.category, snippet.text.slice(0, 150));
                    this.add(snippet);
                }
            });

        this.snippets.updateSnippet$.subscribe((snippet) => {
            if (snippet) {
                console.log('>>> Updated Snippet watcher: ', snippet.category, snippet.text.slice(0, 150));
                this.update(snippet);
            }
        });


    }

    private watchNewProject() {
        // this.store.select(AppState.activeProjectId).subscribe(projectId => {
        //     const boards = values(this.auth.activeUser.activeProject.boards);
        //     console.log('New boards: ', boards, this._snippetList.all);
        //     const company = this.auth.activeUser.activeProject.company;
        //
        //     boards.forEach(async board => {
        //         await forEach(board.nodes, async (node: Node) => {
        //             if (true) { // { (!board.isSnippitized) {
        //                 await this.ingestNodeArticles(node, board.name, company);
        //                 board.isSnippitized = true;
        //
        //                 // this.auth.activeUser.activeProject.saveBoard(board);
        //             }
        //         });
        //         console.table(this._snippetList.all)
        //
        //     });
        //
        //     // this.auth.activeUser.activeProject.saveProperty('isSnippitized' ,true);
        // });
    }


}
