import {Component} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'add-folder-dialog',
    templateUrl: './add-folder-dialog.component.html',
    styleUrls: ['./add-folder-dialog.component.scss']
})
export class AddFolderDialogComponent {

    folderName = '';

    /**
     * Constructor
     *
     * @param {MatDialogRef<AddFolderDialogComponent>} dialogRef
     */
    constructor(public dialogRef: MatDialogRef<AddFolderDialogComponent>) {
    }

}
