import {CommonModule, DatePipe} from '@angular/common';
import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import {AngularFireAuthModule} from '@angular/fire/compat/auth';
import {AngularFirestore, AngularFirestoreModule} from '@angular/fire/compat/firestore';

import {AngularFireStorage} from '@angular/fire/compat/storage';
import {AuthService} from './auth.service';
import {BoardGuards} from './board-guards.service';
import {httpInterceptors} from './interceptors';
import {LiveSupportService} from './live-support.service';
import {Project} from './project.model';
import {StorageService} from './storage.service';
import {User} from './user.model';
import {ProjectAdminGuard} from './project-admin-guard.service';
import {HealthSurveyService} from './health-survey.service';
import {ErrorRoutingService} from './error-routing.service';

import {MatSnackBarModule} from '@angular/material/snack-bar';


@NgModule({
    imports: [
        CommonModule,
        AngularFireAuthModule,
        AngularFirestoreModule,

        MatSnackBarModule
    ],
    providers: [
        AuthService,
        DatePipe,
        StorageService,
        LiveSupportService,
        BoardGuards,
        ProjectAdminGuard,
        HealthSurveyService,
        ErrorRoutingService,
        httpInterceptors,
        {
            provide: 'ProjectFactory',
            useFactory: (afs: AngularFirestore) => {
                return (projectDef): Project => {
                    return new Project(projectDef, afs);
                };
            },
            deps: [AngularFirestore, DatePipe]
        },
        {
            provide: 'UserFactory',
            useFactory: (afs: AngularFirestore, sfStorage: AngularFireStorage, projectFactory: any) => {
                return (userDef): User => {
                    return new User(userDef, afs, sfStorage, projectFactory);
                };
            },
            deps: [AngularFirestore, AngularFireStorage, 'ProjectFactory']
        }
    ],
    declarations: []
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
            throw new Error(
                'CoreModule is already loaded. Import it in the AppModule only');
        }
    }

    static forRoot(): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule
        };
    }
}
