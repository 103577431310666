import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import { ImageCropperModule } from 'ngx-image-cropper';

import {CroppieComponent} from './croppie.component';

@NgModule({
    imports: [
        CommonModule,

        ImageCropperModule
    ],
    declarations: [CroppieComponent],
    exports: [
        CroppieComponent
    ]
})
export class CroppieModule {
}
