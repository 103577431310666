<mat-card fxFill fxLayout="column" fxLayoutAlign="space-between stretch" class="m-0 p-0">
    <mat-toolbar class="mat-grey-100-bg mb-8 h-40">

        <mat-toolbar-row class="h-40">
            <!-- BREADCRUMB -->
            <section class="breadcrumb text-truncate font-size-16 w-400" fxLayout="row" fxLayoutAlign="start center"
                     [@animate]="{value:'*',params:{x:'50px'}}">
                <button mat-icon-button (click)="setFolderIndex(-1)">
                    <mat-icon>home</mat-icon>
                </button>
                <mat-icon [hidden]="(currentFolder$ | async).length === 0" class="separator">chevron_right
                </mat-icon>

                <div *ngFor="let path of currentFolder$ | async; last  as isLast; index as index" fxLayout="row"
                     fxLayoutAlign="start center">
                    <a class="path-link" (click)="setFolderIndex(index)">{{path}}</a>
                    <mat-icon *ngIf="!isLast" class="separator">chevron_right</mat-icon>
                </div>
            </section>
            <!-- / BREADCRUMB -->

            <!-- SEARCH -->
            <section class="toolbar-search">
                <file-search (searchResultSelected)="onSearchResultSelected($event)">
                </file-search>
            </section>

            <!-- /SEARCH -->

            <!-- CREATE FOLDER/FILE -->
            <section class="folder-actions">
                <input hidden type="file" (change)="uploadFile($event.target.files[0], fileInput)" #fileInput/>
                <cs-expandable-input icon="create_new_folder" (onEnter)="onNewFolderName($event)">
                </cs-expandable-input>


                <!--<button mat-icon-button (click)="showAddFolderDialog()">-->
                <!--<mat-icon class="secondary-text">create_new_folder</mat-icon>-->
                <!--</button>-->
                <button mat-icon-button (click)="fileInput.click()">
                    <mat-icon class="secondary-text">note_add</mat-icon>
                </button>

            </section>
            <!-- /CREATE FOLDER/FILE -->

        </mat-toolbar-row>
        <mat-toolbar-row class="h-24">
            <div  fxFill fxLayout="row nowrap" fxLayoutAlign="end center"  *ngIf="progressEvent.isActive">
                <mat-progress-bar [value]="progressEvent.percentage" class="w-100"
                                  mode="determinate"
                                  color="primary">
                </mat-progress-bar>
                <small class="ml-4 font-size-12 w-120">{{progressEvent.transferred | fileSize}} / {{progressEvent.total | fileSize}}</small>
            </div>

        </mat-toolbar-row>
    </mat-toolbar>

    <file-list class="file-list-container" fxLayout="column" fusePerfectScrollbar></file-list>

</mat-card>
