import {Injectable} from '@angular/core';
import {AngularFireStorage, AngularFireUploadTask} from '@angular/fire/compat/storage';

import {Observable} from 'rxjs';
import {finalize, tap} from 'rxjs/operators';

export interface UploadTask {
    downloadUrl?: Observable<string>;
}

@Injectable()
export class FileStorageService {

    constructor(private storage: AngularFireStorage) {
    }

    // Main task
    task: AngularFireUploadTask;

    // Progress monitoring
    percentage: Observable<number>;

    snapshot: Observable<any>;

    // Download URL
    downloadURL: Observable<string>;


    upload(path: string, file: File) {
        // The file reference
        const fileRef = this.storage.ref(path);

        // The main task
        this.task = this.storage.upload(path, file);

        // Progress monitoring
        this.percentage = this.task.percentageChanges();
        this.snapshot = this.task.snapshotChanges().pipe(
            tap(snap => {
                console.log(snap, 'download url ', fileRef.getDownloadURL());
                if (snap.bytesTransferred === snap.totalBytes) {
                }
            })
        );


        // The file's download URL
        // get notified when the download URL is available
        this.task.snapshotChanges().pipe(
            finalize(() => this.downloadURL = fileRef.getDownloadURL())
        )
            .subscribe();

    }
}
