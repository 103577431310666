<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="grey-100-bg m-0">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{dialogTitle}}</span>
            <button mat-button class="mat-icon-button"
                    (click)="dialogRef.close()"
                    aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>

        <mat-toolbar-row class="toolbar-bottom py-8 py-sm-16" fxLayout="column" fxLayoutAlign="center center">
            <cs-file-upload fxLayout="row"
                            fxFlex="100"
                            path="avatars"
                            [fileName]="newAvatarFileName"
                            (onUploaded)="onAvatarUploaded($event)"
                            (onImageCropped)="onAvatarCropped($event)">
                <img *ngIf="avatarBlob" [src]="avatarBlob"
                     class="avatar contact-avatar huge m-0"
                     [alt]="user.name"/>
                <img *ngIf="!avatarBlob" [src]="user.avatar" class=" avatar contact-avatar huge"/>
            </cs-file-upload>
            <div class="contact-name">{{user.name}}</div>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 m-0" fusePerfectScrollbar>
        <cs-dynamic-form [formMetadata]="formMetadata" #form="dynamicForm">
        </cs-dynamic-form>
    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">
        <button mat-raised-button
                (click)="save(form.model)"
                [disabled]="!form.isValid() || imageUploadInProgress"
                class="save-button mat-accent"
                aria-label="SAVE">
            SAVE
        </button>
    </div>
</div>
