import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProjectPickerComponent} from 'app/layout/components/project-picker/project-picker.component';
import {FuseSharedModule} from '@fuse/shared.module';
import {MatMenuModule} from '@angular/material/menu';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {MatDialogModule} from '@angular/material/dialog';


@NgModule({
    declarations: [ProjectPickerComponent],
    exports: [
        ProjectPickerComponent
    ],
    imports: [
        FuseSharedModule,

        MatDialogModule,
        MatMenuModule,
        MatDividerModule,
        MatIconModule
    ]
})
export class ProjectPickerModule {
}
