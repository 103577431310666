import {NgModule} from '@angular/core';

import {NoopComponent} from './noop.component';

@NgModule({
    declarations: [
        NoopComponent
    ],
    imports: [
    ],
    exports: [NoopComponent]
})
export class NoopComponentModule {
}
