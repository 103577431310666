import {Injectable} from '@angular/core';
import {sortBy} from 'lodash-es';

export interface Report {
    id: string;
    order: number;
    name: string;
    type: 'report' | 'chart';
    description: string;
    icon: string;
    uri: string;
}

export type Reports = Array<Report>;

const reports: Reports = [
    {
        id: 'report-1',
        order: 1,
        type: 'chart',
        uri: 'historical-scores',
        name: 'Culture Trends',
        description: 'Evolution of culture driver scores over time',
        icon: 'trending_up'
    }, {
        id: 'report-2',
        order: 2,
        type: 'chart',
        uri: 'strengths-and-risks',
        name: 'Strength & Risks',
        description: 'Ranked Survey Questions',
        icon: 'shuffle'
    }, {
        id: 'report-3',
        order: 3,
        type: 'chart',
        uri: 'slice-analysis',
        name: 'Map Slice Analysis',
        icon: 'layers',
        description: 'Compare culture scores across regions, functions and hierarchy'

    }, {
        id: 'report-4',
        order: 4,
        type: 'chart',
        uri: 'impact-analysis',
        name: 'Impact Analysis',
        icon: 'network_check',
        description: 'Identify the culture drivers with the most impact'

    }];

@Injectable({
    providedIn: 'root'
})
export class ReportsService {

    constructor() {
    }

    public get reportList(): Reports {
        return sortBy(reports, 'order');
    }
}
