import {Project, ProjectDef, ProjectRef} from '@cultursys/core';
import {Board, BoardDef} from '../core/board.model';

export class GetProjectsSuccess {
    static type = '[APP INIT] SET PROJECTS';

    constructor(public projects: ProjectDef[]) {
    }
}

export class SetProjects {
    static type = '[USER SETTINGS] SET PROJECTS';

    constructor(public projects: { [key: string]: ProjectRef }) {
    }
}

export class SetProjectName {
    static type = '[PROJECT PROPS] SET PROJECT NAME';

    constructor(public name: string, public id: string) {
    }
}

export class GetActiveProjectMapsSuccess {
    static type = '[APP INIT] GET ACTIVE PROJECT MAPS';

    constructor(public maps: BoardDef[]) {
    }
}

export class SetActiveMapId {
    static type = '[APP INIT] SET ACTIVE MAP ID';

    constructor(public mapId: string) {
    }
}

export class UpdateActiveMapId {
    static type = '[APP HOME] UPDATE ACTIVE MAP ID';

    constructor(public mapId: string) {
    }
}

export class SetActiveProjectId {
    static type = '[APP INIT] SET ACTIVE PROJECT ID';

    constructor(public projectId: string) {
    }
}

export class UpdateActiveProjectId {
    static type = '[APP TOOLBAR] UPDATE ACTIVE PROJECT ID';

    constructor(public projectId: string) {
    }
}

export class SetCurrentPeriod {
    static type = '[APP HOME] SET CURRENT PERIOD';

    constructor(public period: number, public periodTitle: string) {
    }
}

export class AddProject {
    static type = '[APP HOME] ADD PROJECT';

    constructor(public project: ProjectDef) {
    }
}

export class ImportProject {
    static type = '[PROJECT SETTINGS SIDE BAR] IMPORT PROJECT';

    constructor(public project: ProjectDef, public maps: BoardDef[]) {
    }
}

export class CloneProject {
    static type = '[APP HOME] CLONE PROJECT';

    constructor(public sourceProject: ProjectDef) {
    }
}

export class DeleteProject {
    static type = '[APP HOME] DELETE PROJECT';

    constructor(public project: Project) {
    }
}

export class SetSelectedNodeId {
    static type = '[APP HOME] SET SELECTED NODE ID';

    constructor(public nodeId: string) {
    }
}

export class ToggleMapEditorVisibility {
    static type = '[PROJECT SETTINGS] TOGGLE MAP EDITOR VISIBILITY';

    constructor() {
    }
}

export class AddMap {
    static type = '[MAP SIDEBAR] ADD MAP';

    constructor() {
    }
}

export class CloneMap {
    static type = '[MAP SIDEBAR] CLONE MAP';

    constructor(public map: Board) {
    }
}

export class DeleteMap {
    static type = '[MAP SIDEBAR] DELETE MAP';

    constructor(public map: Board) {
    }
}


