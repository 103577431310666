import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

import { ExpandableInputComponent } from './expandable-input.component';
import {FormsModule} from '@angular/forms';

@NgModule({
  imports: [
      CommonModule,
      FormsModule,

      MatAutocompleteModule,
      MatIconModule,
      MatInputModule,
      MatRippleModule
  ],
    exports: [
        ExpandableInputComponent
    ],

    declarations: [ExpandableInputComponent]
})
export class ExpandableInputModule { }

