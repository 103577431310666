import {Component, Inject, ViewEncapsulation} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import {cloneDeep, extend} from 'lodash-es';

import {defaultAssuranceData, defaultCPRData, DRIVER_FLAG_THRESHOLD, ProjectDef, StringUtils} from '@cultursys/core';
import {DynamicFormControlType, DynamicFormMetadata} from '@cultursys/dynamic-form';
import {CURRENCIES} from '../../../../core/currency.model';

@Component({
    selector: 'cs-project-form',
    templateUrl: './project-form.component.html',
    styleUrls: ['./project-form.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ProjectFormComponent  {

    formMetadata: DynamicFormMetadata[];
    dialogTitle: string;

    private action: string;
    private project: ProjectDef;

    constructor(public dialogRef: MatDialogRef<ProjectFormComponent>,
                @Inject(MAT_DIALOG_DATA) private data: any) {
        this.action = data.action;

        if (this.action === 'edit') {
            this.dialogTitle = 'Edit Project';
            this.project = data.project;
        } else {
            this.dialogTitle = 'New Project';

            this.project = {
                id: StringUtils.generateID(),
                name: '',
                description: '',
                company: '',
                group: '',
                version: '0.1',
                logoUrl: '',
                designations: {},
                cprData: cloneDeep(defaultCPRData),
                assuranceData: cloneDeep(defaultAssuranceData),
                palette: [],
                baseCurrencyCode: CURRENCIES.GBP.code,
                redFlagThreshold: DRIVER_FLAG_THRESHOLD,
                isSnippitized: false
            };
        }
        this.createProjectForm();
    }

    save(formData: ProjectDef) {
        extend(this.project, formData);
        this.dialogRef.close(this.project);
    }

    private createProjectForm() {
        this.formMetadata = [
            {
                id: 'name',
                label: 'Name',
                required: true,
                value: this.project.name,
                type: DynamicFormControlType.Text,
                validationMessages: {
                    'required': 'Name is required'
                }
            },
            {
                id: 'description',
                label: 'Description',
                value: this.project.description,
                type: DynamicFormControlType.Text
            },
            {
                id: 'company',
                label: 'Company',
                value: this.project.company,
                type: DynamicFormControlType.Text
            },
            {
                id: 'version',
                label: 'Version',
                value: this.project.version,
                type: DynamicFormControlType.Text,
                validationMessages: {
                    'required': 'Email is required'
                }
            },
        ];
    }
}
