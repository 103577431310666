<button mat-menu-item [matMenuTriggerFor]="projectsMenu">
    <mat-icon *ngIf="icon">{{icon}}</mat-icon>
    <span>{{title}}</span>
</button>

<mat-menu #projectsMenu="matMenu">
    <ng-container *ngFor="let project of projectDetails; trackBy: trackProject"
                  [ngSwitch]="project.isLeaf">
        <button mat-menu-item (click)="projectSelected.emit(project)" *ngSwitchCase="true">
            {{project.name}}
        </button>

        <ng-container *ngSwitchDefault>
            <button mat-menu-item [matMenuTriggerFor]="companyProjectMenu">
                {{project.group}}
            </button>

            <mat-menu #companyProjectMenu="matMenu">
                <ng-container *ngFor="let companyProject of project.children; trackBy: trackProject">
                    <button mat-menu-item (click)="setSelectedProject(companyProject)"
                            [disabled]="companyProject.disabled">
                        {{companyProject.name}}
                    </button>
                </ng-container>
            </mat-menu>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="allowAdd">
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="addProject()" *ngIf="user?.isAdmin">
            <span>Add new</span>
        </button>
    </ng-container>
</mat-menu>
