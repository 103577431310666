import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

import {FuseConfigService} from '@fuse/services/config.service';
import {navigation} from 'app/navigation/navigation';

@UntilDestroy()
@Component({
        selector: 'horizontal-layout-1',
        templateUrl: './layout-1.component.html',
        styleUrls: ['./layout-1.component.scss'],
        encapsulation: ViewEncapsulation.None
    })
export class HorizontalLayout1Component implements OnInit, OnDestroy {
    fuseConfig: any;
    navigation: any;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {
        // Set the defaults
        this.navigation = navigation;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(untilDestroyed(this))
            .subscribe((config) => {
                this.fuseConfig = config;
            });

        // this.router.events
        //     .pipe(
        //         untilDestroyed(this),
        //         filter(event => event instanceof NavigationEnd)
        //     )
        //     .subscribe((event: NavigationEnd) => {
        //         let isNavMenuVisible = this.fuseConfig.layout.navbar.hidden;
        //         const isAdminState = event.url.startsWith('/apps/administrator');
        //         if (isAdminState && isNavMenuVisible) {
        //             isNavMenuVisible = false;
        //         } else if (!isAdminState && !isNavMenuVisible) {
        //             isNavMenuVisible = true;
        //         }
        //
        //         if (isNavMenuVisible !== !this.fuseConfig.layout.navbar.hidden) {
        //             const newConfig = cloneDeep(this.fuseConfig);
        //
        //             newConfig.layout.navbar.hidden = !isNavMenuVisible;
        //             this._fuseConfigService.setConfig(newConfig);
        //         }
        //     });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
    }

    private isAdminState(path: string) {
        const urlTree = this.router.createUrlTree(['/apps/administrator'], {relativeTo: this.activatedRoute});
        return this.router.isActive(urlTree.toString(), false);
    }

}
