import {AngularFirestore, AngularFirestoreDocument} from '@angular/fire/compat/firestore';

import {clamp, cloneDeep, extend, find, forEach, isEmpty, isNumber, isObject, isUndefined, keys, map, values, words} from 'lodash-es';

import {BehaviorSubject} from 'rxjs';

import {Board, BoardDef} from './board.model';
import {Node} from './node.model';
import {CURRENCIES} from './currency.model';
import {FileAttachment} from '@cultursys/file-manager';

import {ColorHelper} from './color.helper';
import {SetCurrentPeriod} from '../store/app.actions';
import {Store} from '@ngxs/store';

export interface ProjectDataItem {
    text: string;
    attachments: Array<FileAttachment>;
}

const defaultProjectDataItem: ProjectDataItem = {
    text: '',
    attachments: []
};

export interface CPRData {
    managementConcerns: ProjectDataItem;
    strengths: ProjectDataItem;
    improvements: ProjectDataItem;
    risks: ProjectDataItem;
    reportFile?: FileAttachment;
}

export interface AssuranceData {
    overview: ProjectDataItem;
    strengths: ProjectDataItem;
    risks: ProjectDataItem;
    reportFile?: FileAttachment;
}

export interface PaletteEntry {
     value: number;
     color: string;
     from: number;
     to: number;
}

export const DRIVER_FLAG_THRESHOLD = 0.66;

export interface ProjectDef {
    id: string;
    name: string;
    description: string;
    company: string;
    group: string;
    version: string;
    logoUrl: string;
    designations: { [key: string]: boolean };
    cprData: CPRData;
    assuranceData: AssuranceData;
    palette: string[];
    redFlagThreshold: number;
    baseCurrencyCode: string;
    isSnippitized: boolean;
}

export const defaultCPRData: CPRData = {
    improvements: cloneDeep(defaultProjectDataItem),
    managementConcerns: cloneDeep(defaultProjectDataItem),
    risks: cloneDeep(defaultProjectDataItem),
    strengths: cloneDeep(defaultProjectDataItem)
};

export const defaultAssuranceData: AssuranceData = {
    risks: cloneDeep(defaultProjectDataItem),
    strengths: cloneDeep(defaultProjectDataItem),
    overview: cloneDeep(defaultProjectDataItem)
};

export class Project implements ProjectDef {

    id: string;
    name: string;
    description: string;
    company: string;
    group: string;
    version: string;
    logoUrl: string;
    designations: { [key: string]: boolean } = {};
    boards: { [key: string]: Board };
    cprData: CPRData;
    assuranceData: AssuranceData;
    palette: string[];
    redFlagThreshold: number;
    baseCurrencyCode: string;
    isKbImported?: boolean;
    isSnippitized: boolean;

    private _activeBoard: Board;

    private _fullPalette: PaletteEntry[];

    readonly onBoardsModified$: BehaviorSubject<Board[]> = new BehaviorSubject<Board[]>([]);

    readonly onProjectModified$ = new BehaviorSubject<Project>(this);

    private projectRef: AngularFirestoreDocument<any>;

    constructor(projectDef: ProjectDef,
                private afs: AngularFirestore) {
        extend(this, projectDef);
        this.ensureBackwardCompatibility();

        this.projectRef = this.afs.doc(`projects/${this.id}`);
    }


    get activeBoard() {
        return this._activeBoard;
    }

    setMaps(maps: BoardDef[]) {
        this.boards = {};
        forEach(maps, board => this.boards[board.id] = new Board(board));
        this.updateMapPalette();
        this.updateMetricCurrency();
        this.notifyBoardChange();
    }

    saveProperty(prop: string, value: any) {
        const data = {};
        data[prop] = value;
        return this.projectRef
            .update(data)
            .then(() => {
                this[prop] = value;
                if (prop === 'baseCurrencyCode') {
                    this.updateMetricCurrency();
                }
                this.onProjectModified$.next(this);
            })
            .catch(err => {
                console.error('Failed to save project property ', prop, value, err);
                throw err;
            });

    }

    addBoard(boardDef: BoardDef): Promise<Board> {
        const mapsCollection = this.afs.collection(`projects/${this.id}/maps`);
        const boardDoc = mapsCollection.ref.doc();

        boardDef.id = boardDoc.id;
        const board = new Board(boardDef);
        this.boards[board.id] = board;

        board.setPalette(this.getPaletteArray());

        this.notifyBoardChange();

        return boardDoc.set(board.toObject()).then(() => {
            board.markAsPristine();
            return board;
        });
    }

    deleteBoard(board: any): Promise<any> {
        const boards = values(this.boards);
        if (boards.length > 0) {
            delete this.boards[board.id];

            this.notifyBoardChange();

            return this.afs.doc(`projects/${this.id}/maps/${board.id}`).delete();
        } else {
            return Promise.reject('You must have at least one board');
        }
    }

    canDeleteBoard(board: Board) {
        return keys(this.boards).length > 1 && !board.isCPRMap;
    }

    saveBoard(board: Board): Promise<void> {
        board.cacheNodePositions();

        return this.afs
            .doc(`projects/${this.id}/maps/${board.id}`)
            .update(board.toObject())
            .then(() => {
                board.markAsPristine();
            });
    }

    cloneBoard(board: Board): Promise<Board> {
        board.cacheNodePositions();

        const boardDef = board.toObject();
        boardDef.name = `${boardDef.name} (copy)`;
        boardDef.uri = encodeURIComponent(boardDef.name).replace(/%20/g, '-').toLowerCase();

        return this.addBoard(boardDef);
    }

    setActiveBoard(boardId: string) {
        this.initActiveBoard(boardId);
    }

    findBoardById(boardId: string) {
        return find(this.boards, {id: boardId});
    }

    getBoard(boardId: string | null): Promise<BoardDef | null> {
        return Promise.resolve(find(this.boards, {id: boardId}));
    }

    updatePalette(palette: string[]) {
        this.saveProperty('palette', palette).then(() => {
            this.updateMapPalette();
        });
    }

    toObject() {
        const projectObj: ProjectDef = {
            id: this.id,
            name: this.name,
            company: this.company,
            group: this.group || '',
            description: this.description,
            version: this.version,
            logoUrl: '',
            designations: this.designations || {},
            cprData: this.cprData,
            assuranceData: this.assuranceData,
            palette: this.palette,
            redFlagThreshold: this.redFlagThreshold,
            baseCurrencyCode: this.baseCurrencyCode,
            isSnippitized: this.isSnippitized
        };

        return projectObj;
    }

    private notifyBoardChange() {
        this.onBoardsModified$.next(values(this.boards));
    }

    private initActiveBoard(boardId: string) {
        let board = this.boards[boardId];

        if (isEmpty(boardId) || !board) {
            boardId = keys(this.boards)[0];
            board = this.boards[boardId];
        }

        if (board) {
            this._activeBoard = board;
            this._activeBoard.setPalette(this.getPaletteArray());
        }
    }

    update(updateSet: any): Promise<void> {
        return this.afs.doc(`projects/${this.id}`).update(updateSet);
    }

    updateLogo(path: any) {
        return this.afs
            .doc(`projects/${this.id}`)
            .update({logoUrl: path})
            .then(() => {
                this.logoUrl = path;
                this.onProjectModified$.next(this);
            });
    }

    buildPalette() {
        const spectrum = ColorHelper.generatePalette(this.palette);
        this._fullPalette = spectrum.map((color, index) => {
            return {
                value: index,
                color: color,
                from: spectrum.indexOf(color),
                to: spectrum.lastIndexOf(color)
            };
        });
    }
    buildPlotBandPalette() {
        const spectrum = ColorHelper.generatePalette(this.palette);
        return this.palette.map((color, index) => {
            return {
                value: index,
                color: color,
                from: spectrum.indexOf(color) + 1,
                to: spectrum.lastIndexOf(color) + 1
            };
        });
        }



    getColorPalette(): PaletteEntry[] {
        return this._fullPalette;
    }

    getScoreColor(score: number): string {
        const color = this._fullPalette[clamp(Math.round(score) - 1, 0, 99)];
        return color ? color.color : 'transparent';
    }

    getPaletteArray() {
        return map(this._fullPalette, 'color');
    }

    private updateMapPalette() {
        const paletteArray = this.getPaletteArray();
        forEach(this.boards, mapObj => {
            mapObj.setPalette(paletteArray);
        });
    }

    updateCurrentPeriod(period: number) {
        forEach(this.boards, board => board.currentPeriod = period);
    }

    private ensureBackwardCompatibility() {
        if (!this.designations) {
            this.designations = {};
        }

        if (!this.cprData || !isObject(this.cprData.managementConcerns)) {
            this.cprData = cloneDeep(defaultCPRData);
        }

        if (!this.assuranceData || !isObject(this.assuranceData.risks) || !isObject(this.assuranceData.overview) || !isObject(this.assuranceData.strengths)) {
            this.assuranceData = cloneDeep(defaultAssuranceData);
        }

        if (!this.palette) {
            this.palette = [`#ff0000`, '#ff731a', '#fff700', '#a5e703', `#00ff00`];
        }

        this.buildPalette();

        if (isNaN(this.redFlagThreshold) || !isNumber(this.redFlagThreshold)) {
            this.redFlagThreshold = DRIVER_FLAG_THRESHOLD;
        }

        if (!this.baseCurrencyCode) {
            this.baseCurrencyCode = CURRENCIES.GBP.code;
        }
        if (isUndefined(this.isSnippitized)) {
            this.isSnippitized = false;
        }

        if (isEmpty(this.group)) {
            this.group = this.company;
        }
    }


    private updateMetricCurrency() {
        forEach(this.boards, board => {
            board.updateCurrency(this.baseCurrencyCode);
        });
    }
}
