<div fxLayout="column" fxLayoutAlign="start stretch">
    <file-search *ngIf="!disabled"
                 (searchResultSelected)="onFileAttached($event)"
                 [placeholder]="placeholder"
                 icon="attach_file"
                 fxFlexAlign="end">
    </file-search>

    <section class="mt-12" fxLayout="row wrap" fxLayoutAlign="start center">


        <div *ngFor="let file of fileAttachments" class="fuse-card variable-width file-card">
            <mat-icon (click)="onFileRemoved(file)"
                      class="delete-file s-14 on-parent-hover"
                      *ngIf="!disabled">
                cancel
            </mat-icon>

            <a target="_blank"
               [href]="file.url"
               [matTooltip]="file.name"
               fxLayout="row"
               fxLayoutAlign="start center">

                <span class="secondary-text mdi mdi-36px mdi-{{file.fileType}}"></span>
                <small fxFlex class="ml-8 file-title">{{getFileDisplayName(file)}}</small>
            </a>
        </div>

        <ng-container *ngIf="!disabled">
            <div *ngFor="let file of missingFiles" class="fuse-card variable-width file-card">
                <div class="broken-link-overlay white-fg" fxFill fxLayout="column" fxLayoutAlign="center center"
                     fxLayoutGap="6px"
                     matTooltip="Can't find {{file.name || 'a file'}} on this server.  Delete & Upload again">
                    <span class="mdi mdi-link-off mdi-36px"></span>
                </div>

                <button mat-icon-button (click)="onFileRemoved(file)" *ngIf="!disabled"
                        class="delete-file on-parent-hover">
                    <mat-icon [inline]="true">
                        cancel
                    </mat-icon>
                </button>

                <a fxLayout="row" fxLayoutAlign="start center">
                    <span class="secondary-text mdi mdi-36px mdi-{{file.fileType}}"></span>
                    <small fxFlex class="ml-8 file-title">{{getFileDisplayName(file)}}</small>
                </a>
            </div>
        </ng-container>
    </section>
</div>
