import { FuseUtils } from "@fuse/utils";
import * as graphLib from "graphlib";

import * as Ease from "js-easing-functions/dist";

import "jsplumb/dist/js/jsplumb";

import {
    clamp,
    cloneDeep,
    defaults,
    filter as _filter,
    find,
    first as _first,
    forEach as _forEach,
    forEach,
    get,
    isEmpty,
    isNull,
    isNumber,
    isObject,
    isUndefined,
    keys,
    map,
    maxBy,
    omit,
    reduce,
    remove,
    round,
    snakeCase,
    sum,
    sumBy,
    uniq,
    values,
    groupBy,
    sortBy,
} from "lodash-es";
import { Observable, Subject } from "rxjs";
import { debounceTime, share, startWith } from "rxjs/operators";
import { TransferFunctionName } from "./transfer-functions.model";

import { LinkOrigin, Node, Question, SwimLane } from "./node.model";
import { StringUtils } from "./string.utils";
import * as moment from "moment";
import { DeepPartial, ImportedMapData } from "app/core/imported-map-data.model";

export enum Dimensions {
    Region = "region",
    Function = "function",
    Level = "level",
}

export interface MapModificationEvent {
    map: Board;
    change?: any;
}

export interface ProcessMetric {
    id: string;
    name: string;
    displayUnit: string;
    isCurrency: boolean;
}

export interface MetricValue {
    value: number;
    target: number;
}

export interface DimensionTag {
    id: string;
    type: Dimensions;
    name: string;
}

export const DIMENSION_DESCRIPTORS = [
    { type: Dimensions.Region, label: "Region" },
    { type: Dimensions.Function, label: "Function" },
    { type: Dimensions.Level, label: "Level" },
];

export interface SliceDescriptor {
    id: string;
    [Dimensions.Region]: string;
    [Dimensions.Function]: string;
    [Dimensions.Level]: string;
}

export interface HistoricalSliceData {
    period: number;
    periodName: string;
    sliceId: string;
    metrics: { [key: string]: MetricValue };
    driverScores: {
        [nodeId: string]: {
            qScores: {
                [questionId: string]: number;
            };
            score: number;
        };
    };
}

export interface HistoricalValue {
    period: number;
    periodName: string;
    metrics: { [key: string]: MetricValue };
    driverScores: {
        [key: string]: {
            questions: { [key: string]: Question };
            score: number;
        };
    };
    totalScore: number;
}

export interface BoardDef {
    id: string;
    name: string;
    description: string;
    weight: number;
    order: number;
    totalScore: number;
    scoreLabel: string;
    nodeDefs: { [key: string]: any };
    edges: any[];
    swimlanes: { [key: string]: SwimLane };
    documents: any[];
    targetValue: number;
    transferFunction: TransferFunctionName;
    transferFunctionOptions: any;
    uri: string;
    metrics: ProcessMetric[];
    commitments: { main: string; [key: string]: string };
    historicalValues?: HistoricalValue[];
    historicalSlices: HistoricalSliceData[];
    selectedSlices: SliceDescriptor[];
    allSlices: SliceDescriptor[];
    modifiedOn: Date;
    permissions: { [key: string]: string[] };
    tags: DimensionTag[];
    comparedSlices: { sliceIds: string[]; name: string }[];
    isKbImported?: boolean;
    isSnippitized?: boolean;
}

export class Board implements BoardDef {
    id: string;
    name: string;
    description: string;
    weight: number;
    order: number;
    totalScore: number;
    scoreLabel: string;
    nodeDefs: { [key: string]: any };
    edges: any[];
    swimlanes: { [key: string]: SwimLane };
    container: string;
    documents: any[];
    targetValue: number;
    metrics: ProcessMetric[];
    historicalValues: HistoricalValue[];
    historicalSlices: HistoricalSliceData[];
    commitments: { main: string; [key: string]: string };
    permissions: { [key: string]: string[] };
    uri: string;
    tags: DimensionTag[];
    allSlices: SliceDescriptor[];
    selectedSlices: SliceDescriptor[];

    transferFunction: TransferFunctionName;
    transferFunctionOptions: any;

    graph: any;
    gaugeNode: Node;
    modifiedOn: Date;

    isWhatIfMode: boolean;

    isSnippitized?: boolean;

    private nodeModificationSource: Subject<{ node: Node; action: string }> =
        new Subject<{ node: Node; action: string }>();
    readonly onNodeModified$ = this.nodeModificationSource
        .asObservable()
        .pipe(share());

    private swimLaneModificationSource: Subject<{
        swimLane: SwimLane;
        action: string;
    }> = new Subject<{ swimLane: SwimLane; action: string }>();
    readonly onSwimLaneModified$ = this.swimLaneModificationSource
        .asObservable()
        .pipe(share());

    private mapModificationSource = new Subject<MapModificationEvent>();
    readonly onMapModified$: Observable<MapModificationEvent>;

    private _dirty = false;

    private _nodeList: { [key: string]: Node } = null;

    private _containerSize: { width: number; height: number } = {
        width: 1,
        height: 1,
    };

    private connectionData = [];
    private _currentPeriod = 0;
    palette: string[];
    comparedSlices: { sliceIds: string[]; name: string }[];

    constructor(board: BoardDef) {
        // In case new properties were added since the object was last saved
        defaults(this, isEmpty(board) ? {} : board, Board.boardDefaults);

        this.onMapModified$ = this.mapModificationSource
            .asObservable()
            .pipe(startWith({ map: this }), debounceTime(250));

        this.graph = new graphLib.Graph({ directed: true });
        this.graph.setGraph(this.name);

        this.transferFunction = values(TransferFunctionName).includes(
            board.transferFunction
        )
            ? board.transferFunction
            : TransferFunctionName.EaseInOutMedium;
        this.transferFunctionOptions = board.transferFunctionOptions;

        this.initializeNodes();

        if (isEmpty(this.allSlices)) {
            this.historicalSlices = [];
            this.tags = [];
        } else {
            this.sanitizeHistoricalSlices();
        }

        if (isEmpty(this.historicalSlices)) {
            this.initializeHistoricalValues();
            this.initPeriodicTagData();
        }

        this.consolidateData();

        if (isEmpty(this.commitments.main)) {
            this.commitments.main = "Enter your commitments here...";
        }
        this.syncHistoricValues();
    }

    static get boardDefaults(): BoardDef {
        return {
            id: StringUtils.generateID(),
            name: "Untitled Board",
            description: "Default Map",
            weight: 1,
            order: 0,
            totalScore: 0,
            scoreLabel: "Score",
            nodeDefs: {},
            edges: [],
            swimlanes: {},
            documents: [],
            targetValue: 0,
            metrics: [],
            historicalSlices: [],
            selectedSlices: [],
            allSlices: [],
            commitments: { main: "Enter your commitments here..." },
            uri: snakeCase("Untitled Board"),
            transferFunction: TransferFunctionName.EaseInOutMedium,
            transferFunctionOptions: {
                y0: 0.5, // y value for x=0
                y1: 1.5, // y value for x=1
                exponential: {},
                logistic: {
                    steepness: 11,
                },
            },
            modifiedOn: new Date(),
            permissions: {},
            tags: [],
            comparedSlices: [],
            isKbImported: false,
            isSnippitized: false,
        };
    }

    set canvas(x) {
        this.container = x;
        this.setNodeContainers();
    }

    set node(node: Node) {
        const id = node?.id;
        if (id && get(this._nodeList, id, null)) {
            this._nodeList[node?.id] = node;
        }
    }

    get nodes() {
        if (isNull(this._nodeList)) {
            this._nodeList = {};
            forEach(this.graph.nodes(), (nodeId) => {
                this._nodeList[nodeId] = this.graph.node(nodeId);
            });
        }
        return this._nodeList;
    }

    get currentPeriod() {
        return this._currentPeriod;
    }

    set currentPeriod(period) {
        if (period < this.numHistoricPeriods && period >= 0) {
            this._currentPeriod = period;
            this.applyHistoricNodeValues();
            this.rebase();
        }
    }

    get isDirty() {
        return this._dirty;
    }

    get isCPRMap() {
        return this.name.toLowerCase() === "cpr";
    }

    get displayName(): string {
        return this.isCPRMap ? "Assessment" : this.name;
    }

    saveCanvasSize() {
        const containerRef = document.getElementById(this.container);
        this._containerSize.width = containerRef.offsetWidth;
        this._containerSize.height = containerRef.offsetHeight;
        return this._containerSize;
    }

    adjustNodePositions() {
        this.relativizePositions(this._containerSize);
        this.setNodeAbsolutePositions();
        this.repositionGauge();
        this.saveCanvasSize();
    }

    relativizePositions(containerSize) {
        const height = containerSize.height,
            width = containerSize.width;

        forEach(this.nodes, (node) => {
            // save the position as a percentage of board size
            node.position.top = node.selector.offsetTop / height;
            node.position.left = node.selector.offsetLeft / width;
        });
    }

    get dashboardValue() {
        // let totalScore = 0,
        //     maxScore = 0;
        //
        // forEach(this.nodes, node => {
        //     if (!node.isGauge) {
        //         totalScore += node.value;
        //         maxScore += node.normalizationFactor;
        //     }
        // });
        //
        // return maxScore !== 0 ? round(totalScore / maxScore * 100, 0) : 0;
        let totalScore = 0;

        const totalWeights = reduce(
            keys(this.gaugeNode.inputs),
            (sum, sourceId) => {
                const inputNode = this.nodes[sourceId];
                const edge = this.graph.edge(sourceId, this.gaugeNode.id);
                const weight = isNaN(edge.weight) ? 1 : edge.weight;
                totalScore += inputNode.normalizedScore * weight;
                return sum + weight;
            },
            0
        );

        const maxScore = totalWeights === 0 ? 0 : totalScore / totalWeights;

        return round(maxScore * 100, 5);
    }

    get roundedDashboardValue() {
        return round(this.dashboardValue, 0);
    }

    get numHistoricPeriods(): number {
        let numPeriods = 0;
        this.historicalSlices.forEach((sliceData) => {
            if (
                this.selectedSlices.some((s) => s.id === sliceData.sliceId) &&
                numPeriods < sliceData.period
            ) {
                numPeriods = sliceData.period;
            }
        });
        return numPeriods + 1;
    }
    get numActivePeriods(): number {
        let numPeriods = 0;
        this.historicalSlices.forEach((sliceData) => {
            const scores = values(sliceData.driverScores).map(
                (driverScore) => driverScore.score
            );
            const scoreTally = sum(scores);
            const isActive = scoreTally !== 1; // gauge node = 1, all others = 0;
            numPeriods = isActive ? sliceData.period : numPeriods;
        });
        return numPeriods + 1;
    }

    historicalValueFor(period: number): HistoricalValue {
        return this.historicalValues[period];
    }

    getNodeByName(name): Node {
        return find(this.nodes, { name: name });
    }

    get mapScoreNode(): Node {
        return find(this.nodes, { isGauge: true });
    }

    getNodeById(id) {
        return this.nodes[id];
    }

    getFirstNode(excludeGauge: boolean = true) {
        if (excludeGauge) {
            return find(this.nodes, (node) => !node.isGauge) || null;
        } else {
            return values(this.nodes)[0] || null;
        }
    }

    addNode(name?: string, id?: string) {
        const nodeDef = isEmpty(id) ? {} : { id: id };
        const node = new Node(nodeDef);
        node.setPalette(this.palette);
        if (name) {
            node.displayName = name;
        }

        this.graph.setNode(node.id, node);
        // empty the cache
        this._nodeList = null;
        this.nodeModificationSource.next({ node: node, action: "add" });

        this.markAsDirty();

        return node;
    }

    deleteNode(node: Node) {
        this.graph.removeNode(node.id);

        // delete the connections
        forEach(node.inputs, (weight, sourceId) => {
            delete this.nodes[sourceId].outputs[node.id];
        });
        forEach(node.outputs, (weight, targetId) => {
            delete this.nodes[targetId].inputs[node.id];
        });

        // empty the cache
        this._nodeList = null;

        forEach(this.historicalValues, (historicVal) => {
            delete historicVal.driverScores[node.id];
        });

        this.nodeModificationSource.next({ node: node, action: "delete" });
        this.rebase();

        this.markAsDirty();
    }

    addSwimLane(name: string) {
        const maxOrderLane = maxBy(values(this.swimlanes), "order");
        const lane = {
            id: FuseUtils.generateGUID(),
            label: name,
            order: maxOrderLane ? maxOrderLane.order + 1 : 0,
        };

        this.swimlanes[lane.id] = lane;
        this.swimLaneModificationSource.next({
            swimLane: lane,
            action: "add",
        });

        this.markAsDirty();

        return lane;
    }

    deleteSwimLane(lane: SwimLane) {
        delete this.swimlanes[lane.id];
        this.swimLaneModificationSource.next({
            swimLane: lane,
            action: "delete",
        });

        this.markAsDirty();
    }

    rebase() {
        this.setScoreRange();

        // console.log("******-------  Setting Max Values");
        // this.setExtremeValues('max');

        // console.log("******-------  Setting Min Values");
        // this.setExtremeValues('min');

        // console.log("******-------  Randomizing Values");
        this.questionnaireScores();
        // this.randomizeScores();
        // this.setExtremeScore('max');
    }

    setPalette(palette: string[]) {
        this.palette = palette;
        forEach(this.nodes, (node) => {
            node.setPalette(palette);
            node.colorize();
        });
    }

    repositionGauge(showGauge = false) {
        const container = document.getElementById(this.container);
        const gaugeHeight = 164;
        const xOffset = showGauge ? 180 : 40;

        this.gaugeNode.position.top =
            (container.offsetHeight - gaugeHeight) / 2;
        this.gaugeNode.position.left = container.offsetWidth - xOffset;
        // this.gaugeNode.position.left = container.offsetWidth - 140;
        this.gaugeNode.setPosition();
        // console.log(`the position for Gauge is `, this.gaugeNode.position.top + 'px', this.gaugeNode.position.left + 'px');
    }

    setNodeContainers() {
        forEach(this.nodes, (node) => {
            node.selector = document.getElementById(node.id);
            // console.log(`the selector for ${node.name} is `, node.selector);
        });
    }

    setNodeAbsolutePositions() {
        const canvas = document.getElementById(this.container);
        const height = canvas.offsetHeight;
        const width = canvas.offsetWidth;

        forEach(this.nodes, (node) => {
            node.setAbsolutePosition(width, height);
        });

        this.saveCanvasSize();
    }

    setNodeColors() {
        forEach(this.nodes, (node) => {
            node.colorize();
        });
    }

    setTranferFunction(functionName: TransferFunctionName) {
        forEach(this.graph.edges(), (edgeObj) => {
            const edge = this.graph.edge(edgeObj);
            if (edge.func === this.transferFunction) {
                edge.func = functionName;
            }
        });
        this.transferFunction = functionName;
    }

    updateNodeValues() {
        const sortedNodes = graphLib.alg.topsort(this.graph);

        forEach(sortedNodes, (nodeId) => {
            const node = this.nodes[nodeId];

            node.value = this.calcNodeValue(node);

            // if (node.selector) {
            //     node.colorize();
            //     // (<HTMLElement>node.selector.firstElementChild).style['background-color'] = node.color;
            // }

            this.updateHistoricalValues(node);
        });
        this.historicalValues[this.currentPeriod].totalScore =
            this.dashboardValue;
        this.mapModificationSource.next({ map: this });
    }

    copyNodeValuesToSlice() {
        if (this.canEditSlice) {
            // first refresh node values
            this.updateNodeValues();

            // copy historic values to slice
            const selectedSlice = this.selectedSlices[0];
            const currentPeriodSliceData = this.getSliceData(
                selectedSlice.id,
                this.currentPeriod
            );
            const currentPeriodHistoricData = this.historicalValueFor(
                this.currentPeriod
            );
            forEach(this.nodes, (node) => {
                const questionScores = {};
                const questionObjs = {};

                forEach(node.questionnaire.questions, (q) => {
                    questionScores[q.id] = q.score;
                    questionObjs[q.id] = cloneDeep(q);
                });

                currentPeriodSliceData.driverScores[node.id] = {
                    qScores: questionScores,
                    score: node.normalizedScore,
                };
                currentPeriodHistoricData.driverScores[node.id] = {
                    questions: questionObjs,
                    score: node.normalizedScore,
                };
            });
            this.markAsDirty();
        }
    }

    resetNodePercentChange() {
        forEach(this.nodes, (node) => {
            node.percentageChange = 0;
        });
    }

    resetNodeScore() {
        forEach(this.nodes, (node) => {
            node.score = node.initialScore;
            node.previousScore = node.initialScore;
        });
    }

    setScoreRange() {
        forEach(this.nodes, (node: Node) => {
            node.max = node.questionnaire.maxTotal;
            node.min = node.questionnaire.minTotal;
        });
    }

    setExtremeScore(extreme) {
        this.resetNodeScore();
        forEach(this.nodes, function (node: Node) {
            node.score = node[extreme];
            node.previousScore = node[extreme];
            // console.log('---->step 1 (', node.name, '): set score = ', extreme, 'Score (', node[extreme], ')');
        });
        this.updateNodeValues();
        // this.setNodeColors();
    }

    get totalPercentChange() {
        if (this.currentPeriod > 0) {
            const totalScore =
                this.historicalValues[this.currentPeriod].totalScore;
            const previousTotalScore =
                this.historicalValues[this.currentPeriod - 1].totalScore;
            return (totalScore - previousTotalScore) / previousTotalScore;
        } else {
            return 0;
        }
    }

    get totalPercentChangeIcon() {
        let icon = "trending_flat";

        if (this.totalPercentChange > 0) {
            icon = "trending_up";
        } else if (this.totalPercentChange < 0) {
            icon = "trending_down";
        }

        return icon;
    }

    questionnaireScores() {
        forEach(this.nodes, (node: Node) => {
            node.score = node.initialScore;
            node.updateQuestionnaireScore();
        });

        this.updateNodeValues();
    }

    applyHistoricNodeValues() {
        forEach(this.nodes, (node: Node) => {
            if (!node.isGauge) {
                node.applyHistoricQuestionScores(
                    this.historicalValues[this.currentPeriod]
                );
            }
        });

        this.updateNodeValues();
    }

    markAsDirty() {
        this.mapModificationSource.next({ map: this });
        this._dirty = true;
    }

    markAsPristine() {
        this._dirty = false;
    }

    randomizeScores() {
        forEach(this.nodes, function (node: Node) {
            node.score = Math.round(
                Math.floor(Math.random() * (node.max - node.min + 1) + node.min)
            );
            // console.log(node.name, ' has a random sccore of ', node.score);
        });

        this.updateNodeValues();
    }

    toObject(): BoardDef {
        const graph = graphLib.json.write(this.graph);

        const obj = {
            id: this.id,
            name: this.name,
            description: this.description,
            weight: this.weight,
            order: this.order,
            totalScore: this.totalScore,
            scoreLabel: this.scoreLabel,
            nodeDefs: this.nodeDefs,
            edges: graph.edges,
            swimlanes: this.swimlanes,
            documents: this.documents,
            targetValue: this.targetValue,
            metrics: this.metrics,
            historicalSlices: this.historicalSlices,
            selectedSlices: this.selectedSlices,
            allSlices: this.allSlices,
            commitments: this.commitments,
            transferFunctionOptions: this.transferFunctionOptions,
            transferFunction: this.transferFunction,
            uri: this.uri,
            modifiedOn: new Date(),
            permissions: this.permissions,
            tags: this.tags,
            comparedSlices: this.comparedSlices,
            isSnippitized: this.isSnippitized,
        };

        return cloneDeep(obj);
    }

    initializeTags() {}

    initializeNodes() {
        const useDataFile = false;

        if (useDataFile) {
            let nodeNames = map(this.connectionData, (node) => {
                return node[0];
            });

            const nodeKey = "id";

            nodeNames = uniq(nodeNames);
            nodeNames.push("Dashboard");

            forEach(nodeNames, (name: string) => {
                const nodeObj = this.addNode(name);
                nodeObj.isGauge = name === "Dashboard" || nodeObj.isGauge;
                nodeObj.position.top = Math.random();
                nodeObj.position.left = Math.random();
            });

            if (!this.gaugeNode) {
                this.gaugeNode = find(this.nodes, { isGauge: true });
                this.gaugeNode.displayName = "Dashboard";
                this.gaugeNode.isGauge = true;
                this.gaugeNode.position.top = Math.random();
                this.gaugeNode.position.left = Math.random();
            }

            forEach(this.connectionData, (link) => {
                const source = this.getNodeByName(link[0]);
                const target = this.getNodeByName(link[2]);

                this.graph.setEdge(
                    { v: source[nodeKey], w: target[nodeKey] },
                    {
                        label: `${source.key} -- ${link[1]}x -> ${target.key}`,
                        func: this.transferFunction,
                    }
                );

                source.addLink(LinkOrigin.Output, target.id, <number>link[1]);
                target.addLink(LinkOrigin.Input, source.id, <number>link[1]);
            });

            // console.log('The graph is : ', graphLib.json.write(this.graph));
            // console.log('The graph components : ', graphLib.alg.components(this.graph));
            // console.log('The graph shortest path : ', graphLib.alg.dijkstraAll(this.graph));
            // console.log('The graph shortest path : ', graphLib.alg.floydWarshall(this.graph));
            // console.log('The graph topological sorting : ', graphLib.alg.topsort(this.graph));
        } else {
            forEach(this.nodeDefs, (node) => {
                const nodeObj = new Node(node);
                if (nodeObj.isGauge) {
                    this.gaugeNode = nodeObj;
                    this.gaugeNode.isGauge = true;
                }
                this.graph.setNode(node.id, nodeObj);
            });

            if (!this.gaugeNode) {
                this.gaugeNode = new Node();
                this.gaugeNode.name = "Dashboard";
                this.gaugeNode.isGauge = true;
                this.graph.setNode(this.gaugeNode.id, this.gaugeNode);
            }

            this.gaugeNode.displayName = this.scoreLabel;

            forEach(this.edges, (edge) => {
                edge.value.func = this.getCompatibleTransferFunction(
                    this.transferFunction
                );
                if (isEmpty(edge.value.impact)) {
                    edge.value.impact = [0.5, 1.5];
                }
                this.graph.setEdge(edge.v, edge.w, edge.value);
            });
            this.refreshAllLinks();
        }

        // Initialize the connections
        // forEach(this.nodes, function (node) {
        //     nodes[link[0]].addLink(link);
        //     // jsplumbService.addConnections();
        // });

        // TODO unsubscribe on component destroy
        // Observable
        //     .concatAll(nodeScoreChangeObservers)
        //     .subscribe(() => {
        //         this.updateNodeValues();
        //     });
        this.resetNodePercentChange();
    }

    refreshAllLinks() {
        forEach(this.nodes, (node) => this.setNodeLinks(node));
    }

    updateCurrency(baseCurrencyCode: string): any {
        this.metrics.forEach((metric) => {
            if (metric.isCurrency) {
                metric.displayUnit = baseCurrencyCode;
            }
        });
    }

    private setNodeLinks(node: Node) {
        const inputs = {},
            outputs = {};

        forEach(
            this.graph.inEdges(node.id),
            (edgeRef) => (inputs[edgeRef.v] = this.graph.edge(edgeRef).func)
        );
        forEach(
            this.graph.outEdges(node.id),
            (edgeRef) => (outputs[edgeRef.w] = this.graph.edge(edgeRef).func)
        );

        node.inputs = inputs;
        node.outputs = outputs;

        // console.log('Node connections ', node.name, node.inputs, node.outputs);
    }

    private logisticFn(val: number, options: any): number {
        // for 0< x < 1 returns a value between [-1 and 1]
        const range = (options.max - options.min) / 2,
            weight = 1,
            max = 2 / (1 + Math.exp(-options.steepness * (1 - 0.5))) - 1,
            scale = 1 / max;

        // console.log(`T(${round(val*100,0)}) = `,( 2 / ( 1 + Math.exp( -options.steepness * ( val - 0.5 ) ) ) - 1 ) * range );

        return <number>(
            round(
                weight *
                    (2 / (1 + Math.exp(-options.steepness * (val - 0.5))) - 1) *
                    range *
                    scale,
                3
            )
        );
    }

    private easeFn(val: number, tfName, impact: number[]): number {
        const y0 = 0,
            y1 = 100,
            xRange = 100,
            impactMin = impact[0],
            impactMax = impact[1],
            yRange =
                impactMax === impactMin ? 100 : (impactMax - impactMin) * 100,
            x =
                tfName === TransferFunctionName.Linear
                    ? val + impactMin
                    : (impactMin * 100 +
                          Ease[tfName](val * 100, y0, y1, xRange)) /
                      yRange;

        return x;
    }

    private linearFn(val: number, options: any): number {
        return val;
    }

    private percentStr(val) {
        return `${round(val * 100, 1)}%`;
    }

    private calcNodeValue(node: Node) {
        let newNormalizedScore = 0;
        const oldNormalizedScore = node.normalizedScore;
        const inputCount = values(node.inputs).length;
        let contextValue = 0;
        let contextValueStr = `Initial driver score: ${this.percentStr(
            node.normalizedScore
        )}<hr>
                                Context Impact Calculations &nbsp;(Input Node %change * Connection Weight): <br><br>`;

        if (this.isWhatIfMode && inputCount > 0) {
            // Calculate the sum of the weighted impacts (context value)

            forEach(node.inputs, (val, sourceId) => {
                const edge = this.graph.edge(sourceId, node.id);
                // this.board.graph.edge(id, node.id).label.transferFunction
                const weight = isNaN(edge.weight) ? 1 : edge.weight,
                    contribution = weight - 1,
                    // contribution = weight / totalWeights,
                    source = this.nodes[sourceId],
                    name = source.displayName,
                    // impact = this.easeFn(source.normalizedScore, edge.func, edge.impact),
                    impact = source.percentageChange,
                    connectionValue = impact * contribution;

                contextValue += connectionValue;

                contextValueStr += `${name}: ${this.percentStr(
                    source.normalizedScore
                )}  -->
                                    ${this.percentStr(
                                        impact
                                    )} * ${this.percentStr(
                    contribution
                )} = ${this.percentStr(connectionValue)}<br>`;
            });

            newNormalizedScore = clamp(
                node.normalizedScore + contextValue,
                0,
                1
            );

            if (node.percentageChange === 0) {
                node.percentageChange =
                    newNormalizedScore - node.normalizedScore;
                // console.log('Percentage change for connected node ', node.name, ' = ', node.percentageChange);
            }

            // node.normalizedValue = node.normalizedScore + contextValue;
            // node.percentageChange = newNormalizedScore - node.normalizedScore;

            node.previousScore = node.score;

            node.score = node.absoluteScore(newNormalizedScore);

            // node.score = node.absoluteScore(newNormalizedScore);
        }
        const impactValence = contextValue < 0 ? "-" : "+";

        contextValueStr += `<hr>Total Context Impact = ${this.percentStr(
            contextValue
        )}`;
        contextValueStr += `<br>Value = ${this.percentStr(
            oldNormalizedScore
        )} &nbsp;${impactValence} &nbsp;  ${this.percentStr(
            Math.abs(contextValue)
        )}
                            = ${this.percentStr(node.normalizedScore)}`;

        node.calculationDetails = contextValueStr;
        return node.normalizedValue;
    }

    addMetric(
        name: string,
        displayUnit: string,
        isCurrency: boolean,
        id?: string
    ) {
        const metric: ProcessMetric = {
            id: isEmpty(id) ? FuseUtils.generateGUID() : id,
            name: name,
            displayUnit: displayUnit,
            isCurrency: isCurrency,
        };
        this.metrics.push(metric);

        forEach(this.historicalValues, (historicValue) => {
            historicValue.metrics[metric.id] = {
                value: 0,
                target: 0,
            };
        });

        this.historicalSlices.forEach((slice) => {
            slice.metrics[metric.id] = {
                value: 0,
                target: 0,
            };
        });

        this.markAsDirty();

        return metric;
    }

    removeMetric(metric: ProcessMetric) {
        remove(this.metrics, { id: metric.id });

        forEach(this.historicalValues, (historicValue) => {
            delete historicValue.metrics[metric.id];
        });

        this.historicalSlices.forEach((slice) => {
            delete slice.metrics[metric.id];
        });

        this.markAsDirty();
    }

    hasMetric(metric: ProcessMetric): boolean {
        return this.metrics.some((m) => m.id === metric.id);
    }

    cacheNodePositions() {
        const container = document.getElementById(this.container);
        if (container) {
            const height = container.offsetHeight;
            const width = container.offsetWidth;

            this.nodeDefs = {};
            forEach(this.nodes, (node) => {
                const elem = document.getElementById(node.id);

                node.position = { top: elem.offsetTop, left: elem.offsetLeft };

                const nodeDef = node.toObject();
                this.nodeDefs[node.id] = nodeDef;

                if (elem) {
                    // save the position as a percentage of board size
                    nodeDef.position.top = nodeDef.position.top / height;
                    nodeDef.position.left = nodeDef.position.left / width;
                }
            });
        }
    }

    importHistoricalSliceData(
        data: ImportedMapData,
        shouldAppendData: boolean
    ): void {
        // add tags, if any
        if (data.tags) {
            data.tags.forEach((tag) => {
                if (!this.getTagById(tag.id)) {
                    this.tags.push(tag);
                }
            });
        }

        // add slices, if any
        if (data.slices) {
            data.slices.forEach((slice) => {
                if (!this.allSlices.some((s) => s.id === slice.id)) {
                    this.allSlices.push(slice);
                    // if we are importing metrics, then we need to assgn default data for the slices
                    if (!data.nodes) {
                        this.assignSliceData(slice);
                    }
                }
            });
        }

        // add new metrics, if any
        _forEach(data.metrics, (metric) => {
            if (!this.hasMetric(metric)) {
                this.addMetric(
                    metric.name,
                    metric.displayUnit,
                    metric.isCurrency,
                    metric.id
                );
            }
        });

        // import data
        if (shouldAppendData) {
            data.historicalSliceData.forEach((sliceData) => {
                const oldPeriodValue = _first(
                    remove(
                        this.historicalSlices,
                        (ov) =>
                            ov.period === sliceData.period &&
                            ov.sliceId === sliceData.sliceId
                    )
                );
                const newPeriodValue = this.copyHistoricalSliceData(
                    sliceData,
                    oldPeriodValue
                );
                this.historicalSlices.push(newPeriodValue);

                // iterate thru other slices and create slice data for the period, if missing
                this.allSlices.forEach((slice) => {
                    const sliceDataForPeriod = this.getSliceData(
                        slice.id,
                        sliceData.period
                    );
                    if (sliceDataForPeriod) {
                        remove(this.historicalSlices, sliceDataForPeriod);
                        this.historicalSlices.push(
                            this.copyHistoricalSliceData(sliceDataForPeriod)
                        );
                    } else {
                        this.historicalSlices.push(
                            this.createSliceData(
                                slice,
                                sliceData.period,
                                sliceData.periodName
                            )
                        );
                    }
                });
            });
        } else {
            const oldValues = this.historicalSlices;
            this.historicalSlices = [];
            data.historicalSliceData.forEach((sliceData) => {
                const oldPeriodValue = oldValues.find(
                    (ov) =>
                        ov.period === sliceData.period &&
                        ov.sliceId === sliceData.sliceId
                );
                this.historicalSlices.push(
                    this.copyHistoricalSliceData(sliceData, oldPeriodValue)
                );
            });

            if (data.slices) {
                const slices = [...this.allSlices];
                // if the slice does not exist in imported set, remove the slice
                slices.forEach((slice) => {
                    if (!data.slices.some((s) => s.id === slice.id)) {
                        this.deleteSlice(slice);
                    }
                });
            }
        }

        this.consolidateData();
        this.syncHistoricValues();
        this.markAsDirty();
    }

    // TODO delete after demo
    private initializeHistoricalValues() {
        const thisYear = moment().year();
        if (isEmpty(this.historicalValues)) {
            this.historicalValues = [];
        }

        const numHistoricalPeriods = this.numHistoricPeriods || 4;

        for (let i = 0; i < numHistoricalPeriods; i++) {
            let historicalValue: HistoricalValue = this.historicalValues[i];
            if (isEmpty(historicalValue)) {
                historicalValue = {
                    period: i,
                    periodName: `Q${i + 1} ${thisYear}`,
                    metrics: {},
                    driverScores: {},
                    totalScore: 0,
                };
                this.historicalValues[i] = historicalValue;
            }

            // backward compatibility
            if (!historicalValue.periodName) {
                historicalValue.periodName = `Q${i + 1} ${thisYear}`;
            }

            forEach(this.nodes, (node: Node) => {
                this.updateHistoricalValues(node, i);
            });

            forEach(this.metrics, (metric) => {
                if (!isObject(historicalValue.metrics[metric.id])) {
                    const value: number = <any>(
                        (isNumber(historicalValue.metrics[metric.id])
                            ? historicalValue.metrics[metric.id]
                            : 0)
                    );
                    historicalValue.metrics[metric.id] = {
                        value: value,
                        target: 0,
                    };
                }
            });
        }
    }

    private historicDriverScoreForNode(
        node: Node,
        period: number
    ): { score: number; questions: { [id: string]: Question } } {
        let historicValue = this.historicalValues[period];
        if (!historicValue) {
            historicValue = historicValue = {
                driverScores: {},
                period: period,
                periodName: "",
                metrics: {},
                totalScore: 0,
            };
            this.historicalValues[period] = historicValue;
        }

        let valueObj = historicValue.driverScores[node.id];
        if (!valueObj) {
            valueObj = node.createDefaultHistoricValue();
        }
        return valueObj;
    }

    private updateHistoricalValues(node: Node, period = this.currentPeriod) {
        let valueObj = this.historicDriverScoreForNode(node, period);
        let sliceNodeData: {
            qScores: { [qId: string]: number };
            score: number;
        } = null;
        if (this.canEditSlice) {
            const sliceData = this.getSliceData(
                this.selectedSlices[0].id,
                period
            );
            if (sliceData) {
                if (sliceData.driverScores[node.id]) {
                    sliceNodeData = sliceData.driverScores[node.id];
                } else {
                    sliceNodeData = {
                        qScores: {},
                        score: node.normalizedScore,
                    };
                    sliceData.driverScores[node.id] = sliceNodeData;
                }
            }
        }

        if (isUndefined(valueObj)) {
            valueObj = {
                questions: {},
                score: 0,
            };
            this.historicalValues[period].driverScores[node.id] = valueObj;
        } else {
            valueObj.score = node.normalizedScore;
        }

        if (sliceNodeData) {
            sliceNodeData.score = node.normalizedScore;
        }

        forEach(node.questionnaire.questions, (question: Question) => {
            if (!valueObj.questions[question.id]) {
                const qCopy = cloneDeep(question);
                qCopy.score = qCopy.min;
                valueObj.questions[question.id] = qCopy;
            }

            const qObj = valueObj.questions[question.id];
            qObj.score = clamp(qObj.score, qObj.min, qObj.max);

            if (sliceNodeData) {
                sliceNodeData.qScores[question.id] = qObj.score;
            }
        });

        const qIdsInHistoric = keys(valueObj.questions);
        qIdsInHistoric.forEach((qId) => {
            if (!node.questionnaire.questions.some((q) => q.id === qId)) {
                delete valueObj.questions[qId];
                if (sliceNodeData) {
                    delete sliceNodeData.qScores[qId];
                }
            }
        });
    }

    getMetricValue(metricId: string, period: number): MetricValue {
        const metricValues =
            this.historicalValues.length > period
                ? this.historicalValues[period].metrics
                : null;
        let value = {
            value: 0,
            target: 0,
        };

        if (metricValues && metricValues.hasOwnProperty(metricId)) {
            value = metricValues[metricId];
        }

        return value;
    }

    setMetricValue(value: MetricValue, metricId: string, period: number) {
        if (this.canEditSlice) {
            const sliceData = this.getSliceData(
                this.selectedSlices[0].id,
                period
            );
            if (sliceData && sliceData.metrics) {
                sliceData.metrics[metricId] = cloneDeep(value);
                this.markAsDirty();
            } else {
                console.error(
                    "Trying to set metric value for invalid period ",
                    metricId,
                    value
                );
            }
        }
    }

    // updateHistoricQuestion(question: Question, node: Node) {
    //     const driverObj = this.historicalValues[this.currentPeriod].driverScores[node.id];
    //     if (driverObj) {
    //         driverObj.questions[question.id] = question;
    //     } else {
    //         console.error('Trying to update question of unknown driver ', question, node);
    //     }
    // }
    getQuestionScore(questionId: string, driverId: string, period: number) {
        let score;
        const historicValue = this.historicalValues[period];
        if (
            historicValue &&
            historicValue.driverScores.hasOwnProperty(driverId)
        ) {
            const question =
                historicValue.driverScores[driverId].questions[questionId];
            if (question) {
                score = question.score;
            }
        }

        if (isUndefined(score)) {
            console.error(
                "Requesting score for an invalid question ",
                questionId,
                driverId,
                period
            );
            score = 0;
        }
        return score;
    }

    getNodeScore(driverId: string, period: number) {
        let score;
        const historicValue = this.historicalValues[period];
        if (
            historicValue &&
            historicValue.driverScores.hasOwnProperty(driverId)
        ) {
            score = historicValue.driverScores[driverId].score;
        }

        if (isUndefined(score)) {
            console.error(
                "Requesting score for an invalid node ",
                driverId,
                period
            );
            score = 0;
        }
        return score;
    }

    getNodeSliceScores(driverId: string, period: number) {
        const slices = this.historicalSlices.filter((s) => s.period === period);
        return slices.map((slice) => {
            const sliceName = this.getSliceLabel(
                this.getSliceById(slice.sliceId)
            );
            return {
                id: slice.sliceId,
                score: slice.driverScores[driverId].score,
                label: sliceName,
            };
        });
    }

    getCriticalNodes(threshold: number) {
        return _filter(
            this.nodes,
            (node) => Math.round(node.normalizedScore * 100) <= threshold * 100
        );
    }

    getSliceLabel(
        descriptor: SliceDescriptor,
        separator: string = " | "
    ): string {
        const names = [
            Dimensions.Region,
            Dimensions.Function,
            Dimensions.Level,
        ].map((dim) => {
            return find(this.tags, { id: descriptor[dim] })["name"];
        });
        return names.join(separator);
    }

    private getCompatibleTransferFunction(
        transferFunction: TransferFunctionName
    ) {
        if (transferFunction.startsWith("easeInOut")) {
            return TransferFunctionName.EaseInOutMedium;
        } else if (transferFunction.startsWith("easeIn")) {
            return TransferFunctionName.EaseInMedium;
        } else if (transferFunction.startsWith("easeOut")) {
            return TransferFunctionName.EaseOutMedium;
        } else if (transferFunction === TransferFunctionName.Linear) {
            return transferFunction;
        }
        return this.transferFunction; // TransferFunctionName.Linear;
    }

    private copyHistoricalSliceData(
        sliceData: DeepPartial<HistoricalSliceData>,
        oldPeriodValue?: HistoricalSliceData
    ): HistoricalSliceData {
        const copy = cloneDeep(sliceData) as HistoricalSliceData;
        if (!copy.metrics) {
            copy.metrics = {};
        }
        if (!copy.driverScores) {
            copy.driverScores = {};
        }

        // ensure we have the valid metric values
        this.metrics.forEach((metric) => {
            if (!copy.metrics[metric.id]) {
                const oldPeriodMetricValue = get(
                    oldPeriodValue,
                    `metrics.${metric.id}`,
                    { value: 0, target: 0 } as MetricValue
                );
                copy.metrics[metric.id] = cloneDeep(oldPeriodMetricValue);
            }
        });

        // ensure we have the valid driver data
        forEach(this.nodes, (node: Node) => {
            if (!copy.driverScores[node.id]) {
                const oldPeriodDriverValue = get(
                    oldPeriodValue,
                    `driverScores.${node.id}`,
                    { score: 0, qScores: {} }
                );
                copy.driverScores[node.id] = cloneDeep(oldPeriodDriverValue);
            }

            forEach(node.questionnaire.questions, (question) => {
                if (
                    isUndefined(copy.driverScores[node.id].qScores[question.id])
                ) {
                    const defaultValue =
                        question.worstCaseValue === "max"
                            ? question.max
                            : question.min;
                    const score = get(
                        oldPeriodValue,
                        `driverScores.${node.id}.qScores.${question.id}`,
                        defaultValue
                    );
                    copy.driverScores[node.id].qScores[question.id] = score;
                }
            });
        });
        // copy.totalScore = 0;
        return copy;
    }

    deleteQuestion(question: any, node: Node) {
        node.removeQuestion(question);
        this.updateHistoricalValues(node);
        this.markAsDirty();
    }

    private syncHistoricValues() {
        const tempCurrentPeriod = this._currentPeriod;
        const selectedSlices = this.selectedSlices.map((slice) => slice.id);
        for (let i = 0; i < this.numHistoricPeriods; i++) {
            this._currentPeriod = i;
            this.allSlices.forEach((slice) => {
                this.setSelectedSlices([slice.id]);
                this.applyHistoricNodeValues();
                this.rebase();
            });
        }

        this._currentPeriod = tempCurrentPeriod;
        this.setSelectedSlices(selectedSlices);
        this.applyHistoricNodeValues();
        this.rebase();
    }

    addDimensionTag(type: Dimensions, label: string) {
        this.tags.push({
            type: type,
            name: label,
            id: FuseUtils.generateGUID(),
        });
        this.markAsDirty();
    }

    renameDimensionTag(tag: DimensionTag, name: string) {
        const boardTag = this.tags.find((t) => t.id === tag.id);
        if (boardTag) {
            boardTag.name = name;
            this.markAsDirty();
        } else {
            console.warn("No tag found for ", tag);
        }
    }

    setSelectedSlices(sliceIds: string[]) {
        this.selectedSlices = this.allSlices.filter((slice) =>
            sliceIds.includes(slice.id)
        );
        if (!isEmpty(this.selectedSlices)) {
            this.consolidateData();
        }
    }

    getSliceById(sliceId: string): SliceDescriptor {
        return this.allSlices.find((slice) => slice.id === sliceId);
    }

    getSliceData(sliceId: string, period: number): HistoricalSliceData {
        return find(this.historicalSlices, {
            sliceId: sliceId,
            period: period,
        });
    }

    getSliceScore(sliceId: string): { score: number; color: string } {
        const sliceScores = this.getNodeSliceScores(
            this.mapScoreNode.id,
            this.currentPeriod
        );
        const data = find(this.historicalSlices, {
            sliceId: sliceId,
            period: this.currentPeriod,
        });
        const scoreNode = this.mapScoreNode;

        let totalScore = 0;

        const totalWeights = reduce(
            keys(scoreNode.inputs),
            (sum, sourceId) => {
                const inputNode = data.driverScores[sourceId];
                const edge = this.graph.edge(sourceId, this.gaugeNode.id);
                const weight = isNaN(edge.weight) ? 1 : edge.weight;
                totalScore += inputNode.score * weight;
                return sum + weight;
            },
            0
        );

        const sliceScore =
            totalWeights === 0
                ? 0
                : round((100 * totalScore) / totalWeights, 0);

        return {
            score: sliceScore,
            color: this.palette[clamp(sliceScore, 0, 99)],
        };
    }

    private initPeriodicTagData() {
        this.historicalSlices = [];
        if (isEmpty(this.tags)) {
            this.addDimensionTag(Dimensions.Region, "All");
            this.addDimensionTag(Dimensions.Function, "All");
            this.addDimensionTag(Dimensions.Level, "All");
        }

        const regionTag = _first(this.getTagsByType(Dimensions.Region));
        const functionTag = _first(this.getTagsByType(Dimensions.Function));
        const levelTag = _first(this.getTagsByType(Dimensions.Level));

        const defaultSlice: SliceDescriptor = {
            id: FuseUtils.generateGUID(),
            [Dimensions.Region]: regionTag.id,
            [Dimensions.Function]: functionTag.id,
            [Dimensions.Level]: levelTag.id,
        };

        this.allSlices = [defaultSlice];
        this.selectedSlices = [defaultSlice];

        this.allSlices.forEach((slice) => {
            this.historicalValues.forEach((hv) => {
                const historicalSliceData = {
                    period: hv.period,
                    periodName: hv.periodName,
                    sliceId: slice.id,
                    metrics: {},
                    driverScores: {},
                } as HistoricalSliceData;

                forEach(this.nodes, (node: Node) => {
                    const historicalNodeRef = get(
                        hv,
                        `driverScores.${node.id}`,
                        node.createDefaultHistoricValue()
                    );
                    historicalSliceData.driverScores[node.id] = {
                        qScores: {},
                        score: historicalNodeRef.score,
                    };

                    forEach(historicalNodeRef.questions, (q) => {
                        historicalSliceData.driverScores[node.id].qScores[
                            q.id
                        ] = q.score;
                    });
                });

                historicalSliceData.metrics = cloneDeep(hv.metrics);
                this.historicalSlices.push(historicalSliceData);
            });
        });
    }

    private createSliceData(
        slice: SliceDescriptor,
        period: number,
        periodName: string
    ) {
        const historicalSliceData = {
            period: period,
            periodName: periodName,
            sliceId: slice.id,
            metrics: {},
            driverScores: {},
        } as HistoricalSliceData;

        forEach(this.nodes, (node: Node) => {
            historicalSliceData.driverScores[node.id] = {
                qScores: {},
                score: 0,
            };

            forEach(node.questionnaire.questions, (q) => {
                const score = q.worstCaseValue === "min" ? q.min : q.max;
                historicalSliceData.driverScores[node.id].qScores[q.id] = score;
            });
        });

        this.metrics.forEach((metric) => {
            historicalSliceData.metrics[metric.id] = {
                target: 0,
                value: 0,
            };
        });
        return historicalSliceData;
    }

    private consolidateData() {
        this.historicalValues = this.getConsolidatedData(this.selectedSlices);
        this.updateNodeValues();
        this.applyHistoricNodeValues();
        this.rebase();
    }

    private sanitizeHistoricalSlices() {
        const slicesById = groupBy(this.historicalSlices, "sliceId");
        let sanitzedSlices = [];
        forEach(slicesById, (slices, sliceId) => {
            const sortedSlices = sortBy(slices, "period");
            const processed = map(sortedSlices, (sliceData, index) => {
                return {
                    ...sliceData,
                    period: index,
                };
            });
            sanitzedSlices = [...sanitzedSlices, ...processed];
        });

        this.historicalSlices = sanitzedSlices;
    }

    getConsolidatedData(slices: SliceDescriptor[]): HistoricalValue[] {
        const numHistoricalPeriods = this.numHistoricPeriods || 4;
        // reset historical values
        const historicalValues = [];

        // iterate over each selected tag
        slices.forEach((slice, sliceIndex) => {
            const tagSizeForAvgCalc = sliceIndex + 1;

            // start - for each historic period
            for (let i = 0; i < numHistoricalPeriods; i++) {
                const sliceData = this.historicalSlices.find(
                    (hs) => hs.sliceId === slice.id && hs.period === i
                );

                let historicalValue: HistoricalValue = historicalValues[i];
                if (isEmpty(historicalValue)) {
                    historicalValue = {
                        period: sliceData.period,
                        periodName: sliceData.periodName,
                        metrics: {},
                        driverScores: {},
                        totalScore: 0,
                    } as HistoricalValue;
                    historicalValues[i] = historicalValue;
                }

                // start - for each node per historic period
                forEach(this.nodes, (node: Node) => {
                    if (!node.isGauge) {
                        let historicNodeData =
                            historicalValue.driverScores[node.id];
                        const driverDataForTag = get(
                            sliceData,
                            ["driverScores", node.id],
                            { score: 0, qScores: {} }
                        );
                        driverDataForTag.score = get(
                            driverDataForTag,
                            "score",
                            0
                        );

                        if (isUndefined(historicNodeData)) {
                            historicNodeData = {
                                questions: {},
                                score: driverDataForTag.score,
                            };
                            historicalValue.driverScores[node.id] =
                                historicNodeData;
                        } else {
                            // sum the average node score
                            historicNodeData.score = this.getIncrementalAverage(
                                historicNodeData.score,
                                driverDataForTag.score,
                                tagSizeForAvgCalc
                            );
                        }

                        // for each question in the node
                        forEach(
                            node.questionnaire.questions,
                            (question: Question) => {
                                const qScoreForTag =
                                    driverDataForTag.qScores[question.id];
                                const clampedScore = isUndefined(qScoreForTag)
                                    ? question.min
                                    : clamp(
                                          qScoreForTag,
                                          question.min,
                                          question.max
                                      );

                                let historicQObj =
                                    historicNodeData.questions[question.id];

                                if (!historicQObj) {
                                    historicQObj = cloneDeep(question);
                                    historicQObj.score = clampedScore;
                                    historicNodeData.questions[question.id] =
                                        historicQObj;
                                } else {
                                    // sum the average question scores
                                    historicQObj.score =
                                        this.getIncrementalAverage(
                                            historicQObj.score,
                                            clampedScore,
                                            tagSizeForAvgCalc
                                        );
                                }
                            }
                        );
                    }
                });
                // end - for each node per historic period

                // -> start - for each metric per historic period
                forEach(this.metrics, (metric) => {
                    const metricDataForTag = sliceData.metrics[metric.id];
                    if (!isObject(historicalValue.metrics[metric.id])) {
                        historicalValue.metrics[metric.id] = {
                            value: metricDataForTag.value,
                            target: metricDataForTag.target,
                        };
                    } else {
                        historicalValue.metrics[metric.id].value =
                            this.getIncrementalAverage(
                                historicalValue.metrics[metric.id].value,
                                metricDataForTag.value,
                                tagSizeForAvgCalc
                            );
                    }
                });
                // end - for each metric per historic period
            }
            // end - for each historic period
        });
        return historicalValues;
    }

    private getIncrementalAverage(
        oldAvg: number,
        newValue: number,
        newSize: number
    ) {
        // https://math.stackexchange.com/a/957376
        return oldAvg + (newValue - oldAvg) / newSize;
    }

    getTagsByType(type: Dimensions) {
        return this.tags.filter((tag) => tag.type === type);
    }

    get canEditSlice(): boolean {
        return this.selectedSlices.length === 1;
    }

    getTagById(tagId: string): DimensionTag {
        return this.tags.find((tag) => tag.id === tagId);
    }

    updateSelectedSliceTag(tag: DimensionTag) {
        if (this.canEditSlice) {
            this.selectedSlices[0][tag.type] = tag.id;
        }
        this.markAsDirty();
    }

    addSlice(
        slice: SliceDescriptor,
        copyFrom: SliceDescriptor = null,
        setAsActive = true
    ) {
        this.allSlices.push(slice);
        this.assignSliceData(slice, copyFrom);
        if (setAsActive) {
            this.setSelectedSlices([slice.id]);
        }
        this.markAsDirty();
    }

    deleteSlice(slice: SliceDescriptor) {
        remove(this.allSlices, (s) => s.id === slice.id);
        remove(
            this.historicalSlices,
            (sliceData) => sliceData.sliceId === slice.id
        );
        remove(this.selectedSlices, (s) => s.id === slice.id);
        if (this.selectedSlices.length === 0) {
            this.selectedSlices = [_first(this.allSlices)];
        }
        this.markAsDirty();
    }

    private assignSliceData(
        targetSlice: SliceDescriptor,
        srcSlice?: SliceDescriptor
    ) {
        this.historicalValues.forEach((hv) => {
            const sourceSliceDataForPeriod = srcSlice
                ? this.historicalSlices.find(
                      (hs) =>
                          hs.sliceId === srcSlice.id && hs.period === hv.period
                  )
                : null;

            const historicalSliceData = this.createSliceData(
                targetSlice,
                hv.period,
                hv.periodName
            );

            if (sourceSliceDataForPeriod) {
                historicalSliceData.driverScores = cloneDeep(
                    sourceSliceDataForPeriod.driverScores
                );
                historicalSliceData.metrics = cloneDeep(
                    sourceSliceDataForPeriod.metrics
                );
            }
            this.historicalSlices.push(historicalSliceData);
        });
    }

    findTag(dimension: Dimensions, name: string): DimensionTag {
        return this.tags.find(
            (tag) => tag.name === name && tag.type === dimension
        );
    }

    setComparedSlices(comparedSlices: { sliceIds: string[]; name: string }[]) {
        this.comparedSlices = comparedSlices;
    }

    get selectedSlicesName(): string {
        const names = [];
        this.selectedSlices.map((slice) => {
            const tags = values(omit(slice, "id")).map(
                (tagId) => this.getTagById(tagId).name
            );
            names.push(tags.join(" | "));
        });
        return names.length > 1 ? names.join(", ") : names[0];
    }
}
