import {NgModule} from '@angular/core';

import {TawkDirective} from './directives/tawk.directive';

@NgModule({
    imports: [
    ],
    exports: [TawkDirective],
    declarations: [TawkDirective],
})
export class SharedModule {
}
