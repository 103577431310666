<div mat-dialog-content>
<!--    <p class="text-center font-smaller">Use the slider to zoom in or out. Drag the image to center it.</p>-->
    <wi-croppie *ngIf="data.file"
                [imageFile]="data.file"
                [isCircle]="data.isCircle"
                (result)="newImageResultFromCroppie($event)">
    </wi-croppie>

</div>
<div mat-dialog-actions>
    <button mat-button color="accent" class="mr-16" (click)="dialogRef.close(croppedImage)">SAVE</button>
    <button mat-button (click)="dialogRef.close()">CANCEL</button>
</div>
