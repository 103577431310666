import {find, map, upperFirst, words} from 'lodash-es';

// http://easings.net/
export enum TransferFunctionName {
    'Linear' = 'linear',
    // 'EaseInWeak' = 'easeInSine',
    'EaseInMedium' = 'easeInCubic',
    // 'EaseInStrong' = 'easeInQuint',
    // 'EaseOutWeak' = 'easeOutSine',
    'EaseOutMedium' = 'easeOutCubic',
    // 'EaseOutStrong' = 'easeOutQuint',
    // 'EaseInOutWeak' = 'easeInOutSine',
    'EaseInOutMedium' = 'easeInOutCubic',
    // 'EaseInOutStrong' = 'easeInOutQuint',
}

export class TransferFunctions {

    static get fullDisplayNames(): string[] {
        return map(TransferFunctionName, (val, key) => words(upperFirst(key)).join(' '));
    }

    static getFullDisplayName(functionName: TransferFunctionName): string {
        let label;
        find(TransferFunctionName, (val, key) => {
            if (val === functionName) {
                label = words(upperFirst(key)).join(' ').replace(' Medium', '');
                return true;
            }
            return false;
        });
        return label;
    }

    static getShortDisplayName(functionName: TransferFunctionName): string[] {
        let label;
        switch (functionName) {
            case TransferFunctionName.Linear:
                label = 'lin';
                break;
            // case TransferFunctionName.EaseInWeak:
            case TransferFunctionName.EaseInMedium:
            // case TransferFunctionName.EaseInStrong:
                label = 'in';
                break;
            // case TransferFunctionName.EaseOutWeak:
            case TransferFunctionName.EaseOutMedium:
            // case TransferFunctionName.EaseOutStrong:
                label = 'out';
                break;
            // case TransferFunctionName.EaseInOutWeak:
            case TransferFunctionName.EaseInOutMedium:
            // case TransferFunctionName.EaseInOutStrong:
                label = 'inout';
                break;
            default:
                label = functionName;
                break;
        }
        return label;
    }
}
