import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {AngularFirestore} from '@angular/fire/compat/firestore';

import {RouterState} from '@ngxs/router-plugin';
import {Action, Selector, State, StateContext} from '@ngxs/store';

import produce from 'immer';

import {cloneDeep, find, forEach, isEmpty, reject, values} from 'lodash-es';

import {from, of} from 'rxjs';
import {switchMap, take, tap} from 'rxjs/operators';

import {AuthService, ProjectDef, UserRole} from '@cultursys/core';
import {StringUtils} from '../core/string.utils';
import {Board, BoardDef} from '../core/board.model';
import {
    AddMap,
    AddProject,
    CloneMap,
    CloneProject,
    DeleteMap,
    DeleteProject,
    GetProjectsSuccess,
    ImportProject,
    SetActiveMapId,
    SetActiveProjectId,
    SetCurrentPeriod, SetProjectName,
    SetProjects,
    SetSelectedNodeId,
    ToggleMapEditorVisibility,
    UpdateActiveMapId,
    UpdateActiveProjectId
} from './app.actions';
import {UpdateBucket} from '../main/apps/file-manager/store/file-manager.actions';
import {ErrorRoutingService} from '../core/error-routing.service';
import {Inject, Injectable} from '@angular/core';

export interface UIPreferences {
    showMapEditor: boolean;
}

export interface AppStateModel {
    projects: { [key: string]: ProjectDef };
    activeProjectId: string;
    activeMapId: string;
    activeNodeId: string;
    period: number;
    periodTitle: string;
    uiPreferences: UIPreferences;
}

@State({
    name: 'app',
    defaults: {
        project: null,
        activeProjectId: null,
        activeMapId: null,
        activeNodeId: null,
        period: 0,
        periodTitle: '',
        uiPreferences: {
            showMapEditor: false
        }
    }
})
@Injectable()
export class AppState {

    constructor(private auth: AuthService,
                private errorService: ErrorRoutingService,
                private router: Router,
                private afs: AngularFirestore,
                private errorRouting: ErrorRoutingService,
                @Inject('ProjectFactory') private projectFactory) {
    }

    @Selector([RouterState.state])
    static activeRoute(stateModel: AppStateModel, route: RouterStateSnapshot): ActivatedRouteSnapshot {
        let state: ActivatedRouteSnapshot = route.root;
        while (state.firstChild) {
            state = state.firstChild;
        }
        return state;
    }

    @Selector()
    static projects(stateModel: AppStateModel): Array<Partial<ProjectDef>> {
        return values(stateModel.projects);
    }

    @Selector()
    static activeProjectId(stateModel: AppStateModel): string {
        return stateModel.activeProjectId;
    }

    @Selector()
    static activeMapId(stateModel: AppStateModel): string {
        return stateModel.activeMapId;
    }

    @Selector()
    static activeNodeId(stateModel: AppStateModel): string {
        return stateModel.activeNodeId;
    }

    @Selector()
    static currentPeriod(stateModel: AppStateModel): number {
        return stateModel.period;
    }

    @Selector()
    static currentPeriodTitle(stateModel: AppStateModel): string {
        return stateModel.periodTitle;
    }

    @Selector()
    static uiPreferences(stateModel: AppStateModel): UIPreferences {
        return stateModel.uiPreferences;
    }

    @Action(GetProjectsSuccess)
    getProjectsSuccess(context: StateContext<AppStateModel>, action: GetProjectsSuccess) {
        const nextState = produce(context.getState(), draft => {
            draft.projects = {};
            forEach(action.projects, project => {
                draft.projects[project.id] = project;
            });
        });

        context.setState(nextState);

        const activeProject = find(action.projects, {id: this.auth.activeUser.activeProjectId}) || action.projects[0];
        return context.dispatch(new SetActiveProjectId(activeProject.id));
    }

    @Action(SetProjectName)
    setProjectName(context: StateContext<AppStateModel>, action: SetProjectName) {
        const nextState = produce(context.getState(), draft => {
            const project = draft.projects[action.id];
            if (project) {
                project.name = action.name;
            }
        });

        context.setState(nextState);
    }

    @Action(SetProjects)
    setProjects(context: StateContext<AppStateModel>, action: SetProjects) {
        if (isEmpty(action.projects)) {
            if (this.auth.activeUser.allow(UserRole.ProjectAdmin)) {
                return this.router.navigate(['apps', 'administrator', 'users']);
            } else {
                return this.errorRouting.showErrorPage({
                    message: 'Because you do not have any projects assigned to you yet'
                });
            }
        } else {
            this.auth.activeUser.projects = cloneDeep(action.projects);
            return this.auth
                .downloadUserProjects()
                .pipe(
                    switchMap(projects => {
                        const nextState = produce(context.getState(), draft => {
                            draft.projects = {};
                            forEach(projects, project => {
                                draft.projects[project.id] = project;
                            });
                        });
                        context.setState(nextState);

                        const activeProject = find(projects, project => project.id === this.auth.activeUser.activeProjectId) || projects[0];
                        if (activeProject.id !== nextState.activeProjectId) {
                            return context.dispatch(new SetActiveProjectId(activeProject.id));
                        } else {
                            return of({});
                        }
                    })
                );
        }
    }

    @Action(SetActiveProjectId)
    setActiveProjectId(context: StateContext<AppStateModel>, action: SetActiveProjectId) {
        const nextState = produce(context.getState(), draft => {
            draft.activeProjectId = action.projectId;
            draft.uiPreferences.showMapEditor = this.auth.activeUser.getPreference(action.projectId).showMapEditor;
        });

        const project = cloneDeep(nextState.projects[action.projectId]);
        this.auth.activeUser.setActiveProject(project);

        context.setState(nextState);

        return this.afs
            .collection<BoardDef>(`projects/${action.projectId}/maps`)
            .valueChanges()
            .pipe(
                take(1),
                switchMap(maps => {
                    if (isEmpty(maps)) {
                        this.errorService.showErrorPage({
                            message: 'Because there is no map ready for you yet'
                        });
                    } else {
                        // Remove CPR Map as no longer useful...if there are others maps too
                        let purgedMaps = reject(maps, {name: 'CPR'});
                        if (purgedMaps.length === 0 ) {
                            purgedMaps = maps;
                        }
                        this.auth.activeUser.activeProject.setMaps(purgedMaps);
                        const activeMapId = this.auth.activeUser.projects[this.auth.activeUser.activeProject.id].activeBoardId;
                        const activeMap = find(purgedMaps, {id: activeMapId}) || purgedMaps[0];
                        return context.dispatch([
                            new SetActiveMapId(activeMap.id),
                            new UpdateBucket(`project-documents/${this.auth.activeUser.activeProject.id}`)
                        ]);
                    }
                })
            );
    }

    @Action(UpdateActiveProjectId)
    updateActiveProjectId(context: StateContext<AppStateModel>, action: UpdateActiveProjectId) {
        return this.afs
            .collection<BoardDef>(`projects/${action.projectId}/maps`)
            .valueChanges()
            .pipe(
                take(1),
                tap(maps => {
                    if (isEmpty(maps)) {
                        this.errorService.showErrorPage({
                            message: 'Because there is no map ready for you yet'
                        });
                    } else {
                        const activeMapId = this.auth.activeUser.projects[action.projectId].activeBoardId;
                        const activeMap = find(maps, {id: activeMapId}) || maps[0];

                        const lastState = context.getState();
                        const project = cloneDeep(lastState.projects[action.projectId]);
                        this.auth.activeUser.setActiveProject(project);
                        this.auth.activeUser.activeProject.setMaps(maps);
                        this.auth.activeUser.activeProject.setActiveBoard(activeMap.id);

                        const nextState = produce(lastState, draft => {
                            draft.activeProjectId = action.projectId;
                            draft.activeMapId = activeMap.id;
                            const node = this.auth.activeUser.activeProject.activeBoard.getFirstNode();
                            draft.activeNodeId = node ? node.id : null;
                            draft.uiPreferences.showMapEditor = this.auth.activeUser.getPreference(project.id).showMapEditor;
                        });

                        context.setState(nextState);

                        return context.dispatch(new UpdateBucket(`project-documents/${this.auth.activeUser.activeProject.id}`));
                    }
                })
            );
    }

    @Action(SetActiveMapId)
    setActiveMapId(context: StateContext<AppStateModel>, action: SetActiveMapId) {
        const map = this.auth.activeUser.activeProject.findBoardById(action.mapId);

        const nextState = produce(context.getState(), draft => {
            draft.activeMapId = action.mapId;
            if (map.numHistoricPeriods <= draft.period) {
                draft.period = 0;
            }
            draft.periodTitle = map.historicalValueFor(draft.period).periodName;

            const node = map.getFirstNode();
            draft.activeNodeId = node ? node.id : null;
        });

        this.auth.activeUser.activeProject.setActiveBoard(action.mapId);
        context.setState(nextState);
    }

    @Action(UpdateActiveMapId)
    updateActiveMapId(context: StateContext<AppStateModel>, action: UpdateActiveMapId) {
        const map = this.auth.activeUser.activeProject.findBoardById(action.mapId);

        const nextState = produce(context.getState(), draft => {
            draft.activeMapId = action.mapId;
            if (map.numHistoricPeriods <= draft.period) {
                draft.period = 0;
            }
            draft.periodTitle = map.historicalValueFor(draft.period).periodName;

            const node = map.getFirstNode();
            draft.activeNodeId = node ? node.id : null;
        });

        this.auth.activeUser.activeProject.setActiveBoard(action.mapId);

        context.setState(nextState);
    }

    @Action(SetCurrentPeriod)
    setCurrentPeriod(context: StateContext<AppStateModel>, action: SetCurrentPeriod) {
        const nextState = produce(context.getState(), draft => {
            draft.period = action.period;
            draft.periodTitle = action.periodTitle;
        });

        this.auth.activeUser.activeProject.updateCurrentPeriod(action.period);
        context.setState(nextState);
    }

    @Action(SetSelectedNodeId)
    setSelectedNodeId(context: StateContext<AppStateModel>, action: SetSelectedNodeId) {
        const nextState = produce(context.getState(), draft => {
            draft.activeNodeId = action.nodeId;
        });
        context.setState(nextState);
    }

    @Action(ToggleMapEditorVisibility)
    toggleMapEditorVisibility(context: StateContext<AppStateModel>, action: ToggleMapEditorVisibility) {
        const nextState = produce(context.getState(), draft => {
            draft.uiPreferences.showMapEditor = !draft.uiPreferences.showMapEditor;
        });
        this.auth.activeUser.updatePreference(this.auth.activeUser.activeProject.id, 'showMapEditor', nextState.uiPreferences.showMapEditor);

        context.setState(nextState);
    }

    @Action(CloneProject)
    cloneProject(context: StateContext<AppStateModel>, action: CloneProject) {
        const project = cloneDeep(action.sourceProject);
        project.id = StringUtils.generateID();
        project.name += ' (Copy)';

        const promises = [
            this.afs.doc<ProjectDef>(`projects/${project.id}`).set(project),
            this.auth.activeUser.addProject(project)
        ];

        return from(Promise.all(promises))
            .pipe(
                switchMap(() => {
                    return this.afs
                        .collection<BoardDef>(`projects/${action.sourceProject.id}/maps`)
                        .valueChanges()
                        .pipe(take(1));
                }),
                switchMap(maps => {
                    const addMapPromises = [];
                    forEach(maps, map => {
                        map.id = StringUtils.generateID();
                        addMapPromises.push(this.afs.doc(`projects/${project.id}/maps/${map.id}`).set(map));
                    });
                    return from(Promise.all(addMapPromises));
                }),
                tap(() => {
                    const nextState = produce(context.getState(), draft => {
                        draft.projects[project.id] = project;
                    });
                    context.setState(nextState);
                })
            );
    }

    @Action(AddProject)
    addProject(context: StateContext<AppStateModel>, action: AddProject) {
        const project = action.project;

        const defaultMap = Board.boardDefaults;
        defaultMap.name = 'CPR';

        const promises = [
            this.afs.doc<ProjectDef>(`projects/${project.id}`).set(project),
            this.afs.doc<BoardDef>(`projects/${project.id}/maps/${defaultMap.id}`).set(defaultMap),
            this.auth.activeUser.addProject(project)
        ];

        return Promise
            .all(promises)
            .then(() => {
                const nextState = produce(context.getState(), draft => {
                    draft.projects[project.id] = project;
                });
                context.setState(nextState);
            });
    }

    @Action(ImportProject)
    importProject(context: StateContext<AppStateModel>, action: ImportProject) {
        const project = action.project;
        const projectRef = this.afs.collection(`projects`).ref.doc();
        project.id = projectRef.id;
        project.description = `Project uploaded on ${new Date().toUTCString()}. ${project.description}`;

        const projectData = this.projectFactory(project).toObject();
        return projectRef
            .set(projectData)
            .then(() => {
                const promises = [];
                const mapsCollections = this.afs.collection(`projects/${project.id}/maps`);
                forEach(action.maps, map => {
                    const mapRef = mapsCollections.ref.doc();
                    map.id = mapRef.id;

                    const mapData = new Board(map).toObject();

                    const promise = mapRef.set(mapData);
                    promises.push(promise);
                });

                return Promise.all(promises);
            })
            .then(() => {
                return this.auth.activeUser.addProject(project);
            })
            .then(() => {
                const nextState = produce(context.getState(), draft => {
                    draft.projects[project.id] = project;
                });
                context.setState(nextState);
            });
    }

    @Action(DeleteProject)
    deleteProject(context: StateContext<AppStateModel>, action: DeleteProject) {
        const state = context.getState();
        let newActiveProject;
        if (state.activeProjectId === action.project.id) {
            newActiveProject = find(state.projects, project => project.id !== action.project.id);
        }

        return this.deleteProjectEntry(action.project, state)
            .pipe(
                switchMap(() => {
                    if (newActiveProject) {
                        return context.dispatch(new UpdateActiveProjectId(newActiveProject.id));
                    } else {
                        return of(state);
                    }
                }),
                tap(() => {
                    const nextState = produce(context.getState(), draft => {
                        delete draft.projects[action.project.id];
                    });
                    context.setState(nextState);
                })
            );
    }

    @Action(AddMap)
    addMap(context: StateContext<AppStateModel>, action: AddMap) {
        const newMap = Board.boardDefaults;

        return from(this.auth.activeUser.activeProject.addBoard(newMap))
            .pipe(switchMap(map => {
                return context.dispatch(new UpdateActiveMapId(map.id));
            }));
    }

    @Action(CloneMap)
    cloneMap(context: StateContext<AppStateModel>, action: CloneMap) {
        return from(this.auth.activeUser.activeProject.cloneBoard(action.map))
            .pipe(switchMap(map => {
                return context.dispatch(new UpdateActiveMapId(map.id));
            }));
    }

    @Action(DeleteMap)
    deleteMap(context: StateContext<AppStateModel>, action: DeleteMap) {
        const state = context.getState();

        const nextMap = find(this.auth.activeUser.activeProject.boards, map => map.id !== action.map.id);
        return context
            .dispatch(new UpdateActiveMapId(nextMap.id))
            .pipe(switchMap(() => {
                return this.auth.activeUser.activeProject.deleteBoard(action.map);
            }));
    }


    private deleteProjectEntry(project: ProjectDef, state: AppStateModel) {
        return this.afs
            .collection<BoardDef>(`projects/${project.id}/maps`)
            .valueChanges()
            .pipe(
                take(1),
                switchMap(maps => {
                    const promises = [
                        this.afs.doc<ProjectDef>(`projects/${project.id}`).delete(),
                        this.auth.activeUser.deleteProject(project.id)
                    ];

                    forEach(maps, map => {
                        promises.push(this.afs.doc<ProjectDef>(`projects/${project.id}/maps/${map.id}`).delete());
                    });
                    return from(Promise.all(promises));
                })
            );
    }

}
