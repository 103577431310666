import {FileRef} from '../file-ref.def';

export class UpdateBucket {
    static type = '[MAIN MENU] UPDATE BUCKET';

    constructor(public bucket: string) {
    }
}

export class FileListUpdateSuccess {
    static type = '[FILE MANAGER] UPDATE FILE LIST';

    constructor(public files: FileRef[]) {
    }
}

export class SetCurrentFolder {
    static type = '[FILE MANAGER] SET CURRENT FOLDER';

    constructor(public folder: string[]) {
    }
}

export class AddFolderInCurrentFolder {
    static type = '[FILE MANAGER] ADD FOLDER IN CURRENT FOLDER';

    constructor(public folderName: string) {
    }
}

export class UploadFileToCurrentFolder {
    static type = '[FILE MANAGER] UPLOAD FILE TO CURRENT FOLDER';

    constructor(public file: File) {
    }
}

export class UploadFile {
    static type = '[FILE MANAGER] UPLOAD FILE';

    constructor(public file: File, public path: string) {
    }
}

export class DeleteFile {
    static type = '[FILE MANAGER] DELETE FILE';

    constructor(public file: FileRef) {
    }
}
