import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';

import {ImageCroppedEvent} from 'ngx-image-cropper';

export type Type = 'canvas' | 'base64' | 'html' | 'blob' | 'rawcanvas';

@Component({
    selector: 'wi-croppie',
    templateUrl: './croppie.component.html',
    styleUrls: ['./croppie.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class CroppieComponent {
    @Input() imageUrl: string;
    @Input() imageFile: any;
    @Input() isCircle = true;

    @Output() result: EventEmitter<string | HTMLElement | Blob | HTMLCanvasElement>
        = new EventEmitter<string | HTMLElement | Blob | HTMLCanvasElement>();

    imageCropped(event: ImageCroppedEvent) {
        this.result.emit(event.base64);
    }
}
