import {animate, state, style, transition, trigger} from '@angular/animations';
import {Component, ContentChild, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild} from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatOption } from '@angular/material/core';

import {Observable, BehaviorSubject} from 'rxjs';

import {find} from 'lodash-es';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';


export interface ExpandableInputAutoCompleteItem {
    id: string;
    name: string;
}



@UntilDestroy()
@Component({
        selector: 'cs-expandable-input',
        templateUrl: './expandable-input.component.html',
        styleUrls: ['./expandable-input.component.scss'],
        animations: [
            trigger('slideInOut', [
                state('true', style({width: '*'})),
                state('false', style({width: '0'})),
                transition('true => false', animate('300ms ease-in')),
                transition('false => true', animate('300ms ease-out'))
            ])
        ]
    })
export class ExpandableInputComponent implements OnInit, OnChanges, OnDestroy {

    @Input() autoComplete$?: Observable<ExpandableInputAutoCompleteItem[]> = new BehaviorSubject<any>([]);
    @Input() icon = 'search';
    @Input() placeholder = '';
    @Input() opened = false;

    @ContentChild('autoCompleteItemTmpl', /* TODO: add static flag */ {}) autoCompleteItemTmpl: TemplateRef<any>;

    @ViewChild('input', { static: true }) inputElement: ElementRef;

    @Output() onBlur = new EventEmitter<string>();
    @Output() onClose = new EventEmitter<void>();
    @Output() onEnter = new EventEmitter<string>();
    @Output() onFocus = new EventEmitter<string>();
    @Output() onOpen = new EventEmitter<void>();
    @Output() onChange = new EventEmitter<string>();
    @Output() autoCompleteSelected = new EventEmitter<ExpandableInputAutoCompleteItem>();

    searchVisible = false;

    text = '';

    private autoCompleteItems: ExpandableInputAutoCompleteItem[];

    ngOnInit() {
        if (this.autoComplete$) {
            this.autoComplete$.pipe(untilDestroyed(this)).subscribe(items => this.autoCompleteItems = items);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.opened && changes.opened.currentValue) {
            this.open();
        }
    }

    // With ngx-takeuntil-destroy, for AOT this method must be present, even if empty!
    // Otherwise 'ng build --prod' will optimize away any calls to ngOnDestroy,
    // even if the method is added by the @TakeUntilDestroy decorator
    ngOnDestroy(): void {
    }

    public close(): void {
        this.searchVisible = false;
        this.inputElement.nativeElement.value = '';
        this.onClose.emit();
    }

    public open(): void {
        this.searchVisible = true;
        this.inputElement.nativeElement.focus();
        this.onOpen.emit();
    }

    onInputBlur(searchValue: string) {
        if (!searchValue) {
            this.searchVisible = false;
        }
        this.onBlur.emit(searchValue);
    }

    onEntering(searchValue: string) {
        this.close();
        this.onEnter.emit(searchValue);
    }

    onFocusChange(searchValue: string) {
        this.onFocus.emit(searchValue);
    }

    onAutoCompleteSelected(event: MatAutocompleteSelectedEvent) {
        const selectedId = event.option.value;
        this.close();
        this.autoCompleteSelected.emit(find(this.autoCompleteItems, item => item.id === selectedId));
    }
}
