import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AngularFireAuth} from '@angular/fire/compat/auth';

import {ToastrService} from 'ngx-toastr';

import {FuseConfigService} from '@fuse/services/config.service';
import {fuseAnimations} from '@fuse/animations';

@Component({
    selector: 'fuse-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
    animations: fuseAnimations
})
export class FuseForgotPasswordComponent implements OnInit {
    forgotPasswordForm: FormGroup;
    forgotPasswordFormErrors: any;

    constructor(private fuseConfig: FuseConfigService,
                private afAuth: AngularFireAuth,
                private toastyService: ToastrService,
                private formBuilder: FormBuilder) {
        this.fuseConfig.config = {
            layout: {
                navbar   : {
                    hidden: true
                },
                toolbar  : {
                    hidden: true
                },
                footer   : {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };

        this.forgotPasswordFormErrors = {
            email: {}
        };
    }

    ngOnInit() {
        this.forgotPasswordForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]]
        });

        this.forgotPasswordForm.valueChanges.subscribe(() => {
            this.onForgotPasswordFormValuesChanged();
        });
    }

    sendResetLink() {
        const redirectSettings = {url: `${window.location.origin}/login`};
        this.afAuth
            .sendPasswordResetEmail(this.forgotPasswordForm.value.email, redirectSettings)
            .then(() => {
                this.toastyService.success(
                    'The password reset link is sent to your registered email id.',
                    'Reset Password',
                    {timeOut: 5000, closeButton: true}
                );
            });
    }

    onForgotPasswordFormValuesChanged() {
        for (const field in this.forgotPasswordFormErrors) {
            if (!this.forgotPasswordFormErrors.hasOwnProperty(field)) {
                continue;
            }

            // Clear previous errors
            this.forgotPasswordFormErrors[field] = {};

            // Get the control
            const control = this.forgotPasswordForm.get(field);

            if (control && control.dirty && !control.valid) {
                this.forgotPasswordFormErrors[field] = control.errors;
            }
        }
    }
}
