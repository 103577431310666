import {Component, EventEmitter, Input, OnDestroy, Output, ViewEncapsulation} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';

import {AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask} from '@angular/fire/compat/storage';

import {last, isNumber, isString} from 'lodash-es';

import {Observable, of} from 'rxjs';
import {catchError, finalize} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';

import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

import {FileStorageService} from './file-storage.service';
import {FileSizePipe} from '../pipes';
import {CroppieDialogComponent} from './croppie-dialog.component';

export interface FileUploadEvent {
    downloadUrl: string;
    fileName: string;
    fullPath: string;
}

@UntilDestroy()
@Component({
    selector: 'cs-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss'],
    providers: [
        FileStorageService
    ],
    animations: [trigger('imageHover', [
        state('hoveringImage', style({
            opacity: 0.3
        })),
        state('notHoveringImage', style({
            opacity: 1
        })),
        transition('hoveringImage <=> notHoveringImage', animate('400ms ease-in'))
    ])],
    encapsulation: ViewEncapsulation.None
})
export class FileUploadComponent implements OnDestroy {

    @Input() multiple = false;

    @Input() path: string;

    @Input() fileName: string;

    @Input() fileType: string;

    @Input() maxSize: number = 10 * 1024 * 1024;

    @Input() isCircle = true;

    @Input() disableCroppie = false;

    @Input() overwrite = true;

    @Output() onUploaded = new EventEmitter<FileUploadEvent | FileUploadEvent[]>();
    @Output() percentageChange = new EventEmitter<number>();
    @Output() onImageCropped = new EventEmitter<Blob>();

    private task: AngularFireUploadTask;

    // State for dropzone CSS toggling
    isHovering: boolean;
    private fileRef: AngularFireStorageReference;

    constructor(private storage: AngularFireStorage,
                private toasty: ToastrService,
                private dialog: MatDialog,
                private fileSizePipe: FileSizePipe) {
    }

    // With ngx-takeuntil-destroy, for AOT this method must be present, even if empty!
    // Otherwise 'ng build --prod' will optimize away any calls to ngOnDestroy,
    // even if the method is added by the @TakeUntilDestroy decorator
    ngOnDestroy(): void {
    }

    toggleHover(event: boolean) {
        this.isHovering = event;
    }

    onFileSelected(event: Event){
        const inputTarget = event.target as HTMLInputElement;
        this.preProcessFile(inputTarget.files);

        // reset the file input
        inputTarget.value = '';
    }

    preProcessFile(event: FileList) {
        const file: File = event.item(0);

        if (file) {
            if (file.size > this.maxSize) {
                const maxSizeInMB = this.fileSizePipe.transform(this.maxSize, false);
                this.toasty.error(
                    `The file size can not exceed ${maxSizeInMB}`,
                    'Error Uploading',
                    {timeOut: 5000, closeButton: true}
                );
            } else if (!this.disableCroppie && file.type.startsWith('image/')) {
                this.showCroppie(file);
            } else {
                this.startUpload(file);
            }
        }
    }

    startUpload(file: File, data?: Blob | string) {
        const fileExtension = last(file.name.split('.'));
        const fileName = `${this.fileName}.${fileExtension}`;
        // The storage path
        const path = `${this.path}/${fileName}`;

        // First check if the file exists in the storage bucket. if it exists, we must delete the file before we upload
        // the new one since the file access token will change on file overwrite
        // https://stackoverflow.com/a/47738367/4242140
        const fileRefTemp = this.storage.ref(path);
        fileRefTemp
            .getDownloadURL()
            .toPromise()
            .then(() => {
                // file exists
                if (this.overwrite) {
                    // delete the existing file
                    return fileRefTemp.delete().toPromise();
                } else {
                    console.warn('Trying to overwrite the file. This will likely generate a permission error on file download link');
                }
            })
            .then(() => {
                this.writeFile(data, path, file, fileName);
            })
            .catch(err => {
                // https://firebase.google.com/docs/storage/web/handle-errors
                if (err.code === 'storage/object-not-found') {
                    // File doesn't exist. so go ahead and upload
                    this.writeFile(data, path, file, fileName);
                } else {
                    this.handleFileUploadError(err);
                }
            });
    }

    private writeFile(data: Blob | string, path: string, file: File, fileName: string) {
        this.fileRef = this.storage.ref(path);
        // The main task
        if (data) {
            if (isString(data)) {
                this.task = this.fileRef.putString(data as string, 'data_url');
            } else {
                this.task = this.fileRef.put(data);
            }
        } else {
            this.task = this.storage.upload(path, file);
        }

        this.notifySuccess(fileName, path);
        this.notifyProgress();
    }

    private notifySuccess(fileName: string, path: string) {
        // Progress monitoring
        this.task
            .snapshotChanges()
            .pipe(
                finalize(async () => {
                    const downloadUrl = await this.fileRef.getDownloadURL().toPromise();
                    this.onUploaded.emit({
                        downloadUrl: downloadUrl,
                        fileName: fileName,
                        fullPath: path
                    });
                }),
                catchError(err => {
                    this.handleFileUploadError(err);
                    return of(null);
                })
            )
            .subscribe(() => {
                // nothing here
            });
    }

    dropzoneHovering(isHovering: boolean) {
        this.isHovering = isHovering;
    }

    private handleFileUploadError(err) {
        console.error('Error uploading file ', err);
        this.toasty.error(
            'File upload failed. Try again',
            'Error',
            {timeOut: 2000, closeButton: true}
        );
    }

    private showCroppie(file: File) {
        const croppieDialog = this.dialog.open(CroppieDialogComponent, {
            data: {
                file: file,
                isCircle: this.isCircle
            }
        });

        croppieDialog
            .afterClosed()
            .subscribe(image => {
                if (image) {
                    this.onImageCropped.emit(image);
                    this.startUpload(file, image);
                }
            });
    }

    private notifyProgress() {
        this.task
            .percentageChanges()
            .subscribe(progress => {
                if (isNumber(progress) && !isNaN(progress)) {
                    this.percentageChange.emit(progress);
                }
            });
    }
}
