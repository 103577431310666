import {Component, OnDestroy, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from '@angular/router';

import {Select, Store} from '@ngxs/store';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {AppState} from 'app/store/app.state';
import {AuthService, Board} from '@cultursys/core';
import {SetCurrentPeriod} from 'app/store/app.actions';
import {FuseConfigService} from '../../../../../../@fuse/services/config.service';



@UntilDestroy()
@Component({
        selector: 'navbar-horizontal-style-1',
        templateUrl: './style-1.component.html',
        styleUrls: ['./style-1.component.scss'],
        encapsulation: ViewEncapsulation.None
    })
export class NavbarHorizontalStyle1Component implements OnDestroy {
    @Select(AppState.currentPeriodTitle) currentPeriodTitle$;

    appViews = [
        {path: '/apps/project/summary', name: 'Executive Summary', enabled: true},
        {path: '/apps/project/culture-map', name: 'Culture Map', enabled: true},
        {path: '/apps/project/metrics', name: 'Metrics', enabled: true},
        {path: '/apps/project/reports', name: 'Reports', enabled: true},
        {path: '/apps/project/assurance', name: 'Assurance', enabled: true}
    ];

    map: Board;
    currentPeriod = 0;

    constructor(private router: Router,
                private store: Store,
                private auth: AuthService,
                private configService: FuseConfigService,
                private activatedRoute: ActivatedRoute
    ) {
        this.store
            .select(AppState.currentPeriod)
            .pipe(untilDestroyed(this))
            .subscribe(period => {
                this.currentPeriod = period;
            });

        this.store
            .select(AppState.activeMapId)
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                if (this.auth.activeUser) {
                    this.map = this.auth.activeUser.activeProject.activeBoard;
                }
            });
    }

    ngOnDestroy() {
    }

    isActiveView(view) {
        const urlTree = this.router.createUrlTree([view.path], {relativeTo: this.activatedRoute});
        return this.router.isActive(urlTree.toString(), false);
    }

    nextPeriod() {
        if (this.currentPeriod < this.map.numHistoricPeriods) {
            const period = this.currentPeriod + 1;
            const historicValue = this.map.historicalValueFor(period);
            this.store.dispatch(new SetCurrentPeriod(period, historicValue.periodName));
        }
    }

    previousPeriod() {
        if (this.currentPeriod > 0) {
            const period = this.currentPeriod - 1;
            const historicValue = this.map.historicalValueFor(period);
            this.store.dispatch(new SetCurrentPeriod(period, historicValue.periodName));
        }
    }
}
