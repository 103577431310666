import {Directive, Inject, OnInit, Renderer2} from '@angular/core';


import {environment} from 'environments/environment';
import {LiveSupportService} from '../../core/live-support.service';
import {DOCUMENT} from '@angular/common';

@Directive({
    selector: '[csTawk]'
})
export class TawkDirective implements OnInit {

    private tawkApi: any;

    constructor(private _renderer: Renderer2,
                @Inject(DOCUMENT) private _document,
                private liveSupport: LiveSupportService) {
    }

    ngOnInit() {
        const tawkId = environment.tawkId;
        const s = this._renderer.createElement('script');
        s.text = `var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
                    (function () {
                        var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
                        s1.async = true;
                        s1.src = 'https://embed.tawk.to/${tawkId}/default';
                        s1.charset = 'UTF-8';
                        s1.setAttribute('crossorigin', '*');
                        s0.parentNode.insertBefore(s1, s0);
                    })();`;
        this._renderer.appendChild(this._document.body, s);

        this.liveSupport.updateUserInfo();
    }
}
