import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {Store} from '@ngxs/store';

import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

import {filter, switchMap} from 'rxjs/operators';
import {combineLatest} from 'rxjs';

import {get, isEmpty} from 'lodash-es';

import {AuthService, ReportsService} from '@cultursys/core';
import {FileManagerState} from '@cultursys/file-manager';


@UntilDestroy()
@Component({
        selector: 'cs-main-nav-menu',
        templateUrl: './nav-menu.component.html',
        styleUrls: ['./nav-menu.component.scss'],
        encapsulation: ViewEncapsulation.None
    })
export class NavMenuComponent implements OnInit, OnDestroy {

    reportMenuItems = [];
    configMenuItems = [
        {path: '/apps/administrator/users', title: 'User Management'},
        // {path: '/apps/administrator/kb/articles', title: `Knowledge Base Editor`},
        {path: '/apps/administrator/snippets', title: `Map Data Manager`},
    ];

    constructor(private router: Router,
                private store: Store,
                private auth: AuthService,
                private reportService: ReportsService,
                private activatedRoute: ActivatedRoute) {

        this.reportMenuItems = this.reportService.reportList.map( (report) => {
            return {
                path: `/apps/project/reports/${report.uri}`,
                title: report.name,
                hidden: false,
                id: report.id
            };
        });

        // Insert a hidden menu item so that when we navigate to /reports
        // the top level menu is shown as active
       this.reportMenuItems.push({path: '/apps/project/reports/list', title: 'reports', hidden: true});
    }

    ngOnInit(): void {
        // this.filterMenuItems();
    }

    ngOnDestroy() {
    }

    get isAdmin(): boolean {
        return this.auth.activeUser ? this.auth.activeUser.isAdmin : false;
    }

    isActiveView(path: string) {
        const urlTree = this.router.createUrlTree([path], {relativeTo: this.activatedRoute});
        return this.router.isActive(urlTree.toString(), false);
    }

    trackMenuItem(index, {path}) {
        return path;
    }

    getItemPath(item: any): string {
        return item.path;
    }

    private filterMenuItems() {
        this.store
            .select(state => get(state, 'app.activeProjectId', null))
            .pipe(
                filter(projectId => {
                    return !isEmpty(projectId) && !!this.auth.activeUser && !!this.auth.activeUser.activeProject;
                }),
                switchMap(() => {
                    return combineLatest([
                        this.store.select(FileManagerState.files),
                        this.auth.activeUser.activeProject.onProjectModified$
                    ]);
                }),
                untilDestroyed(this)
            )
            .subscribe(([files, project]) => {
                // this.filterCPRMenuItems(project, files);
            });
    }

    navigateTo(path: string) {
       this.router.navigateByUrl(path);

    }
}
