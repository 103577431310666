import {Component, OnInit} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {Router} from '@angular/router';

@Component({
    selector: 'cs-verify-login-url',
    templateUrl: './verify-login-url.component.html',
    styleUrls: ['./verify-login-url.component.scss']
})
export class VerifyLoginUrlComponent implements OnInit {

    constructor(private afAuth: AngularFireAuth, private router: Router) {
    }

    ngOnInit() {
        const url = this.router.url;
        if (url) {
            this.confirmSignIn(url);
        } else {
            this.router.navigate(['login']);
        }
    }

    confirmSignIn(url) {
        if (this.afAuth.isSignInWithEmailLink(url)) {
            let email = window.localStorage.getItem('emailForSignIn');

            // If missing email, prompt user for it
            if (!email) {
                email = window.prompt('Please provide your email for confirmation');
            }

            // Signin user and remove the email localStorage
            this.afAuth
                .signInWithEmailLink(email, url)
                .then(() => {
                    window.localStorage.removeItem('emailForSignIn');
                    this.router.navigate(['apps', 'project']);
                })
                .catch(err => {
                    console.error('Error signing in ', err);
                    this.router.navigate(['login']);
                });
        }
    }

}
