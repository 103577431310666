import {invert} from 'lodash-es';

export interface FacetOption {
    group?: string;
    label: string;
    value: string;
    checked: boolean;
    disabled: boolean;
    count: number;
}

export interface Facet {
    title: string;
    docKey: string;
    idxKey: string;
    options: FacetOption[];
    selected: string[];
    showCount: number;
    showFilter: boolean;
    page?: number;
}

export interface Tag
{
    id?: string;
    title?: string;
}


export enum SnippetCategory  {
    Action =       'Action Plan',
    BestPractice =     'Best Practice',
    Profile =        'Profile',
    References = 'References',
    Data =          'Data'
}

export enum SnippetCategoryProperty {
    Action =        'recommendations',
    Practice =      'bestPractices',
    Profile =       'profile',
    References =    'references',
    Data =          'data'
}

export const SnippetCategoryHash: Record<SnippetCategory, SnippetCategoryProperty> = {
    [SnippetCategory.Action]: SnippetCategoryProperty.Action,
    [SnippetCategory.BestPractice]: SnippetCategoryProperty.Practice,
    [SnippetCategory.Profile]: SnippetCategoryProperty.Profile,
    [SnippetCategory.References]: SnippetCategoryProperty.References,
    [SnippetCategory.Data]: SnippetCategoryProperty.Data,
};

export const SnippetPropertyToCategoryHash = invert(SnippetCategoryHash);


export interface LegacyMediaDescriptor {
    id: string;
    version: number;
    fileName: string;
    url: string;
}

export interface Attachment {
    filename: string;
    url:string;
}
export interface ISnippetDB {
    uid:        string;
    text:       string;
    topics:     string[];
    drivers:    string[];
    companies:  string[];
    sources:    Attachment[];
    category:   SnippetCategory;
    order:      number;

}

export interface ISnippet extends ISnippetDB {
    isEditing?: boolean;
    isSelected?: boolean;
    isHidden?: boolean;
    isDraggable?: boolean;
    isDirty?: boolean;
}

export const EMPTY_SNIPPET: ISnippet = {
    uid: '',
    text: '',
    topics: [],
    drivers: [],
    companies: [],
    sources: [],
    category: SnippetCategory.Action,
    order: 0,
    isEditing: false,
    isSelected: false,
    isHidden: false,
    isDraggable: false,
    isDirty: false
 };


export interface ISnippetDoc {
    uid: string;
    text: string;
    snippets: ISnippet[];
}

export interface SnippetPagination
{
    length: number;
    size: number;
    page: number;
    lastPage: number;
    startIndex: number;
    endIndex: number;
}


export type ISnippetList = ISnippet[];


const UIProps = ['isEditing' , 'isSelected', 'isHidden', 'isDraggable', 'isDirty'];
