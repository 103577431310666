import { Pipe, PipeTransform } from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {compact, isArray, isEmpty,  trim} from 'lodash-es';

@Pipe({
    name: 'csHighlight'
})
export class HighlightSearchPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer){}

    transform(value: string | string[], args: string): SafeHtml | SafeHtml[] {
        if (isEmpty(value)) {
            return this.sanitizer.bypassSecurityTrustHtml('');
        }

        const isInputArray = isArray(value);
        if (!args) {
            if (isInputArray) {
                return (<string[]>value).map(text => this.sanitizer.bypassSecurityTrustHtml(text));
            } else {
                return this.sanitizer.bypassSecurityTrustHtml(<string>value);
            }
        }

        const tokens = compact(args.split(' ').map((word) => trim(word, '~*!=^.$+-')));
        const markedResults = [];
        const rawText: string[] = isArray(value) ? value as string[] : [value as string];

        rawText.forEach((plainText) => {
            let marketText = plainText;
            tokens.forEach( (token) => {
                try {
                    // find case insensitive matches, excluding patterns in html tags.
                    // e.g., '<span>panel</span>' , "Pan" should be highlighted in panel and not in span
                    const re = new RegExp(`(${token})(?![^<]*>)`, 'gi');
                    marketText = marketText.replace(re, `<mark>${token}</mark>`);
                } catch (err) {
                    console.log('Unable to highlight token ', token, err);
                }
            });
            markedResults.push(this.sanitizer.bypassSecurityTrustHtml(marketText));
        });

        return isArray(value) ? markedResults : markedResults[0];
    }
}
