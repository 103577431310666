<h1 matDialogTitle>Add Folder</h1>
<div mat-dialog-content>
    <form>
        <mat-form-field>
            <input matInput
                   name="folderName"
                   placeholder="Enter folder name..."
                   [(ngModel)]="folderName">
        </mat-form-field>
    </form>
</div>
<div mat-dialog-actions class="pt-24">
    <button mat-raised-button class="mat-accent mr-16" (click)="dialogRef.close(folderName)">Create</button>
    <button mat-button (click)="dialogRef.close()">Cancel</button>
</div>
