<mat-toolbar class="h-24 accent-500">

    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutAlign.gt-xs="space-between center" fxFlex>

        <span class="secondary-text font-size-12">
                © {{currentYear}} Pyxis Culture Technologies Limited. All rights reserved.
            </span>


        <div fxLayout="row" fxLayoutAlign="start center">
            <a class="font-size-10 font-weight-400 secondary-text" (click)="readArticle('terms')">Terms & Conditions</a>
            <span class="mx-20">&bull;</span>
            <a class="font-size-10 font-weight-400 secondary-text" (click)="readArticle('privacy')">Privacy Policy</a>

        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxHide fxShow.gt-xs>
            <a class="font-size-10 font-weight-400 secondary-text" href="http://whatif.io" target="_blank">Web App Crafted by WhatIf...</a>
        </div>

        <div fxLayout="row" fxLayoutAlign="start center">
            <a class="live-help-message font-size-12" href="javascript:;" (click)="launchSupport()" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="s-16" *ngIf="liveSupportConfig.isOperatorOnline">chat</mat-icon>
                <mat-icon class="s-16" *ngIf="!liveSupportConfig.isOperatorOnline">email</mat-icon>
                <span class="ml-4 accent-100-fg">{{liveSupportConfig.message}}</span>
            </a>
            <a href="javascript:;" ng-click="launchSupport()" id="whatif-live-help-icon">
            <span class="fa-stack fa-lg">
            <i class="fa fa-comment  fa-stack-2x grey"
            [ngClass]="{'green': liveSupportConfig.isOperatorOnline}"></i>
            <i class="fa fa-ellipsis-h fa-stack-1x white"></i>
            </span>
            </a>
        </div>
    </div>

</mat-toolbar>
