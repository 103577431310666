import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import {FuseSharedModule} from '@fuse/shared.module';

import {FuseRegisterComponent} from './register.component';

@NgModule({
    declarations: [
        FuseRegisterComponent
    ],
    imports: [
        RouterModule,
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatInputModule,

        FuseSharedModule
    ]
})
export class RegisterModule {
}
