import { Pipe, PipeTransform, SecurityContext } from "@angular/core";
import { get, isArray, isEmpty } from "lodash-es";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

const unsafe = (val: string | SafeHtml) =>
    get(val, "changingThisBreaksApplicationSecurity", val);

@Pipe({
    name: "tags",
})
export class TagsPipe implements PipeTransform {
    constructor(protected sanitizer: DomSanitizer) {}

    transform(
        data: string | SafeHtml | string[] | SafeHtml[],
        cssClass: string = ""
    ): SafeHtml {
        if (isEmpty(data)) {
            return this.sanitizer.bypassSecurityTrustHtml("");
        }

        const tagData = isArray(data)
            ? (<any[]>data).map((el) => unsafe(el))
            : [unsafe(data)];

        const tags = tagData.reduce(
            (tagsStr, item) =>
                (tagsStr += `<span class="tag ${cssClass}">${item}</span>`),
            ""
        ) as string;

        return this.sanitizer.bypassSecurityTrustHtml(tags);
    }
}
